// @ts-nocheck

angular.module('yaltyApp')

    .controller('loyaltyCardsCtrl', function ($scope, $rootScope, $anchorScroll, $timeout, $filter, $http, $cookies, $stateParams, $location, $state, $window, $document, $q, $mdDialog, API_PREFIX, SubscriptionNames, LoyaltyCardService2, GoogleAnalyticsService) {
        $rootScope.isDataSheetModified = false;
        $scope.child = {}
        $scope.isActivateLoyaltyCard = false;
        $scope.newloyaltyCard = "Új törzskártya";
        $scope.SubscriptionNames = SubscriptionNames;
        $scope.animateMobile = "";
        $scope.showLoyaltys = true;
        $rootScope.logoCropperData = {};
        $scope.logoZoomSlider = 0;
        $scope.logoSliderMin = 0;
        $scope.logoSliderMax = 10;
        $scope.zoomSlider = 0;
        $scope.sliderMin = 0;
        $scope.sliderMax = 10;
        var shareIconCanvas = {}, backCanvas = {}, addIconCanvas = {};
        $scope.isNumber = function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }
        var virtualSize = $window.innerHeight - 300;
        var company = [];
        $scope.companyStores = [];
        $scope.allCompStores = [];
        $scope.containerSize = { "height": virtualSize + "px", "width": "300px", "margin-left": "8px" };

        $scope.promoStatusText = [{ code: 0, text: "még nem promótált törzskártya" },
        { code: 1, text: "futó promóció" },
        { code: 2, text: "kifutó promóció" },
        { code: 3, text: "törölt promóció" }];

        $scope.barcodeTypes = [
            {
                id: 1, displayName: 'Vonalkód (“1D vonalkód”)'
            },
            {
                id: 10, displayName: 'QR-kód (“2D vonalkód”)'
            }
        ]
        $scope.barcodeSubTypes = [
            {
                id: 21, displayName: 'EAN-13'
            },
            {
                id: 20, displayName: 'EAN-8'
            },
            {
                id: 27, displayName: 'EAN-128 (GS1-128)'
            },
            {
                id: 22, displayName: 'Code-25 Interleaved'
            },
            {
                id: 23, displayName: 'Code-39'
            },
            {
                id: 24, displayName: 'Code-128'
            },
            {
                id: 25, displayName: 'UPC-A'
            },
            {
                id: 26, displayName: 'UPC-E'
            }
        ]

        $scope.customerLoyaltyIdGroupingTypes = [
            {
                id: 0, displayName: 'Nincs csoportosítás'
            },
            {
                id: 1, displayName: 'Automatikus csoportosítás'
            },
            {
                id: 2, displayName: 'Egyedi csoportosítás'
            }
        ]


        $scope.customerLoyaltyIdInputLimitations = [
            {
                id: 0, displayName: 'Nincs megkötés'
            },
            {
                id: 1, displayName: 'Numerikus billentyűzet javaslata'
            },
            {
                id: 2, displayName: 'Kizárólag numerikus billentyűzet'
            }
        ]

        $scope.customerLoyaltyIdErrorDisplayTypes = [
            {
                id: 0, displayName: 'Nincs megjelenítés'
            },
            {
                id: 1, displayName: 'Szekció megjelenítése - Javításra figyelmeztetés'
            },
            {
                id: 2, displayName: 'Szekció megjelenítése - Javításra kötelezés'
            }
        ]

        $scope.reverseloyaltyCard = false;

        $scope.isCardBackgroundColorConflictedWithPromotionCompanyName = false;
        $scope.isCardBackgroundColorConflictedWithDiscountName = false;
        $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor = false;
        $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons = false;

        var acceptableContrastRatio = 10.0; //Megadható mekkora kontrasztbeli eltérés esetén jelenjen meg figyelmeztetés, a felhasználó felé

        $scope.checkColorContrastConflitions = function (name, value) {
            if (typeof value === 'undefined') {
                return;
            }
            let valueRgbColor = hexaToRgb(value);

            var contrastRatio = 0;

            switch (name) {
                case 'compNamePromoTextColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithPromotionCompanyName = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'promotionNameTextColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithDiscountName = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'detailedDisplayBackgroundColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor = contrastRatio <= acceptableContrastRatio ? true : false;

                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.backButtonColor));
                    $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'backButtonColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.detailedDisplayBackgroundColor));
                    $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'cardBackgroundColor':
                    $scope.checkColorContrastConflitions('compNamePromoTextColor', $scope.compNamePromoTextColor);
                    $scope.checkColorContrastConflitions('promotionNameTextColor', $scope.promotionNameTextColor);
                    $scope.checkColorContrastConflitions('detailedDisplayBackgroundColor', $scope.detailedDisplayBackgroundColor);
                    break;
            }
        }

        function hexaToRgb(hexColor) {
            let r, g, b;
            hexColor = hexColor.replace("#", "")
                , r = parseInt(hexColor.substring(0, 2), 16)
                , g = parseInt(hexColor.substring(2, 4), 16)
                , b = parseInt(hexColor.substring(4, 6), 16);

            let rgbColor = [r, g, b];
            return rgbColor;
        }

        function deltaE(rgbA, rgbB) {
            let labA = rgb2lab(rgbA);
            let labB = rgb2lab(rgbB);
            let deltaL = labA[0] - labB[0];
            let deltaA = labA[1] - labB[1];
            let deltaB = labA[2] - labB[2];
            let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
            let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
            let deltaC = c1 - c2;
            let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
            deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
            let sc = 1.0 + 0.045 * c1;
            let sh = 1.0 + 0.015 * c1;
            let deltaLKlsl = deltaL / (1.0);
            let deltaCkcsc = deltaC / (sc);
            let deltaHkhsh = deltaH / (sh);
            let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
            return i < 0 ? 0 : Math.sqrt(i);
        }

        function rgb2lab(rgb) {
            let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
            r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
            g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
            b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
            x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
            y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
            z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
            x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
            y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
            z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;
            return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
        }

        $scope.openTranslationWindow = (languageId, whereToOpen) => {
            if (languageId != 1) {
                const langIndex = $scope.languages.findIndex(item => item.id == languageId)
                $scope.windowTitleLang = $scope.languages[langIndex].windowTitleName
                $scope.langName = $scope.languages[langIndex].name
                $scope.lang = $scope.languages[langIndex].code
                $scope.translatedCompNamePromo = $scope[$scope.lang].compNamePromo
                $scope.translatedPromotionName = $scope[$scope.lang].promotionName
                $scope.translatedCustomerLoyaltyIdErrorDisplayMsg = $scope[$scope.lang].customerLoyaltyIdErrorDisplayMsg
                //const element = document.getElementById("translatedCompNamePromo");
                //element.scrollIntoView();
                //document.getElementById('translatedCompNamePromo').focus()
                $scope.showTranslationWindow = true
                const langCode = $scope.languages[langIndex].code
                $scope.initValues = {}
                $scope.initValues[langCode] = {}
                $scope.initValues[langCode].enabled = $scope.translationEnabled
                if (!$scope.translationEnabled) {
                    $scope.translationEnabled = true
                    $scope.languageSelector = 2
                    $scope.modifyTranslationEnabled(true)
                }
                const divToScroll = whereToOpen ? whereToOpen : 'translationTable'
                $(`#${divToScroll}`).animate({ scrollTop: 0 }, "fast");


            }
        }

        $scope.modifyTranslationEnabled = (windowOpening) => {
            if ($scope.languageSelector == 2) {
                $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
                $scope.languages[1].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
            }
            const langName = $scope.langName.toLowerCase()
            if ($scope.translationEnabled) {
                let alert = $mdDialog.confirm()
                    .title(`A(z) ${langName} fordítás bekapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegeket, üzletek információit és a promóciók szövegeit is szükséges ${langName} nyelvre fordítani. A fordításban érintett oldalak:`)
                    .htmlContent(`
                    <ul class="md-title mdDialogList">
                        <li>Partneri adatok,</li>
                        <li>Üzletek (azon belül az egyes üzletek),</li>
                        <li>Hűségkártyáink (azon belül az egyes, még aktív hűségkártyák), </li>
                        <li>Törzskártyáink (azon belül az egyes, még aktív törzskártyák) oldalak.</li>
                    </ul>`)
                    .ok('Rendben')
                if (windowOpening) alert.cancel('Mégsem')
                $mdDialog.show(alert).catch(() => {
                    for (let i = 1; i < 6; i++) {
                        $scope.handlingErrorInTranslatedDescrField(i)
                    }
                    $scope.loyaltyCardForm.languageSelector.$valid = true
                    $scope.loyaltyCardForm.languageSelector.$invalid = false
                    $scope.loyaltyCardForm.languageSelector.$error = {}
                    $scope.translationEnabled = false
                    $scope.closeTranslationWindow()
                })
            } else {
                const alert = $mdDialog.alert()
                    .title(`A(z) ${langName} fordítás kikapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegek, üzletek információ és a promóciók szövege esetén kikapcsoljuk a(z) ${langName} nyelvű fordítást. Tehát minden, a fordításban érintett oldalon:`)
                    .htmlContent(`
                        <ul class="md-title mdDialogList">
                            <li>Partneri adatok,</li>
                            <li>Üzletek (azon belül az egyes üzletek),</li>
                            <li>Hűségkártyáink (azon belül az egyes hűségkártyák), </li>
                            <li>Törzskártyáink (azon belül az egyes, még aktív törzskártyák) oldalak.</li>
                        </ul>
                    `)
                    .ok('Rendben')
                $mdDialog.show(alert).then(() => {
                    for (let i = 1; i < 6; i++) {
                        $scope.handlingErrorInTranslatedDescrField(i)
                    }
                    $scope.loyaltyCardForm.languageSelector.$valid = true
                    $scope.loyaltyCardForm.languageSelector.$invalid = false
                    $scope.loyaltyCardForm.languageSelector.$error = {}
                })
            }

        }

        $scope.closeTranslationWindow = () => {
            $scope.translatedCompNamePromo = $scope[$scope.lang].compNamePromo
            $scope.translatedPromotionName = $scope[$scope.lang].promotionName
            $scope.translatedCustomerLoyaltyIdErrorDisplayMsg = $scope[$scope.lang].customerLoyaltyIdErrorDisplayMsg
            $scope.languageSelector = 1
            $scope.showTranslationWindow = false
            $scope.translationEnabled = $scope.initValues['EN'].enabled
            $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
            $scope.languages[$scope.languageSelector].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
        }

        $scope.saveTranslation = () => {
            let isTranslationModified = false;
            if($scope[$scope.lang].compNamePromo != $scope.translatedCompNamePromo) {
                $scope[$scope.lang].compNamePromo = $scope.translatedCompNamePromo
                isTranslationModified = true;
            }
            if($scope[$scope.lang].promotionName != $scope.translatedPromotionName) {
                $scope[$scope.lang].promotionName = $scope.translatedPromotionName
                isTranslationModified = true;
            }
            if($scope[$scope.lang].customerLoyaltyIdErrorDisplayMsg != $scope.translatedCustomerLoyaltyIdErrorDisplayMsg) {
                $scope[$scope.lang].customerLoyaltyIdErrorDisplayMsg = $scope.translatedCustomerLoyaltyIdErrorDisplayMsg
                isTranslationModified = true;
            }
            $scope.languageSelector = 1
            $scope.sendButtonDisabled = false
            $rootScope.isDataSheetModified = true
            if ($scope.loyaltyCardForm.translatedCompNamePromo.$valid && $scope.loyaltyCardForm.translatedPromotionName.$valid && $scope.loyaltyCardForm.translatedCustomerLoyaltyIdErrorDisplayMsg) {
                $scope.loyaltyCardForm.languageSelector.$valid = true
                $scope.loyaltyCardForm.languageSelector.$invalid = false
                $scope.loyaltyCardForm.languageSelector.$error = {}
            }
            $scope.showTranslationWindow = false
            if(isTranslationModified == true) {
                var confirm = $mdDialog.confirm()
                    .textContent('Figyelem: a fordítások módosításainak mentéséhez használja a Törzskártya oldal Mentés gombját.')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                }, function () {
                });
            }

        }

        $scope.copyToTranslationField = (fieldName, copyFromPartnerData) => {
            const translationFieldName = 'translated' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
            if (copyFromPartnerData) {
                if ($scope.partnerData[translationFieldName]) {
                    $scope[translationFieldName] = $scope.partnerData[translationFieldName]
                }
            } else if ($scope[fieldName]) {
                $scope[translationFieldName] = $scope[fieldName]
            }
        }

        $scope.$watch(function () {
            return $rootScope.isDataSheetModified
        }, function (current) {
            if (current == false) {
                $scope.sendButtonDisabled = true;
            } else {
                $scope.sendButtonDisabled = false;
            }
        });

        $scope.$watch(function () {
            return $rootScope.logoCropperData.compLogoImg;
        }, function (current, prevent) {
            if(current){ //Fehér képeknél szürke háttér beállítása
                const img = document.createElement("img");
                img.src = current.includes('/imgs/') ? $location.protocol() + "://" + location.host + $rootScope.logoCropperData.compLogoImg : $rootScope.logoCropperData.compLogoImg;
                img.crossOrigin = 'anonymous';
                img.style.display = "none";
                document.body.appendChild(img);
                let colorSum = 0;
                let pixel = 0;
            
                img.onload = function() {
                    const canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);
            
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;
                    let r, g, b, avg;
            
                    for(let x=0, len=data.length; x<len; x+=4) {
                        if(data[x+3] != 0){
                            r = data[x];
                            g = data[x+1];
                            b = data[x+2];
                            avg = Math.floor((r+g+b) / 3);
                            colorSum += avg;
                            pixel++;
                        }
                    }
            
                    const brightness = Math.floor(colorSum / pixel);
                    console.log(brightness);
                    if(brightness >= 230){
                        $rootScope.logoCropperData.isTooBrightness = true;
                    } else {
                        $rootScope.logoCropperData.isTooBrightness = false;
                    }
                }
            }
            if (prevent && current) {
                if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                    $scope.isCardLogoImgURLSameAsCo = false;
                    $rootScope.isDataSheetModified = true;
                }
            } else if (!prevent && current && !current.includes('/imgs/') && !$scope.isCardLogoImgURLSameAsCo) {
                $rootScope.isDataSheetModified = true;
            }
        });

        $scope.resetPage = function () {
            if ($rootScope.isDataSheetModified == false) {
                $window.scrollTo(0, 0);
                $state.reload();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    $state.reload();
                }, function () {
                });
            }
        }

        var resetVariables = function () {
            $rootScope.isDataSheetModified = false;
            $scope.loyaltyCardData = {};
            if ($rootScope.logoCropperData.cropper) {
                $rootScope.logoCropperData.cropper.destroy();
            }
            delete $rootScope.logoCropperData;
            $rootScope.logoCropperData = {};
            $scope.detailedDisplayBackgroundColor = "#000000";
            $scope.backButtonColor = "#FFFFFF";
            $scope.cardBackgroundColor = "#FFFFFF";
            $scope.isCompNamePromoSameAsCo = undefined;
            $scope.compNamePromoTextColor = "#4E525C";
            $scope.promotionNameTextColor = "#000000";
            $scope.loyaltyCardId = undefined;
            $scope.isBeforeActivation = undefined;
            $scope.loyaltyCardPromoStatusCode = null;
            $scope.isCompStoresSame = false;
            $scope.isCardLogoImgURLSameAsCo = true;
            $scope.isCardLogoImgSameAsCo = undefined;
            //$scope.partnerSubscriptionType = undefined;
            $scope.promotionName = undefined
            $scope.customerLoyaltyIdFormat = undefined;
            $scope.checkCustomerLoyaltyIdFormatOnFirstTyping = null;
            $scope.searchKeywords = undefined;
            $scope.barcodeType = undefined;
            $scope.barcodeSubType = undefined;
            $scope.customerLoyaltyIdErrorDisplayType = undefined;
            $scope.customerLoyaltyIdErrorDisplayMsg = null;
            $scope.customerLoyaltyIdInputLimitation = undefined;
            $scope.customerLoyaltyIdGroupingType = undefined;
            $scope.customerLoyaltyIdGroups = undefined;
            $scope.companyStores = [];
            company = []
            $scope.isAllRedeemLocationIndicator = false;
            $scope.noStoreAYC = false;
            $scope.isActivateLoyaltyCard = false;
            $scope.sendButtonDisabled = false;
            $scope.allRedeemLocationIndicator = 'select';

            //$scope.coloringIcons($scope.backButtonColor);
        };
        // $scope.setIconCanvases = function (hexaColor) {
        //     backCanvas.canvas = document.getElementById("backIconCanvas");
        //     backCanvas.ctx = backCanvas.canvas.getContext("2d");
        //     backCanvas.ctx.clearRect(0, 0, backCanvas.canvas.width, backCanvas.canvas.height);
        //     backCanvas.cw = backCanvas.canvas.width;
        //     backCanvas.ch = backCanvas.canvas.height;
        //     backCanvas.imgData;
        //     backCanvas.data;
        //     backCanvas.originalData;
        //     backCanvas.img = new Image();
        //     backCanvas.img.src = "../imgs/back_line_icon.svg";
        //     backCanvas.isLoaded = false;

        //     shareIconCanvas.canvas = document.getElementById("shareIconCanvas");
        //     shareIconCanvas.ctx = shareIconCanvas.canvas.getContext("2d");
        //     shareIconCanvas.ctx.clearRect(0, 0, shareIconCanvas.canvas.width, shareIconCanvas.canvas.height);
        //     shareIconCanvas.cw = shareIconCanvas.canvas.width;
        //     shareIconCanvas.ch = shareIconCanvas.canvas.height;
        //     shareIconCanvas.imgData;
        //     shareIconCanvas.data;
        //     shareIconCanvas.originalData;
        //     shareIconCanvas.img = new Image();
        //     shareIconCanvas.img.src = "../imgs/share_line_icon.svg";
        //     shareIconCanvas.isLoaded = false;

        //     addIconCanvas.canvas = document.getElementById("addIconCanvas");
        //     addIconCanvas.ctx = addIconCanvas.canvas.getContext("2d");
        //     addIconCanvas.ctx.clearRect(0, 0, addIconCanvas.canvas.width, addIconCanvas.canvas.height);
        //     addIconCanvas.cw = addIconCanvas.canvas.width;
        //     addIconCanvas.ch = addIconCanvas.canvas.height;
        //     addIconCanvas.imgData;
        //     addIconCanvas.data;
        //     addIconCanvas.originalData;
        //     addIconCanvas.img = new Image();
        //     addIconCanvas.img.src = "../imgs/add_icon.png";
        //     addIconCanvas.isLoaded = false;


        //     $scope.coloringIcons(hexaColor);
        // }
        /*$rootScope.$on('coloringCanvas', function (event, args) {
            $scope.setIconCanvases("#FFFFFF");
        });*/
        $scope.checkChanges = function (hexaColor) {
            //if (hexaColor) $scope.coloringIcons(hexaColor)
            if ($scope.compNamePromo) {
                $scope.compNamePromo = $scope.compNamePromo.trim();
            }
            if ($scope.promotionName) {
                $scope.promotionName = $scope.promotionName.trim();
            }
            if ($scope.customerLoyaltyIdFormat) {
                $scope.customerLoyaltyIdFormat = $scope.customerLoyaltyIdFormat.trim();
            }
            if ($scope.searchKeywords ) {
                $scope.searchKeywords  = $scope.searchKeywords .trim();
            }
            if ($scope.customerLoyaltyIdErrorDisplayType == 0) {
                $scope.customerLoyaltyIdErrorDisplayMsg = null;
                $scope['EN'].customerLoyaltyIdErrorDisplayMsg = null;
                $scope.translatedCustomerLoyaltyIdErrorDisplayMsg = null;
            }
            if ($rootScope.isDataSheetModified == false) {
                if ($scope.loyaltyCardId) {
                    if ($scope.detailedDisplayBackgroundColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.detailedDisplayBackgroundColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.backButtonColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.backButtonColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.cardBackgroundColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.cardBackgroundColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromo != $scope.loyaltyCardData.loyaltyCardDetalViewData.compNamePromo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isCompNamePromoSameAsCo != $scope.loyaltyCardData.loyaltyCardDetalViewData.isCompNamePromoSameAsCo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromoTextColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.compNamePromoTextColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionName != $scope.loyaltyCardData.loyaltyCardDetalViewData.promotionName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionNameTextColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.promotionNameTextColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdFormat != $scope.loyaltyCardData.loyaltyCardDetalViewData.customerLoyaltyIdFormat) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if($scope.checkCustomerLoyaltyIdFormatOnFirstTyping != $scope.loyaltyCardData.loyaltyCardDetalViewData.checkCustomerLoyaltyIdFormatOnFirstTyping) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.searchKeywords != $scope.loyaltyCardData.loyaltyCardDetalViewData.searchKeywords) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeType != $scope.loyaltyCardData.loyaltyCardDetalViewData.barcodeType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSubType != $scope.loyaltyCardData.loyaltyCardDetalViewData.barcodeSubType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdInputLimitation != $scope.loyaltyCardData.loyaltyCardDetalViewData.customerLoyaltyIdInputLimitation) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdErrorDisplayType != $scope.loyaltyCardData.loyaltyCardDetalViewData.customerLoyaltyIdErrorDisplayType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdErrorDisplayMsg != $scope.loyaltyCardData.loyaltyCardDetalViewData.customerLoyaltyIdErrorDisplayMsg) {
                        $rootScope.isDataSheetModified = true;
                    }
                    customerLoyaltyIdErrorDisplayMsg
                    if ($scope.customerLoyaltyIdGroupingType != $scope.loyaltyCardData.loyaltyCardDetalViewData.customerLoyaltyIdGroupingType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdGroups != $scope.loyaltyCardData.loyaltyCardDetalViewData.customerLoyaltyIdGroups) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyStores) {
                        if ($scope.companyStores.length != $scope.loyaltyCardData.loyaltyCardRedeemLocations.length) {
                            $rootScope.isDataSheetModified = true;
                        }
                    } else {
                        $rootScope.isDataSheetModified = true;
                    }
                } else {
                    if ($scope.detailedDisplayBackgroundColor != "#FFFFFF") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.backButtonColor != "#000000") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.cardBackgroundColor != "#FFFFFF") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromo != $scope.loyaltyCardData.companyData.compNamePromo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isCompNamePromoSameAsCo != true) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromoTextColor != "#4E525C") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionNameTextColor != "#000000") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdFormat) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.searchKeywords) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSubType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdInputLimitation) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdGroupingType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.customerLoyaltyIdGroups) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyStores) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        }

        $scope.isSameCompanyLogoImg = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isCardLogoImgURLSameAsCo == true) {
                $rootScope.logoCropperData.compLogoImg = $scope.loyaltyCardData.companyData.compLogoImgURL;
            } else {
                if ($scope.loyaltyCardId) {
                    $rootScope.logoCropperData.compLogoImg = !$scope.isCardLogoImgSameAsCo ?
                        $scope.loyaltyCardData.loyaltyCardDetalViewData.cardLogoImgURL : null
                } else {
                    $rootScope.logoCropperData = {};
                }
            }
        }

        var addOrUpdateLoyaltyCard = function (loyaltyCardForm, isBeforeActivation) {
            /*if ($scope.loyaltyCardPromoStatusCode == 0) {
                $scope.isActivateLoyaltyCard = false
            }*/
            return $q(function (resolve, reject) {
                if (!loyaltyCardForm.$valid) {

                    angular.forEach(loyaltyCardForm.$error, function (field) {
                        angular.forEach(field, function (errorField) {
                            errorField.$setTouched();
                        });
                    });
                    let errorInEnglishTranslation = null
                    if (!loyaltyCardForm.translatedCompNamePromo.$valid) {
                        errorInEnglishTranslation = 'translatedCompNamePromo'
                    } else if (!loyaltyCardForm.translatedPromotionName.$valid) {
                        errorInEnglishTranslation = 'translatedPromotionName'
                    } else if (!loyaltyCardForm.translatedCustomerLoyaltyIdErrorDisplayMsg.$valid) {
                        errorInEnglishTranslation = 'translatedCustomerLoyaltyIdErrorDisplayMsg'
                    }

                    if (errorInEnglishTranslation) {
                        $scope.langName = 'angol'
                        $scope.loyaltyCardForm.languageSelector.$touched = true
                        $scope.loyaltyCardForm.languageSelector.$valid = false
                        $scope.loyaltyCardForm.languageSelector.$invalid = true
                        $scope.loyaltyCardForm.languageSelector.$error = { required: true }
                        document.getElementById('languageSelector').scrollIntoView()
                    }

                    if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                        angular.element($document[0].querySelector('input.ng-invalid')).focus();
                    } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                        angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                    } else {
                        angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                    }
                } else if ($scope.isActivateLoyaltyCard && $rootScope.logoCropperData.compLogoImg == null) {
                    loyaltyCardForm.logoImage.$error.need = true;
                    //$('.main').animate({ scrollTop: $('div#logoImage').offset().top - 150 }, "slow");
                    const element = document.getElementById("logoImgContainer")
                    element.scrollIntoView();
                    reject(false);
                } else if (($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor || $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) && $scope.isActivateLoyaltyCard) {
                    var alert = $mdDialog.alert()
                        .title("Kis kontraszt - túl közeli színárnyalatok")
                        .htmlContent("A kártya megfelelő megjelenítéséhez és kényelmes használatához fontos, hogy a különböző elemek színei megfelelő kontrasztban legyenek egymással.<br><br>Jelenleg egy vagy több ponton a kártya elemeinek színei túl közel vannak egymáshoz. Kérjük adjon meg ezeknél markánsabban különböző színárnyalatokat.")
                        .ok('Javítás')
                    $mdDialog.show(alert).then(function () {
                        let contrastRatioError = "";
                        if ($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor) {
                            contrastRatioError = "cardContrastRatioError"
                        } else if ($scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) contrastRatioError = "detailedDisplayContrastRatioError";
                        const element = document.getElementById(contrastRatioError);
                        element.scrollIntoView();
                    });
                }else {
                    $scope.sendButtonDisabled = true;
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }
                    var loyaltyCard = {};
                    loyaltyCard.loyaltyCardId = $scope.loyaltyCardId;
                    loyaltyCard.detailedDisplayBackgroundColor = $scope.detailedDisplayBackgroundColor;
                    loyaltyCard.backButtonColor = $scope.backButtonColor;
                    loyaltyCard.cardBackgroundColor = $scope.cardBackgroundColor;
                    loyaltyCard.cardLogoImg = $rootScope.logoCropperData.compLogoImg;
                    loyaltyCard.isCardLogoImgURLSameAsCo = $scope.isCardLogoImgURLSameAsCo ? 1 : 0;
                    loyaltyCard.compNamePromo = $scope.compNamePromo;
                    loyaltyCard.isCompNamePromoSameAsCo = $scope.isCompNamePromoSameAsCo;
                    loyaltyCard.compNamePromoTextColor = $scope.compNamePromoTextColor;
                    loyaltyCard.promotionName = $scope.promotionName;
                    loyaltyCard.promotionNameTextColor = $scope.promotionNameTextColor;
                    loyaltyCard.customerLoyaltyIdFormat = $scope.customerLoyaltyIdFormat;
                    loyaltyCard.checkCustomerLoyaltyIdFormatOnFirstTyping = ($scope.customerLoyaltyIdFormat != null && $scope.customerLoyaltyIdFormat != '') ? ($scope.checkCustomerLoyaltyIdFormatOnFirstTyping != null ? $scope.checkCustomerLoyaltyIdFormatOnFirstTyping : false)  : null;
                    loyaltyCard.searchKeywords = $scope.searchKeywords;
                    loyaltyCard.barcodeType = $scope.barcodeType;
                    loyaltyCard.barcodeSubType = $scope.barcodeType == 1 ? $scope.barcodeSubType : null;
                    loyaltyCard.customerLoyaltyIdInputLimitation = $scope.customerLoyaltyIdInputLimitation;
                    loyaltyCard.customerLoyaltyIdErrorDisplayType = ($scope.customerLoyaltyIdFormat != null && $scope.customerLoyaltyIdFormat != '') ? $scope.customerLoyaltyIdErrorDisplayType : null;
                    loyaltyCard.customerLoyaltyIdErrorDisplayMsg = ($scope.customerLoyaltyIdFormat != null && $scope.customerLoyaltyIdFormat != '') ? $scope.customerLoyaltyIdErrorDisplayMsg : null;
                    loyaltyCard.customerLoyaltyIdGroupingType = $scope.customerLoyaltyIdGroupingType;
                    loyaltyCard.customerLoyaltyIdGroups = $scope.customerLoyaltyIdGroupingType == 2 ? $scope.customerLoyaltyIdGroups : null;
                    var stores = [];
                    for (var i in $scope.companyStores) {
                        stores[i] = $scope.companyStores[i];
                    }
                    loyaltyCard.isRedeemLocationsModified = $scope.isCompStoresSame;
                    //loyaltyCard.redeemLocationIndicator = $scope.isAllRedeemLocationIndicator ? 'all' : 'select'
                    loyaltyCard.redeemLocationIndicator = $scope.allRedeemLocationIndicator;
                    loyaltyCard.redeemLocations = stores;
                    if ($scope.isBeforeActivation) {
                        loyaltyCard.isBeforeActivation = $scope.isBeforeActivation;
                    } else {
                        loyaltyCard.isBeforeActivation = isBeforeActivation;
                    }

                    loyaltyCard.isTranslation_EN_Enabled = $scope.isEnglishTranslationEnabled == 'bekapcsolt'
                    loyaltyCard.compNamePromo_EN = $scope['EN'].compNamePromo
                    loyaltyCard.promotionName_EN = $scope['EN'].promotionName
                    loyaltyCard.customerLoyaltyIdErrorDisplayMsg_EN = ($scope.customerLoyaltyIdFormat != null && $scope.customerLoyaltyIdFormat != '') ? $scope['EN'].customerLoyaltyIdErrorDisplayMsg : null

                    if ($scope.loyaltyCardId && $scope.loyaltyCardPromoStatusCode == 1 && $scope.loyaltyCardData.loyaltyCardDetalViewData.loyaltyCardPromoStatus == 1) {
                        var confirm = $mdDialog.confirm()
                            .title('A törzskártyához futó promóció tartozik.')
                            .textContent('Biztosan módosítja a törzskártya adatait?')
                            .ok('Módosítás')
                            .cancel('Mégsem');
                        $mdDialog.show(confirm).then(function () {
                            LoyaltyCardService2.addOrUpdateLoyaltyCard(loyaltyCard).then(function (result) {
                                if (document.getElementById('loadingOverlay')) {
                                    document.getElementById('loadingOverlay').style.display = "none";
                                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                                }
                                if (result.errorCode == 0) {
                                    resolve(true);
                                } else {
                                    $scope.sendButtonDisabled = false;
                                    reject(result.errorCode);
                                }
                            }, function () {

                            })
                        }, function () {
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            $scope.sendButtonDisabled = false;
                        })
                    } else {
                        LoyaltyCardService2.addOrUpdateLoyaltyCard(loyaltyCard).then(function (result) {
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            if (result.errorCode == 0) {
                                resolve(true);
                            } else {
                                $scope.sendButtonDisabled = false;
                                reject(result.errorCode);
                            }
                        }, function () {

                        })
                    }
                }
            })
        }

        $scope.addOrUpdateLoyaltyCard = function (form) {
            if ($rootScope.isDataSheetModified == false) {
                const alert = $mdDialog.alert()
                    .title("A sablonon nem történt változtatás.")
                    .textContent("Az üres sablonon nem történt változtatás. Változtatás nélkül nem mentődik el a lap.")
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () { });
            } else {
                addOrUpdateLoyaltyCard(form, false).then(function (result) {
                    if (result == true) {
                        let alert;
                        if ($scope.loyaltyCardId) {
                            if ($scope.isActivateLoyaltyCard) {
                                alert = $mdDialog.alert()
                                    .title("A törzskártya módosításait elmentettük")
                                    .ok('Rendben')
                            } else {
                                alert = $mdDialog.alert()
                                    .title("A törzskártyát elmentettük")
                                    .htmlContent("Törzskártya aktiválása: a törzskártyát visszatöltve a lap jobb felső sarkában találja az aktiválás gombot, amivel az adott törzskártyát aktiválni, vagyis a felhasználók számára elérhetővé tenni tudja. <br><br> Aktiválni csak olyan törzskártyát lehet, amely esetén a lap minden mezője kitöltött.")
                                    .ok('Rendben')
                            }
                        } else {
                            alert = $mdDialog.alert()
                                .title("A törzskártyát elmentettük")
                                .htmlContent("Törzskártya aktiválása: a törzskártyát visszatöltve a lap jobb felső sarkában találja az aktiválás gombot, amivel az adott törzskártyát aktiválni, vagyis a felhasználók számára elérhetővé tenni tudja. <br><br> Aktiválni csak olyan törzskártyát lehet, amely esetén a lap minden mezője kitöltött.")
                                .ok('Rendben')
                        }
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $state.reload();
                        });
                    }
                }, function (result) {
                    if (result == 1) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result == 2) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $scope.sendButtonDisabled = false;
                            $state.go('partner_HU');
                        });
                    } else if (result == 3) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Törzskártya státusza miatt nem módosítható.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result == 4) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Az aktiváláshoz legalább 1 beváltóhelyet ki kell választani.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result == 5) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Az mentéshez nincsen a megfelelő előfizetési státuszban!")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    }
                })
            }
        }

        $scope.selectCompStore = function () {
            $scope.isCompStoresSame = true;
        }
        const setAllCompStores = function () {
            $scope.isCompStoresSame = true;
            if ($scope.companyStores == (null || undefined || '')) {
                if ($scope.allCompStores != null) {
                    for (var i = 0; i < $scope.allCompStores.length; i++) {
                        company.push($scope.allCompStores[i].id);
                    }
                    $scope.companyStores = company;
                    $scope.checkAll = false;
                }
            } else {
                $scope.companyStores = [];
                $scope.checkAll = true;
                company = [];
            }
        }
        $scope.checkRedeemLocations = function (isNoStoreAYCModified) {
            $rootScope.isDataSheetModified = true;
            if(isNoStoreAYCModified){
                if($scope.noStoreAYC == true){
                    $scope.isAllRedeemLocationIndicator = false;
                }
            } else {
                if ($scope.isAllRedeemLocationIndicator == true) {
                    $scope.noStoreAYC = false;
                }
            }
            if($scope.noStoreAYC == true){
                $scope.allRedeemLocationIndicator = "noStoreAYC";
                $scope.companyStores = [];
            } else if ($scope.isAllRedeemLocationIndicator === true) {
                $scope.allRedeemLocationIndicator = "all";
                $scope.checkAll = true;
                if ($scope.allCompStores != null) {
                    $scope.companyStores = [];
                    company = [];
                    for (var i = 0; i < $scope.allCompStores.length; i++) {
                        company.push($scope.allCompStores[i].id);
                    }
                    $scope.companyStores = company;
                }
            } else {
                $scope.allRedeemLocationIndicator = "select";
                $scope.checkAll = true;
                if ($scope.allCompStores != null) {
                    $scope.companyStores = [];
                    company = [];
                    for (var i = 0; i < $scope.allCompStores.length; i++) {
                        company.push($scope.allCompStores[i].id);
                    }
                    $scope.companyStores = company;
                }
            }
        }
        $scope.$watch('companyStores', function (current) {
            if (current) {
                if (current.length === $scope.allCompStores.length) {
                    $scope.checkAll = true;
                } else {
                    $scope.checkAll = false;
                }
            } else {
                $scope.checkAll = false;
            }
        })

        $scope.setAllCompStores = function () {
            setAllCompStores();
        };

        $scope.compNamePromoSameAsCo = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isCompNamePromoSameAsCo == true) {
                $scope.compNamePromo = $scope.loyaltyCardData.companyData.compNamePromo;
            } else {
                $scope.compNamePromo = undefined;
            }
        }

        var modifyLoyaltyCard = function ({ id, isTemplate }) {
            $scope.loyaltyCardId = id;
            LoyaltyCardService2.modifyLoyaltyCard(id).then(function (result) {
                if (result.errorCode == 0) {
                    $scope.child.hideLCList()
                    resetVariables();
                    $scope.loyaltyCardData = result;
                    $scope.loyaltyCardPromoStatusText = $scope.promoStatusText[result.loyaltyCardDetalViewData.loyaltyCardPromoStatus].text;
                    var date = new Date(result.loyaltyCardDetalViewData.lastUpdatedTimestamp);
                    var year = $filter('date')(date, "yyyy");
                    var month = $filter('date')(date, "MM");
                    var day = $filter('date')(date, "dd");
                    var monthInHungarian = getMonth(month);
                    $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";
                    $scope.animateMobile = "yalty-animateTo-mobile";
                    $scope.openVirtual = { "margin-top": "0px" };
                    $scope.allCompStores = result.companyStoreData;
                    $scope.compNamePromo = result.loyaltyCardDetalViewData.compNamePromo;
                    $scope.isCompNamePromoSameAsCo = result.loyaltyCardDetalViewData.isCompNamePromoSameAsCo;
                    $scope.isEnglishTranslationEnabled = result.companyData.isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt'
                    $scope.translationEnabled = result.companyData.isTranslation_EN_Enabled
                    $scope.languages = [
                        { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                        {
                            id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                            url: "../imgs/translation_flag_EN.png",
                            windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                        }
                    ]
                    $scope.partnerData = {}
                    $scope.languageSelector = 1
                    $scope['EN'] = {}
                    $scope['EN'].compNamePromo = result.loyaltyCardDetalViewData.compNamePromo_EN
                    $scope['EN'].promotionName = result.loyaltyCardDetalViewData.promotionName_EN
                    $scope['EN'].customerLoyaltyIdErrorDisplayMsg = result.loyaltyCardDetalViewData.customerLoyaltyIdErrorDisplayMsg_EN
                    $scope.translatedCompNamePromo = result.loyaltyCardDetalViewData.compNamePromo_EN
                    $scope.partnerData.translatedCompNamePromo = result.companyData.compNamePromo_EN
                    $scope.translatedPromotionName = result.loyaltyCardDetalViewData.promotionName_EN
                    $scope.translatedCustomerLoyaltyIdErrorDisplayMsg = result.loyaltyCardDetalViewData.customerLoyaltyIdErrorDisplayMsg_EN
                    if (isTemplate == false) {
                        $scope.loyaltyCardId = result.loyaltyCardDetalViewData.id;
                        $scope.promotionName = result.loyaltyCardDetalViewData.promotionName;
                        $scope.loyaltyCardPromoStatusCode = result.loyaltyCardDetalViewData.loyaltyCardPromoStatus;
                        $rootScope.logoCropperData.compLogoImg = result.loyaltyCardDetalViewData.cardLogoImgURL;
                    } else {
                        $scope.loyaltyCardPromoStatusCode = 0;
                        $scope.loyaltyCardId = undefined;

                        var logoImg = new Image();
                            var logoCanvas = document.getElementById("logoCanvas");
                            var logoContext = logoCanvas.getContext("2d");
                            logoImg.src = result.loyaltyCardDetalViewData.cardLogoImgURL;
                            logoImg.onload = function () {
                                logoContext.canvas.height = logoImg.height;
                                logoContext.canvas.width = logoImg.width;
                                logoContext.drawImage(logoImg, 0, 0);
                                const format = result.loyaltyCardDetalViewData.cardLogoImgURL.split('.')[1]
                                const dataURL = logoCanvas.toDataURL(`image/${format}`)
                                $rootScope.logoCropperData.compLogoImg = dataURL
                        }
                        /*if (result.loyaltyCardDetalViewData.promotionName != null && result.loyaltyCardDetalViewData.promotionName != "") {
                            if ((result.loyaltyCardDetalViewData.promotionName + " (MÁSOLAT)").length <= 60) {*/
                                $scope.promotionName = result.loyaltyCardDetalViewData.promotionName/* + " (MÁSOLAT)";
                            } else {
                                var slice;
                                if (result.loyaltyCardDetalViewData.promotionName.length > 47) {
                                    slice = result.loyaltyCardDetalViewData.promotionName.slice(0, 47);
                                }
                                $scope.promotionName = slice + "... (MÁSOLAT)";
                            }
                        } else {
                            $scope.promotionName = null;
                        }*/
                    }
                    $scope.detailedDisplayBackgroundColor = result.loyaltyCardDetalViewData.detailedDisplayBackgroundColor;
                    $scope.backButtonColor = result.loyaltyCardDetalViewData.backButtonColor;
                    $scope.cardBackgroundColor = result.loyaltyCardDetalViewData.cardBackgroundColor;
                    $scope.isCardLogoImgURLSameAsCo = result.loyaltyCardDetalViewData.isCardLogoImgURLSameAsCo;
                    $scope.isCardLogoImgSameAsCo = result.loyaltyCardDetalViewData.isCardLogoImgURLSameAsCo;
                    $scope.compNamePromoTextColor = result.loyaltyCardDetalViewData.compNamePromoTextColor;
                    $scope.promotionNameTextColor = result.loyaltyCardDetalViewData.promotionNameTextColor;
                    $scope.customerLoyaltyIdFormat = result.loyaltyCardDetalViewData.customerLoyaltyIdFormat;
                    $scope.checkCustomerLoyaltyIdFormatOnFirstTyping = result.loyaltyCardDetalViewData.checkCustomerLoyaltyIdFormatOnFirstTyping;
                    $scope.searchKeywords = result.loyaltyCardDetalViewData.searchKeywords;
                    $scope.barcodeType = result.loyaltyCardDetalViewData.barcodeType;
                    $scope.barcodeSubType = result.loyaltyCardDetalViewData.barcodeSubType;
                    $scope.customerLoyaltyIdInputLimitation = result.loyaltyCardDetalViewData.customerLoyaltyIdInputLimitation;
                    $scope.customerLoyaltyIdErrorDisplayType = result.loyaltyCardDetalViewData.customerLoyaltyIdErrorDisplayType;
                    $scope.customerLoyaltyIdErrorDisplayMsg = result.loyaltyCardDetalViewData.customerLoyaltyIdErrorDisplayMsg;
                    $scope.customerLoyaltyIdGroupingType = result.loyaltyCardDetalViewData.customerLoyaltyIdGroupingType;
                    $scope.customerLoyaltyIdGroups = result.loyaltyCardDetalViewData.customerLoyaltyIdGroups;
                    //kontrasztot ellenőrző metódus defeault lefutása
                    $scope.checkColorContrastConflitions('cardBackgroundColor', $scope.cardBackgroundColor);
                    $scope.checkColorContrastConflitions('detailedDisplayBackgroundColor', $scope.detailedDisplayBackgroundColor);

                    if (result.loyaltyCardDetalViewData.redeemLocationIndicator == "all") {
                        $scope.allRedeemLocationIndicator = "all";
                        $scope.isAllRedeemLocationIndicator = true;
                        $scope.noStoreAYC = false;
                        setAllCompStores();
                    } else if(result.loyaltyCardDetalViewData.redeemLocationIndicator == "select") {
                        $scope.allRedeemLocationIndicator = "select";
                        $scope.isAllRedeemLocationIndicator = false;
                        $scope.noStoreAYC = false;
                        for (var i = 0; i < result.loyaltyCardRedeemLocations.length; i++) {
                            company.push(result.loyaltyCardRedeemLocations[i].companyStoreId);
                        }
                        $scope.companyStores = company;
                    } else if(result.loyaltyCardDetalViewData.redeemLocationIndicator == "noStoreAYC"){
                        $scope.allRedeemLocationIndicator = "noStoreAYC";
                        $scope.isAllRedeemLocationIndicator = false;
                        $scope.noStoreAYC = true;
                    }
                    if ($scope.loyaltyCardPromoStatusCode == 1) {
                        $scope.isBeforeActivation = true;
                        $scope.isActivateLoyaltyCard = true;
                    }
                    if (isTemplate == false) {
                        $rootScope.isDataSheetModified = false;
                    } else {
                        $rootScope.isDataSheetModified = true;
                    }
                    $scope.showLoyaltys = false;
                    $scope.showSetLoyalty = true;
                    $scope.sendButtonDisabled = true;
                } else {
                    var alert = $mdDialog.alert()
                        .title('Sikertelen azonosítás')
                        .textContent('Felhasználó azonosítása sikertelen, ezért ki kell léptetnünk az oldalról.')
                        .ariaLabel('Session expired.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $rootScope.isDataSheetModified = false;
                        $cookies.remove('firstName');
                        $cookies.remove('lastName');
                        $cookies.remove('nameTitle');
                        $cookies.remove('areRedeemPageFilterSettingsPredefined');
                        $cookies.remove('defaultRedeemPageShowFilter');
                        $cookies.remove('defaultRedeemPageCityFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('userEmail');
                        $cookies.remove('userCompany');
                        $cookies.remove('compLogo');
                        $cookies.remove('authorizationLevel');
                        $cookies.remove('currentUserType');
                        $cookies.remove('expdt');
                        $cookies.remove('alrtdt');
                        $cookies.remove('redeemPageShowFilter');
                        $cookies.remove('redeemPageCompanyStoreFilter');
                        $cookies.remove('redeemPageCityFilter');
                        $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
                        $cookies.remove('redeemPageCityFilterCheckAll');
                        $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
                        $cookies.remove('canSendIndividualPushMessages');
                        $cookies.remove('freePackageSubscriptionExpTimestamp');
                        $cookies.remove('isYaltyStartEnabled');
                        $window.localStorage.clear();
                        $rootScope.authorizationLevel = null;
                        $rootScope.$$listeners['timer-tick'] = [];
                        $rootScope.$$listeners['timer-stopped'] = [];
                        $rootScope.$broadcast('timer-stop');
                        $state.go('partner_HU.login');
                    });
                }
            }, function () {

            })
        }

        $scope.modifyLoyaltyCard = function (paramObj) {
            if ($rootScope.isDataSheetModified == false) {
                modifyLoyaltyCard(paramObj);
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    modifyLoyaltyCard(paramObj);
                }, function () {
                });
            }
        }

        $scope.activateOrDeactivateloyaltyCard = function (id, activateOrDeactivate, form) {
            $scope.isActivateLoyaltyCard = true;
            form.$setSubmitted();
            if (activateOrDeactivate == "activate") {
                setTimeout(function () {
                    if (form.$valid) {
                        if ($rootScope.logoCropperData.compLogoImg == null) {
                            form.logoImage.$error.need = true;
                            const element = document.getElementById("logoImgContainer")
                            element.scrollIntoView();
                        } else if ($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor || $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) {
                            var alert = $mdDialog.alert()
                                .title("Kis kontraszt - túl közeli színárnyalatok")
                                .htmlContent("A kártya megfelelő megjelenítéséhez és kényelmes használatához fontos, hogy a különböző elemek színei megfelelő kontrasztban legyenek egymással.<br><br>Jelenleg egy vagy több ponton a kártya elemeinek színei túl közel vannak egymáshoz. Kérjük adjon meg ezeknél markánsabban különböző színárnyalatokat.")
                                .ok('Javítás')
                            $mdDialog.show(alert).then(function () {
                                let contrastRatioError = "";
                                if ($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor) {
                                    contrastRatioError = "cardContrastRatioError"
                                } else if ($scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) contrastRatioError = "detailedDisplayContrastRatioError";
                                const element = document.getElementById(contrastRatioError);
                                element.scrollIntoView();
                            });
                        }  else if (($scope.barcodeType) &&
                            ($scope.companyStores.length > 0 || $scope.allRedeemLocationIndicator == "noStoreAYC")) {
                            var confirm = $mdDialog.confirm()
                                .title('Aktiválás megerősítése')
                                .htmlContent("Aktiválás esetén a törzskártyát élesítjük vagyis a felhasználók számára elérhetővé tesszük. <br>" +
                                    "<br>" +
                                    "A kártya sikeres aktiváció után már megjelenik a mobil applikációban, ott ellenőrizni tudja a kívánt végleges megjelenést. <br>" +
                                    "<br>" +
                                    "Megjegyzés: ha a Yalty applikáció már nyitva volt a telefonján, akkor egy kis időnek el kell telnie mire az alkalmazás újra " +
                                    "megnyitásakor az új kártyát magától frissíti. Ilyenkor kezdeményezheti a frissítést standard módon úgy is, ha bármelyik lista " +
                                    "esetén az alkalmazásban (hűségkártyák, partnerek listája stb.) a lista tetejét tovább húzza lefelé. Illetve, ha az " +
                                    "alkalmazást teljesen lecsukja és újraindítja, az olyankor is automatikusan frissül.")
                                .ok('Aktiválás')
                                .cancel('Mégsem');
                            $mdDialog.show(confirm).then(function () {
                                $scope.sendButtonDisabled = true;
                                addOrUpdateLoyaltyCard(form, true).then(function () {
                                    LoyaltyCardService2.activateOrDeactivateLoyaltyCard(id, activateOrDeactivate).then(function (result) {
                                        if (result.errorCode == 0) {
                                            let alert;
                                            if (activateOrDeactivate == "activate") {
                                                let subscriptionType = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[0] : '';
                                                let subscriptionEndTimestamp = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[6] : ''
                                                let sbcrtyp = "";
                                                if (subscriptionType == 'yalty_start_free' && subscriptionEndTimestamp != '9999-12-31T23:59:59.997Z') {
                                                    for (let i = 0; i < 6; i++) {
                                                        sbcrtyp += $cookies.get('sbcrtyp').split('&')[i];
                                                        sbcrtyp += "&";
                                                    }
                                                    sbcrtyp += "9999-12-31T23:59:59.997Z";
                                                    $cookies.put('sbcrtyp', sbcrtyp);
                                                }
                                                alert = $mdDialog.alert()
                                                    .title("Sikeres aktiváció")
                                                    .textContent("A törzskártya aktiválása sikeres.")
                                                    .ok('Rendben')
                                            } else {
                                                alert = $mdDialog.alert()
                                                    .title("Sikeres deaktiváció")
                                                    .textContent("A törzskártya promóciójának törlése sikeres.")
                                                    .ok('Rendben')
                                            }
                                            $mdDialog.show(alert).then(function () {
                                                $rootScope.isDataSheetModified = false;
                                                $scope.sendButtonDisabled = false;
                                                $state.reload();
                                            });
                                        } else if (result.errorCode == 1) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = false;
                                            });
                                        } else if (result.errorCode == 2) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $rootScope.isDataSheetModified = false;
                                                $scope.sendButtonDisabled = false;
                                                $state.go('partner_HU');
                                            });
                                        } else if (result.errorCode == 3) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("A törzskártya nincsen 'még nem prómótált' státuszban.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = false;
                                            });
                                        } else if (result.errorCode == 4) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("A törzskártya nincsen 'futó promóció' státuszban.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = false;
                                            });
                                        } else if (result.errorCode == 5) {
                                            const alert = $mdDialog.alert()
                                                .title("FIGYELEM: A promóciót nem aktiváltuk!")
                                                .textContent("A promócióhoz nem tartozik olyan üzlet, amely aktív Yalty városhoz tartozik. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                                    + "Állítsa be a promócióhoz tartozó üzlet adatlapján a Yalty város mezőt és ezután aktiválja újra a promóciót.\n\n"
                                                    + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = true;
                                                $rootScope.isDataSheetModified = false;
                                            });
                                        } else if (result.errorCode == 6) {
                                            const alert = $mdDialog.alert()
                                                .title("FIGYELEM: A promóciót aktiváltuk, de egy vagy több, a promócióhoz tartozó üzlet városához nem fog megjelenni!")
                                                .textContent("A promócióhoz tartozik egy vagy több olyan üzlet, amely nem köthető Yalty városhoz. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                                    + "Állítsa be a Yalty város mezőt a promócióhoz tartozó ezen üzlet(ek) adatlapján.\n\n"
                                                    + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $rootScope.isDataSheetModified = false;
                                                $scope.sendButtonDisabled = false;
                                                $state.reload();
                                            });
                                        }
                                    }, function (result) {
                                        $scope.sendButtonDisabled = false;
                                    })
                                }, function () {
                                    $scope.sendButtonDisabled = false;
                                });
                            }, function (result) {
                                $scope.sendButtonDisabled = false;
                                if (result != false) {
                                    if (result == 1) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    } else if (result == 2) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $rootScope.isDataSheetModified = false;
                                            $scope.sendButtonDisabled = false;
                                            $state.go('partner_HU');
                                        });
                                    } else if (result == 3) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Törzskártya státusza miatt nem módosítható.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    } else if (result == 4) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Az mentéshez nincsen a megfelelő előfizetési státuszban!")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    } else if (result == 5) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Az aktiváláshoz legalább 1 beváltóhelyet ki kell választani.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    }
                                }
                            })
                        } else {
                            if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                                angular.element($document[0].querySelector('input.ng-invalid')).focus();
                            } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                                angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                            } else if (angular.element($document[0].querySelector('textarea.ng-invalid')).length > 0) {
                                angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                            } else {
                                console.log("hidden error")
                            }
                        }
                    } else {

                        let errorInEnglishTranslation = null
                        if ($scope.translationEnabled) {
                            if (!form.translatedCompNamePromo.$valid) {
                                errorInEnglishTranslation = 'translatedCompNamePromo'
                            } else if (!form.translatedPromotionName.$valid) {
                                errorInEnglishTranslation = 'translatedPromotionName'
                            } else if (!form.translatedCustomerLoyaltyIdErrorDisplayMsg.$valid) {
                                errorInEnglishTranslation = 'translatedCustomerLoyaltyIdErrorDisplayMsg'
                            }
                        }
                        if (errorInEnglishTranslation) {
                            $scope.langName = 'angol'
                            $scope.loyaltyCardForm.languageSelector.$touched = true
                            $scope.loyaltyCardForm.languageSelector.$valid = false
                            $scope.loyaltyCardForm.languageSelector.$invalid = true
                            $scope.loyaltyCardForm.languageSelector.$error = { required: true }
                            document.getElementById('languageSelector').scrollIntoView()
                        }

                        if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                            angular.element($document[0].querySelector('input.ng-invalid')).focus();
                        } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {

                            angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                        } else if (angular.element($document[0].querySelector('textarea.ng-invalid')).length > 0) {
                            angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                        } else {
                            console.log('hidden error');
                        }

                    }
                }, 500);
            } else if (activateOrDeactivate == "stopNewCardIssuing") {
                const confirm = $mdDialog.confirm()
                    .title('Kifuttatás megerősítése')
                    .htmlContent('<div style="width: 600px;">'
                        + '<p>A promóció kifuttatása esetén új törzskártyát a felhasználók már nem tudnak megkezdeni. A felhasználói eszközökről a következő frissítés alkalmával a törzskártya törlésre kerül. <br><br>' +
                        'Azon felhasználóink, akik már korábban elkezdték használni a kártyát, nem tapasztalnak majd semmilyen változást, a kártyát tovább tudják használni.</p></div>')
                    .ariaLabel('Kifuttatás megerősítése')
                    .ok('Kifuttatás')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $scope.sendButtonDisabled = true;
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }
                    LoyaltyCardService2.activateOrDeactivateLoyaltyCard(id, activateOrDeactivate).then(function (result) {
                        if (document.getElementById('loadingOverlay')) {
                            document.getElementById('loadingOverlay').style.display = "none";
                            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                        }
                        if (result.errorCode == 0) {
                            const alert = $mdDialog.alert()
                                .title("Sikeres kifuttatás")
                                .textContent("A törzskártya promóciójának kifuttatás sikeres.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.reload();
                            });
                        } else if (result.errorCode == 1) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 2) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.go('partner_HU');
                            });
                        } else if (result.errorCode == 3) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A törzskártya nincsen 'még nem prómótált' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 4) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A törzskártya nincsen 'futó promóció' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        }
                    }, function () {
                    });
                }, function () { });
            } else {
                const confirm = $mdDialog.confirm()
                    .title('Törlés megerősítése')
                    .htmlContent('<div><p>A promóció törlése esetén a törzskártya megjelenését és további használatát visszavonjuk. A felhasználói eszközökről következő frissítésük alkalmával a kártya törlésre kerül. <br><br>' +
                        'Azon felhasználóinknak, akiknek van nyitott, használatban lévő kártyája, üzenetet jelenítünk meg a törlésről. <br><br>' +
                        'A törlés után már egyik felhasználónk sem tudja majd felhasználni a kártyát - ezért a törlés helyett első lépésben a kifuttatást javasoljuk.</div></p>')
                    .ariaLabel('Törlés megerősítése')
                    .ok('Törlés')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $scope.sendButtonDisabled = true;
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }
                    LoyaltyCardService2.activateOrDeactivateLoyaltyCard(id, activateOrDeactivate).then(function (result) {
                        if (document.getElementById('loadingOverlay')) {
                            document.getElementById('loadingOverlay').style.display = "none";
                            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                        }
                        if (result.errorCode == 0) {
                            const alert = $mdDialog.alert()
                                .title("A törzskártya promóciót sikeresen töröltük.")
                                .htmlContent('<div><p>Kérjük tájékoztassa ügyfeleit, ' +
                                    'hogy a kártyát a továbbiakban már nem tudják majd használni.<br><br> ' +
                                    'Ha most hozott létre új kártyát is egyben a régi, törölt helyett, akkor a tájékoztatással ' +
                                    'együtt promóciózza számukra ezt az új kártyát is.</p></div>')
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.reload();
                            });
                        } else if (result.errorCode == 1) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 2) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.go('partner_HU');
                            });
                        } else if (result.errorCode == 3) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A törzskártya nincsen 'még nem prómótált' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 4) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A törzskártya nincsen 'futó promóció' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        }
                    }, function (result) {
                        $scope.sendButtonDisabled = false;
                    })
                }, function () { })
            }
        }

        $scope.removeLoyaltyCard = function (ev) {
            var title = $scope.isActivateLoyaltyCard ? 'A törzskártyához futó promóció tartozik. A törzskártya törlésével a promóció is törlődik. A törzskártyát jelenleg is használó felhasználók törlés után már nem tudják tovább használni kártyáikat vagy beváltani a kedvezményeiket. Biztosan törli a kártyát?'
                : 'Biztosan törli a kártyát?'
            var confirm = $mdDialog.confirm()
                .title(title)
                .ariaLabel('Törlés megerősítése')
                .targetEvent(ev)
                .ok('Igen')
                .cancel('Mégsem');

            $mdDialog.show(confirm).then(function () {
                if ($scope.loyaltyCardId) {
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }
                    if ($rootScope.isDataSheetModified == true) {
                        $rootScope.isDataSheetModified = false;
                    }
                    var loyaltyCard = {};
                    loyaltyCard.loyaltyCardId = $scope.loyaltyCardId;
                    $http.post(API_PREFIX.url + '/loyaltyCard/removeLoyaltyCard', loyaltyCard).then(function (result) {
                        if (result.data.errorCode == 0) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_törlés_sikeres",
                                "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya törlése sikeres")
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            const alert = $mdDialog.alert()
                                .title("Sikeres művelet")
                                .textContent("Sikeres törzskártya törlés.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.reload();
                            });
                        } else if (result.data.errorCode == 1) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_törlés_sikertelen",
                                "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya törlése sikertelen")
                                const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 2) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_törlés_sikertelen",
                                "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya törlése sikertelen")
                                const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.go('partner_HU');
                            });
                        }
                    }, function () {
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_törlés_sikertelen",
                            "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya törlése sikertelen")
                    });
                }
            }, function () {
            });
        }

        $scope.getLoyaltys = function () {
            if ($scope.showLoyaltys) {
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.showLoyaltys = false;
                $scope.openVirtual = { "margin-top": "0px" };
            } else {
                $scope.animateMobile = "yalty-animateFrom-mobile"
                $scope.showLoyaltys = true;
                $scope.openVirtual = { "margin-top": "-" + (virtualSize) + "px" };
            }
        }

        $scope.child = {}

        var setLoyalty = function () {
            LoyaltyCardService2.modifyLoyaltyCard(null).then(function (result) {
                $scope.child.hideLCList()
                resetVariables();
                $scope.loyaltyCardData = result;
                $scope.allCompStores = result.companyStoreData;
                $scope.compNamePromo = result.companyData.compNamePromo;
                $rootScope.logoCropperData.compLogoImg = result.companyData.compLogoImgURL;
                $scope.isCompNamePromoSameAsCo = true;
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.openVirtual = { "margin-top": "0px" };
                $scope.newloyaltyCard = "Új törzskártya";
                var date = new Date();
                var year = $filter('date')(date, "yyyy");
                var month = $filter('date')(date, "MM");
                var day = $filter('date')(date, "dd");
                var monthInHungarian = getMonth(month);
                $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";

                $scope.isEnglishTranslationEnabled = result.companyData.isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt'
                $scope.translationEnabled = result.companyData.isTranslation_EN_Enabled
                $scope.languages = [
                    { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                    {
                        id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                        url: "../imgs/translation_flag_EN.png",
                        windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                    }
                ]
                $scope.languageSelector = 1
                $scope['EN'] = {}
                $scope['EN'].compNamePromo = null
                $scope['EN'].promotionName = null
                $scope.partnerData = {}
                $scope.partnerData.translatedCompNamePromo = result.companyData.compNamePromo_EN



                setTimeout(function () {
                    $scope.showLoyaltys = false;
                    $scope.showSetLoyalty = true;
                }, 50)
                $scope.sendButtonDisabled = true;
            }, function (result) {
            })
        }
        $scope.setLoyalty = function () {
            if ($rootScope.isDataSheetModified == false) {
                setLoyalty();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    setLoyalty();
                }, function () {
                });
            }
        }

        if ($stateParams.loyaltyCardId && $stateParams.loyaltyCardId != 0) {
            $scope.modifyLoyaltyCard({id: $stateParams.loyaltyCardId, isTemplate: false});
        } else {
            $scope.showLoyaltys = true;
            $scope.showSetLoyalty = false;
            $scope.animateMobile = "";
        }

        function getMonth(month) {
            if (month == "01") {
                return "január";
            } else if (month == "02") {
                return "február";
            } else if (month == "03") {
                return "március";
            } else if (month == "04") {
                return "április";
            } else if (month == "05") {
                return "május";
            } else if (month == "06") {
                return "június";
            } else if (month == "07") {
                return "július";
            } else if (month == "08") {
                return "augusztus";
            } else if (month == "09") {
                return "szeptember";
            } else if (month == "10") {
                return "október";
            } else if (month == "11") {
                return "november";
            } else if (month == "12") {
                return "december";
            }
        }
    })