// @ts-nocheck

import { downgradeComponent } from '@angular/upgrade/static';
import { LoyaltyCardYaltyDashboardComponent } from 'src/app/pages/loyalty-card-yalty-dashboard/loyalty-card-yalty-dashboard.component';
import { LoyaltyCardYaltyTimeSeriesStatsComponent } from 'src/app/pages/loyalty-card-yalty-time-series-stats/loyalty-card-yalty-time-series-stats.component';
import { LoyaltyCardYaltyUserStatsComponent } from 'src/app/pages/loyalty-card-yalty-user-stats/loyalty-card-yalty-user-stats.component';

angular.module('yaltyApp')
    .directive("premium", function () {
        return {
            restrict: "A",
            scope: {
                premium: '='
            },
            link: function (scope, element, attributes) {
                if (scope.premium != "premium" && scope.premium != "custom") {
                    element.addClass('higherPartnerSubscriptionTypeRequired');
                }
            }
        };
    })
    .directive("basic", function () {
        return {
            restrict: "A",
            scope: {
                basic: '='
            },
            link: function (scope, element, attributes) {
                if (scope.basic == "yalty_start_free" || scope.basic == "yalty_start_paid") {
                    element.addClass('higherPartnerSubscriptionTypeRequired');
                }
            }
        };
    })
    .directive('loyaltyCardYaltyTimeSeriesStats', downgradeComponent({ component: LoyaltyCardYaltyTimeSeriesStatsComponent }) as angular.IDirectiveFactory)
    .directive('loyaltyCardYaltyUserStats', downgradeComponent({ component: LoyaltyCardYaltyUserStatsComponent }) as angular.IDirectiveFactory)
    .directive('loyaltyCardYaltyDashboard', downgradeComponent({ component: LoyaltyCardYaltyDashboardComponent }) as angular.IDirectiveFactory)

    .controller('husegCtrl', function ($scope, $rootScope, $anchorScroll, $timeout, $filter, $http, $cookies, $stateParams, $location, $state, $window, $document, $q, $mdDialog, API_PREFIX, SubscriptionNames, LoyaltyCardService, GoogleAnalyticsService) {
        $scope.lists = [
            {
                items: [
                    { name: "" }
                ]
            }
        ];

        $scope.originalLists = [
            {
                items: [
                    { name: "" }
                ]
            }
        ];


        $scope.isCardBackgroundColorConflictedWithPromotionCompanyName = false;
        $scope.isCardBackgroundColorConflictedWithDiscountName = false;
        $scope.isCardBackgroundColorConflictedWithStampFixColor = false;
        $scope.isCardBackgroundColorConflictedWithStampCounterColor = false;
        $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor = false;
        $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons = false;

        var acceptableContrastRatio = 10.0; //Megadható mekkora kontrasztbeli eltérés esetén jelenjen meg figyelmeztetés, a felhasználó felé

        $scope.checkColorContrastConflitions = function (name, value) {
            if (typeof value === 'undefined') {
                return;
            }
            let valueRgbColor = hexaToRgb(value);

            var contrastRatio = 0;

            switch (name) {
                case 'compNamePromoTextColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithPromotionCompanyName = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'promotionNameTextColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithDiscountName = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'stampTextPlaceholderColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithStampFixColor = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'stampCurrentCountTextColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithStampCounterColor = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'detailedDisplayBackgroundColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.cardBackgroundColor));
                    $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor = contrastRatio <= acceptableContrastRatio ? true : false;

                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.backButtonColor));
                    $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'backButtonColor':
                    contrastRatio = deltaE(valueRgbColor, hexaToRgb($scope.detailedDisplayBackgroundColor));
                    $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons = contrastRatio <= acceptableContrastRatio ? true : false;
                    break;
                case 'cardBackgroundColor':
                    $scope.checkColorContrastConflitions('compNamePromoTextColor', $scope.compNamePromoTextColor);
                    $scope.checkColorContrastConflitions('promotionNameTextColor', $scope.promotionNameTextColor);
                    $scope.checkColorContrastConflitions('stampTextPlaceholderColor', $scope.stampTextPlaceholderColor);
                    $scope.checkColorContrastConflitions('stampCurrentCountTextColor', $scope.stampCurrentCountTextColor);
                    $scope.checkColorContrastConflitions('detailedDisplayBackgroundColor', $scope.detailedDisplayBackgroundColor);
                    break;
            }
        }

        function hexaToRgb(hexColor) {
            let r, g, b;
            hexColor = hexColor.replace("#", "")
                , r = parseInt(hexColor.substring(0, 2), 16)
                , g = parseInt(hexColor.substring(2, 4), 16)
                , b = parseInt(hexColor.substring(4, 6), 16);

            let rgbColor = [r, g, b];
            return rgbColor;
        }

        function deltaE(rgbA, rgbB) {
            let labA = rgb2lab(rgbA);
            let labB = rgb2lab(rgbB);
            let deltaL = labA[0] - labB[0];
            let deltaA = labA[1] - labB[1];
            let deltaB = labA[2] - labB[2];
            let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
            let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
            let deltaC = c1 - c2;
            let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
            deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
            let sc = 1.0 + 0.045 * c1;
            let sh = 1.0 + 0.015 * c1;
            let deltaLKlsl = deltaL / (1.0);
            let deltaCkcsc = deltaC / (sc);
            let deltaHkhsh = deltaH / (sh);
            let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
            return i < 0 ? 0 : Math.sqrt(i);
        }

        function rgb2lab(rgb) {
            let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
            r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
            g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
            b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
            x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
            y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
            z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
            x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
            y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
            z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;
            return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
        }

        function arraysEqual(a, b) {
            if (a.length != b.length) return false;
            for (let i = 0; i < a.length; i++) {
                if (a[i].name != b[i].name) {
                    return false;
                }
            }
            return true;
        }


        $scope.$watch('lists', function (lists) {
            if (arraysEqual($scope.lists[0].items, $scope.originalLists[0].items) == false) {
                $scope.sendButtonDisabled = false
                $rootScope.isDataSheetModified = true
            }
        }, true);

        $scope.addListItem = function (name) {
            $scope.lists[0].items.push({ name: name });
            setTimeout(() => {
                let adottsor = 'sor' + ($scope.lists[0].items.length-1);
                document.getElementById(adottsor).focus();
            }, 0);
        }
        function addListItem(name){
            $scope.lists[0].items.push({ name: name });
        }

        $scope.getListItems = function () {
            let array = [];
            for (let i = 0; i < $scope.lists[0].items.length; i++) {
                if ($scope.lists[0].items[i].name.length > 0) {
                    array.push($scope.lists[0].items[i].name);
                }
            }
            return array;
        }

        $scope.removeListItem = function (index) {
            if ($scope.lists[0].items[index].name.length > 0) {
                var confirm = $mdDialog.confirm({
                    title: 'Törlés megerősítése',
                    textContent: 'Biztosan törlőd a "' + $scope.lists[0].items[index].name + '" tételt a listából?',
                    ok: 'Igen',
                    cancel: 'Mégsem'
                })
                $mdDialog.show(confirm).then(function () {
                    if ($scope.lists[0].items.length == 1) {
                        $scope.lists[0].items[0].name = "";
                    } else {
                        $scope.lists[0].items.splice(index, 1);
                    }
                }, function () {
                });
            } else {
                if ($scope.lists[0].items.length != 1) {
                    $scope.lists[0].items.splice(index, 1);
                }
            }
        }

        $rootScope.isDataSheetModified = false;
        $scope.child = {}
        $scope.isActivateLoyaltyCard = false;
        $scope.newloyaltyCard = "Új hűségkártya";
        const loyaltyCardCount = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[1] : 1;
        $scope.SubscriptionNames = SubscriptionNames;
        var stampIconStampedColor, stampIconUnstampedColor;
        //$scope.buttonClass = "activeBtn";
        //$scope.newButtonClass = "btn";
        $scope.animateMobile = "";
        $scope.showLoyaltys = true;
        //$scope.isOpened = "fa fa-chevron-up";
        $rootScope.stampCropperData = {};
        $rootScope.logoCropperData = {};
        $rootScope.unstampCropperData = {};
        $scope.logoZoomSlider = 0;
        $scope.logoSliderMin = 0;
        $scope.logoSliderMax = 10;
        $scope.zoomSlider = 0;
        $scope.sliderMin = 0;
        $scope.sliderMax = 10;
        $scope.stampZoomSlider = 0;
        $scope.stampSliderMin = 0;
        $scope.stampSliderMax = 10;
        $scope.MIN_PECSETELESEK_SZAMA = 2;
        $scope.MAX_PECSETELESEK_SZAMA = 30;
        var uniqueStamp = {}, uniqueUnstamp = {}, shareIconCanvas = {}, backCanvas = {}, addIconCanvas = {};
        $scope.isNumber = function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }
        var virtualSize = $window.innerHeight - 300;
        var company = [];
        $scope.companyStores = [];
        $scope.allCompStores = [];
        $scope.containerSize = { "height": virtualSize + "px", "width": "300px", "margin-left": "8px" };

        $scope.promoStatusText = [{ code: 0, text: "még nem promótált hűségkártya" },
        { code: 1, text: "futó promóció" },
        { code: 2, text: "kifutó promóció" },
        { code: 3, text: "törölt promóció" }];
        $scope.promotionConditionsDescrTemplate = "Partnerünk fenntartja magának a jogot arra, hogy vitás kérdésekben egyoldalúan döntést hozzon.";
        $scope.promotionConditionsDescrTemplateEN = "Our partner reserves the right to unilaterally make decisions on disputed issues.";

        $scope.stampSizeText = [{ id: 1, text: "1: pecsét körön belül margóval (legkisebb)", url: "../imgs/ikonok_meretezese_1.png" },
        { id: 2, text: "2: pecsét körön belül margó nélkül", url: "../imgs/ikonok_meretezese_2.png" },
        { id: 3, text: "3: pecsét kört vágva (2. és 4. között felezve)", url: "../imgs/ikonok_meretezese_3.png" },
        { id: 4, text: "4: pecsét kört pont befoglalva", url: "../imgs/ikonok_meretezese_4.png" },
        { id: 5, text: "5: pecsét körön túllógva (legnagyobb)", url: "../imgs/ikonok_meretezese_5.png" }
        ]

        $scope.mustBeRedeemedLaterValues = [{ id: false, text: "Opcionális a későbbi beváltás" }, { id: true, text: "Kötelező később beváltani" }]

        $scope.canBeRedeemedLaterValues = [{ id: true, text: "Később is beváltható" }, { id: false, text: "Kigyűlés után beváltandó" }]
        $scope.redeemCanBePostponedValues = [{ id: false, text: "Azonnali jutalombeváltás" }, { id: true, text: "Opcionálisan a következő alkalomra halasztható beváltás" }]

        $scope.stampSizeStyle = {
            height: '40px',
            width: '40px'
        }

        $scope.reverseloyaltyCard = false;

        $scope.listOfMaxNoOfDaysBetweenStamping = [
            { value: "null", displayText: "Nincs megkötés", text: "Nincs megkötés" },
            { value: "30", displayText: "1 hónap (30 nap)", text: "1 hónap (30 nap)" },
            { value: "60", displayText: "2 hónap (60 nap)", text: "2 hónap (60 nap)" },
            { value: "90", displayText: "3 hónap (90 nap)", text: "3 hónap (90 nap)" },
            { value: "180", displayText: "Fél év (180 nap)", text: "Fél év (180 nap)" },
            { value: "365", displayText: "1 év (365 nap)", text: "1 év (365 nap) - maximális érték" }
        ]
        $scope.listOfCardValidityDuration = [
            { value: "null", displayText: "Nincs lejárat, korlátlan ideig felhasználható", text: "Nincs lejárat, korlátlan ideig felhasználható" },
            { value: "30", displayText: "1 hónap (30 nap)", text: "1 hónap (30 nap)" },
            { value: "60", displayText: "2 hónap (60 nap)", text: "2 hónap (60 nap)" },
            { value: "90", displayText: "3 hónap (90 nap)", text: "3 hónap (90 nap)" },
            { value: "180", displayText: "Fél év (180 nap)", text: "Fél év (180 nap)" },
            { value: "365", displayText: "1 év (365 nap)", text: "1 év (365 nap)" },
            { value: "730", displayText: "2 év (730 nap)", text: "2 év (730 nap)" },
            { value: "1825", displayText: "5 év (1825 nap)", text: "5 év (1825 nap) - maximális érték" }
        ]

        $scope.setCanBeRedeemedLater = function () {
            if ($scope.canBeRedeemedLater == true) {
                $scope.mustBeRedeemedLater = false;
                $scope.nrOfRedeemableRewardsAtOnce = null;
                $scope.nrOfRedeemableRewardsPerRewardTypes = null;
                $scope.redeemCanBePostponed = null;
                $scope.isNrOfRedeemableRewardsAtOnceUnlimited = true;
                $scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited = true;
            } else if ($scope.canBeRedeemedLater == false) {
                $scope.mustBeRedeemedLater = null;
                $scope.nrOfRedeemableRewardsAtOnce = null;
                $scope.nrOfRedeemableRewardsPerRewardTypes = null;
                $scope.redeemCanBePostponed = true;
                $scope.isNrOfRedeemableRewardsAtOnceUnlimited = false;
                $scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited = false;
            }
            console.log($scope.redeemCanBePostponed);
        }

        $scope.getStampIconTemplateList = function (state, param) {
            if ($scope.stampImages == null || $scope.stampIconCategories == null || state == "set") {
                if (state == "get") {
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }
                }
                if ($scope.stampImages == null || $scope.stampIconCategories == null) {
                    $http.post(API_PREFIX.url + '/loyaltyCard/getLoyaltyCardYaltyStampIconTemplateList', {}).then(function (result) {
                        if (result.data.errorCode == 0) {
                            $scope.stampIconCategories = result.data.categoryList;
                            $scope.stampImages = result.data.loyaltyCardStampIconTemplateList;
                            for (var i = 0; i < $scope.stampImages.length; i++) {
                                $scope.stampImages[i].id = i;
                                $scope.stampImages[i].checked = false;
                                if ($scope.partnerSubscriptionType == "custom") {
                                    $scope.stampImages[i].showLock = false;
                                } else if ($scope.partnerSubscriptionType == "basic" && ($scope.stampImages[i].partnerSubscriptionTypeRequired == "premium" || $scope.stampImages[i].partnerSubscriptionTypeRequired == "custom")) {
                                    $scope.stampImages[i].showLock = true;
                                } else if ($scope.partnerSubscriptionType == "premium" && $scope.stampImages[i].partnerSubscriptionTypeRequired == "custom") {
                                    $scope.stampImages[i].showLock = false;
                                } else if (($scope.partnerSubscriptionType == "yalty_start_free" || $scope.partnerSubscriptionType == "yalty_start_paid")
                                    && ($scope.stampImages[i].partnerSubscriptionTypeRequired == "basic" || $scope.stampImages[i].partnerSubscriptionTypeRequired == "premium" || $scope.stampImages[i].partnerSubscriptionTypeRequired == "custom")) {
                                    $scope.stampImages[i].showLock = true;
                                }
                                if ($scope.stampImages[i].partnerSubscriptionTypeRequired == "basic") {
                                    $scope.stampImages[i].partnerSubscriptionTypeRequired = "Design+"
                                } else if ($scope.stampImages[i].partnerSubscriptionTypeRequired == "premium") {
                                    $scope.stampImages[i].partnerSubscriptionTypeRequired = "Premium"
                                } else if ($scope.stampImages[i].partnerSubscriptionTypeRequired == "custom") {
                                    $scope.stampImages[i].partnerSubscriptionTypeRequired = "Egyedi"
                                }
                            }
                            if (state == "get") {
                                if (document.getElementById('loadingOverlay')) {
                                    document.getElementById('loadingOverlay').style.display = "none";
                                    document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                                }
                                $scope.selectedCategory = result.data.categoryList[0];
                                $scope.selectCategory = result.data.categoryList[0];
                                $scope.isSelectTemplateImg = true;
                            } else if (state == "set" && param) {
                                var index = $scope.stampImages.findIndex(x => x.stampIconURLPrefix == param);
                                if (index != -1) {
                                    $scope.selectTemplateImg = $scope.stampImages[index];
                                    $scope.selectedTemplateImg = $scope.stampImages[index];
                                    angular.forEach($scope.stampImages, function (subscription, index) {
                                        if ($scope.selectedTemplateImg.id != index) {
                                            subscription.checked = false;
                                        } else {
                                            subscription.checked = true;
                                        }
                                    });
                                    $scope.selectedCategory = result.data.categoryList[result.data.categoryList.findIndex(x => x.categoryName == $scope.stampImages[index].categoryName)];
                                    $scope.selectCategory = $scope.selectedCategory;
                                    //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
                                    //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                                    $scope.shiftColor(true, true);
                                    $scope.shiftColor(false, true);
                                }
                            }
                        }
                    });
                } else {
                    if (state == "set" && param) {
                        var index = $scope.stampImages.findIndex(x => x.stampIconURLPrefix == param);
                        if (index != -1) {
                            $scope.selectTemplateImg = $scope.stampImages[index];
                            $scope.selectedTemplateImg = $scope.stampImages[index];
                            angular.forEach($scope.stampImages, function (subscription, index) {
                                if ($scope.selectedTemplateImg.id != index) {
                                    subscription.checked = false;
                                } else {
                                    subscription.checked = true;
                                }
                            });
                            $scope.selectedCategory = $scope.stampIconCategories[$scope.stampIconCategories.findIndex(x => x.categoryName == $scope.stampImages[index].categoryName)];
                            $scope.selectCategory = $scope.selectedCategory;
                            //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
                            //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                            $scope.shiftColor(true, true);
                            $scope.shiftColor(false, true);
                        }
                    }
                }
            } else {
                $scope.isSelectTemplateImg = true;
                if ($scope.selectCategory) {
                    const elementTop = $scope.stampIconCategories.findIndex(record => record.id === $scope.selectCategory.id)
                    var id = "#category-" + elementTop;
                    setTimeout(function () {
                        $('#templateCategoryContainer .md-virtual-repeat-scroller')[0].scrollTop = $(id)[0].offsetTop;
                    }, 250)
                }
                if ($scope.selectTemplateImg) {
                    var filteredImages = $filter('filter')($scope.stampImages, { categoryName: $scope.selectCategory.categoryName });
                    const elementTop = filteredImages.findIndex(record => record.id === $scope.selectTemplateImg.id)
                    setTimeout(function () {
                        $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = elementTop * 45;
                    }, 250)
                } else {
                    $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = 0;
                }
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }

        }



        $scope.saveSelectedIconTemplate = function () {
            //$('.main').animate({ scrollTop: $('#stampIconType').offset().top - 150 }, 50);
            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
            $scope.isSelectTemplateImg = false;
            $scope.selectedTemplateImg = null;
            $scope.selectedTemplateImg = $scope.selectTemplateImg;
            $scope.selectCategory = $scope.selectedCategory;
            $scope.stampIconType = 1;
            $scope.child.stampIconType = 1
            $scope.showStampIcon = true;
            if ($scope.stampIconStampedSizing == null) {
                $scope.stampIconStampedSizing = 2;
            }
            if ($scope.stampIconUnstampedSizing == null) {
                $scope.stampIconUnstampedSizing = 2;
            }
            if ($scope.isStampedColorEnabled == true && $scope.stampIconStampedColor) {
                if (uniqueStamp) {
                    uniqueStamp.isLoaded = false;
                }
                $scope.stampIconType = 1;
                $scope.shiftColor(true, true);
            }
            if ($scope.isUnstampedColorEnabled == true && $scope.stampIconUnstampedColor) {
                if (uniqueUnstamp) {
                    uniqueUnstamp.isLoaded = false;
                }
                $scope.stampIconType = 1;
                $scope.shiftColor(false, true);
            }
            $scope.loyaltyCardForm.templateImgs.$error.need = false
            $rootScope.isDataSheetModified = true
        }

        $scope.cancelStampIconTemplate = function () {
            //$('.main').animate({ scrollTop: $('#stampIconType').offset().top - 150 }, 50);
            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
            $scope.isSelectTemplateImg = false;
            $scope.selectedCategory = $scope.selectCategory;
            if ($scope.selectTemplateImg) {
                angular.forEach($scope.stampImages, function (subscription, index) {
                    subscription.checked = false;
                });
                $scope.selectTemplateImg = null;
            }
            if ($scope.selectedTemplateImg) {
                $scope.selectTemplateImg = $scope.selectedTemplateImg;
                if ($scope.isStampedColorEnabled == true && $scope.stampIconStampedColor) {
                    if (uniqueStamp) {
                        uniqueStamp.isLoaded = false;
                    }
                    $scope.shiftColor(true, true);
                }
                if ($scope.isUnstampedColorEnabled == true && $scope.stampIconUnstampedColor) {
                    if (uniqueUnstamp) {
                        uniqueUnstamp.isLoaded = false;
                    }
                    $scope.shiftColor(false, true);
                }
                angular.forEach($scope.stampImages, function (subscription, index) {
                    if ($scope.selectTemplateImg.id != index) {
                        subscription.checked = false;
                    } else {
                        subscription.checked = true;
                    }
                });
                if (!$.isEmptyObject($rootScope.stampCropperData) && $.isEmptyObject($rootScope.unstampCropperData)) {
                    if ($rootScope.stampCropperData.stampImg != $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png"
                        && $rootScope.unstampCropperData.unstampImg != $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png") {
                        $scope.selectedTemplateImg = null;
                        for (var i = 0; i < $scope.stampImages.length; i++) {
                            $scope.stampImages[i].checked = false;
                        }
                    }
                }
            }
        }

        $scope.selectingCategory = function (category) {
            $scope.selectedCategory = category;
            if ($scope.selectTemplateImg) {
                if ($scope.selectTemplateImg.categoryName == category.categoryName) {
                    var filteredImages = $filter('filter')($scope.stampImages, { categoryName: $scope.selectedCategory.categoryName });
                    var elementTop = filteredImages.findIndex(record => record.id === $scope.selectTemplateImg.id)
                    setTimeout(function () {
                        $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = elementTop * 45;
                    }, 250)
                } else {
                    setTimeout(function () {
                        $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = 0;
                    }, 250)
                }
            } else {
                $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = 0;
            }
        }

        $scope.selectTemplateImage = function (position, entities) {
            if ($scope.selectTemplateImg == position) {
                $scope.selectTemplateImg = null;
                angular.forEach(entities, function (subscription, index) {
                    if (position.id == index) {
                        subscription.checked = false;
                    }
                });
            } else {
                $scope.selectTemplateImg = position;
                if ($scope.isStampedColorEnabled == true && $scope.stampIconStampedColor) {
                    if (uniqueStamp) {
                        uniqueStamp.isLoaded = false;
                    }
                    $scope.shiftColor(true, true, false);
                }
                if ($scope.isUnstampedColorEnabled == true && $scope.stampIconUnstampedColor) {
                    if (uniqueUnstamp) {
                        uniqueUnstamp.isLoaded = false;
                    }
                    $scope.shiftColor(false, true, false);
                }
                angular.forEach(entities, function (subscription, index) {
                    if (position.id != index) {
                        subscription.checked = false;
                    } else {
                        subscription.checked = true;
                    }
                });
            }
        }

        $scope.openTranslationWindow = (languageId, whereToOpen) => {
            if (languageId != 1) {
                const langIndex = $scope.languages.findIndex(item => item.id == languageId)
                $scope.windowTitleLang = $scope.languages[langIndex].windowTitleName
                $scope.langName = $scope.languages[langIndex].name
                $scope.lang = $scope.languages[langIndex].code
                $scope.translatedCompNamePromo = $scope[$scope.lang].compNamePromo
                $scope.translatedPromotionName = $scope[$scope.lang].promotionName
                $scope.translatedStampConditionsDescr = $scope[$scope.lang].stampConditionsDescr
                $scope.translatedReward1RewardDescr = $scope[$scope.lang].reward1RewardDescr
                $scope.translatedReward2RewardDescr = $scope[$scope.lang].reward2RewardDescr
                $scope.translatedReward3RewardDescr = $scope[$scope.lang].reward3RewardDescr
                $scope.translatedReward4RewardDescr = $scope[$scope.lang].reward4RewardDescr
                $scope.translatedReward5RewardDescr = $scope[$scope.lang].reward5RewardDescr
                $scope.translatedPromotionConditionsDescr = $scope[$scope.lang].promotionConditionsDescr
                $scope.translatedPhoneOrderInfoMessage = $scope[$scope.lang].phoneOrderInfoMessage
                $scope.translatedOnlineOrderInfoMessage = $scope[$scope.lang].onlineOrderInfoMessage
                //const element = document.getElementById("translatedCompNamePromo");
                //element.scrollIntoView();
                //document.getElementById('translatedCompNamePromo').focus()
                $scope.showTranslationWindow = true
                const langCode = $scope.languages[langIndex].code
                $scope.initValues = {}
                $scope.initValues[langCode] = {}
                $scope.initValues[langCode].enabled = $scope.translationEnabled
                if (!$scope.translationEnabled) {
                    $scope.translationEnabled = true
                    $scope.languageSelector = 2
                    $scope.modifyTranslationEnabled(true)
                }
                for (let i = 1; i < 6; i++) {
                    $scope.setTranslatedRewardDescr(i, true)
                    $scope.handlingErrorInTranslatedDescrField(i)
                }
                const divToScroll = whereToOpen ? whereToOpen : 'translationTable'
                $(`#${divToScroll}`).animate({ scrollTop: 0 }, "fast");


            }
        }

        $scope.modifyTranslationEnabled = (windowOpening) => {
            if ($scope.languageSelector == 2) {
                $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
                $scope.languages[1].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
            }
            const langName = $scope.langName.toLowerCase()
            if ($scope.translationEnabled) {
                let alert = $mdDialog.confirm()
                    .title(`A(z) ${langName} fordítás bekapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegeket, üzletek információit és a promóciók szövegeit is szükséges ${langName} nyelvre fordítani. A fordításban érintett oldalak:`)
                    .htmlContent(`
                    <ul class="md-title mdDialogList">
                        <li>Partneri adatok,</li>
                        <li>Üzletek (azon belül az egyes üzletek),</li>
                        <li>Hűségkártyáink (azon belül az egyes, még aktív hűségkártyák) és </li>
                    </ul>`)
                    .ok('Rendben')
                if (windowOpening) alert.cancel('Mégsem')
                $mdDialog.show(alert).catch(() => {
                    for (let i = 1; i < 6; i++) {
                        $scope.handlingErrorInTranslatedDescrField(i)
                    }
                    $scope.loyaltyCardForm.languageSelector.$valid = true
                    $scope.loyaltyCardForm.languageSelector.$invalid = false
                    $scope.loyaltyCardForm.languageSelector.$error = {}
                    $scope.translationEnabled = false
                    $scope.closeTranslationWindow()
                })
            } else {
                const alert = $mdDialog.alert()
                    .title(`A(z) ${langName} fordítás kikapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegek, üzletek információ és a promóciók szövege esetén kikapcsoljuk a(z) ${langName} nyelvű fordítást. Tehát minden, a fordításban érintett oldalon:`)
                    .htmlContent(`
                        <ul class="md-title mdDialogList">
                            <li>Partneri adatok,</li>
                            <li>Üzletek (azon belül az egyes üzletek),</li>
                            <li>Hűségkártyáink (azon belül az egyes hűségkártyák) és</li>
                        </ul>
                    `)
                    .ok('Rendben')
                $mdDialog.show(alert).then(() => {
                    for (let i = 1; i < 6; i++) {
                        $scope.handlingErrorInTranslatedDescrField(i)
                    }
                    $scope.loyaltyCardForm.languageSelector.$valid = true
                    $scope.loyaltyCardForm.languageSelector.$invalid = false
                    $scope.loyaltyCardForm.languageSelector.$error = {}
                })
            }

        }

        $scope.closeTranslationWindow = () => {
            $scope.translatedCompNamePromo = $scope[$scope.lang].compNamePromo
            $scope.translatedPromotionName = $scope[$scope.lang].promotionName
            $scope.translatedStampConditionsDescr = $scope[$scope.lang].stampConditionsDescr
            for (let i = 1; i < 6; i++) {
                $scope.setTranslatedRewardDescr(i, false, true)
            }
            $scope.translatedPromotionConditionsDescr = $scope[$scope.lang].promotionConditionsDescr
            $scope.translatedPhoneOrderInfoMessage = $scope[$scope.lang].phoneOrderInfoMessage
            $scope.translatedOnlineOrderInfoMessage = $scope[$scope.lang].onlineOrderInfoMessage
            $scope.languageSelector = 1
            $scope.showTranslationWindow = false
            $scope.translationEnabled = $scope.initValues['EN'].enabled
            $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
            $scope.languages[$scope.languageSelector].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
        }

        $scope.saveTranslation = () => {
            let isTranslationModified = false;
            if ($scope[$scope.lang].compNamePromo != $scope.translatedCompNamePromo) {
                $scope[$scope.lang].compNamePromo = $scope.translatedCompNamePromo
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].promotionName != $scope.translatedPromotionName) {
                $scope[$scope.lang].promotionName = $scope.translatedPromotionName
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].stampConditionsDescr != $scope.translatedStampConditionsDescr) {
                $scope[$scope.lang].stampConditionsDescr = $scope.translatedStampConditionsDescr
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].reward1RewardDescr != $scope.getTranslatedRewardDescrWithoutPrefix(1)) {
                $scope[$scope.lang].reward1RewardDescr = $scope.getTranslatedRewardDescrWithoutPrefix(1)
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].reward2RewardDescr != $scope.getTranslatedRewardDescrWithoutPrefix(2)) {
                $scope[$scope.lang].reward2RewardDescr = $scope.getTranslatedRewardDescrWithoutPrefix(2)
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].reward3RewardDescr != $scope.getTranslatedRewardDescrWithoutPrefix(3)) {
                $scope[$scope.lang].reward3RewardDescr = $scope.getTranslatedRewardDescrWithoutPrefix(3)
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].reward4RewardDescr != $scope.getTranslatedRewardDescrWithoutPrefix(4)) {
                $scope[$scope.lang].reward4RewardDescr = $scope.getTranslatedRewardDescrWithoutPrefix(4)
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].reward5RewardDescr != $scope.getTranslatedRewardDescrWithoutPrefix(5)) {
                $scope[$scope.lang].reward5RewardDescr = $scope.getTranslatedRewardDescrWithoutPrefix(5)
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].promotionConditionsDescr != $scope.translatedPromotionConditionsDescr) {
                $scope[$scope.lang].promotionConditionsDescr = $scope.translatedPromotionConditionsDescr
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].phoneOrderInfoMessage != $scope.translatedPhoneOrderInfoMessage) {
                $scope[$scope.lang].phoneOrderInfoMessage = $scope.translatedPhoneOrderInfoMessage
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].onlineOrderInfoMessage != $scope.translatedOnlineOrderInfoMessage) {
                $scope[$scope.lang].onlineOrderInfoMessage = $scope.translatedOnlineOrderInfoMessage
                isTranslationModified = true;
            }
            $scope.languageSelector = 1
            $scope.sendButtonDisabled = false
            $rootScope.isDataSheetModified = true
            if ($scope.loyaltyCardForm.translatedCompNamePromo.$valid && $scope.loyaltyCardForm.translatedPromotionName.$valid &&
                $scope.loyaltyCardForm.translatedStampConditionsDescr.$valid && $scope.loyaltyCardForm.translatedReward1RewardDescr.$valid &&
                $scope.loyaltyCardForm.translatedReward2RewardDescr.$valid && $scope.loyaltyCardForm.translatedReward3RewardDescr.$valid &&
                $scope.loyaltyCardForm.translatedReward4RewardDescr.$valid && $scope.loyaltyCardForm.translatedReward5RewardDescr.$valid &&
                $scope.loyaltyCardForm.translatedPromotionConditionsDescr.$valid && (!$scope.isPhoneOrderAvailable || $scope.loyaltyCardForm.translatedPhoneOrderInfoMessage.$valid) &&
                (!$scope.isOnlineOrderAvailable || $scope.loyaltyCardForm.translatedOnlineOrderInfoMessage.$valid)) {
                $scope.loyaltyCardForm.languageSelector.$valid = true
                $scope.loyaltyCardForm.languageSelector.$invalid = false
                $scope.loyaltyCardForm.languageSelector.$error = {}
            }
            $scope.showTranslationWindow = false
            if (isTranslationModified == true) {
                var confirm = $mdDialog.confirm()
                    .textContent('Figyelem: a fordítások módosításainak mentéséhez használja a Hűségkártya oldal Mentés gombját.')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                }, function () {
                });
            }
        }

        $scope.copyToTranslationField = (fieldName, copyFromPartnerData) => {
            const translationFieldName = 'translated' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
            if (copyFromPartnerData) {
                if ($scope.partnerData[translationFieldName]) {
                    $scope[translationFieldName] = $scope.partnerData[translationFieldName]
                }
            } else if ($scope[fieldName]) {
                $scope[translationFieldName] = $scope[fieldName]
            }
        }

        $scope.$watch(function () {
            return $rootScope.isDataSheetModified
        }, function (current) {
            if (current == false) {
                $scope.sendButtonDisabled = true;
            } else {
                $scope.sendButtonDisabled = false;
            }
        });

        $scope.$watch(function () {
            return $rootScope.logoCropperData.compLogoImg;
        }, function (current, prevent) {
            if (current) { //Fehér képeknél szürke háttér beállítása
                const img = document.createElement("img");
                img.src = current.includes('/imgs/') ? $location.protocol() + "://" + location.host + $rootScope.logoCropperData.compLogoImg : $rootScope.logoCropperData.compLogoImg;
                img.crossOrigin = 'anonymous';
                img.style.display = "none";
                document.body.appendChild(img);
                let colorSum = 0;
                let pixel = 0;

                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);

                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;
                    let r, g, b, avg;

                    for (let x = 0, len = data.length; x < len; x += 4) {
                        if (data[x + 3] != 0) {
                            r = data[x];
                            g = data[x + 1];
                            b = data[x + 2];
                            avg = Math.floor((r + g + b) / 3);
                            colorSum += avg;
                            pixel++;
                        }
                    }

                    const brightness = Math.floor(colorSum / pixel);
                    if (brightness >= 230) {
                        $rootScope.logoCropperData.isTooBrightness = true;
                    } else {
                        $rootScope.logoCropperData.isTooBrightness = false;
                    }
                }
            }
            if (prevent && current) {
                if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                    $scope.isCardLogoImgURLSameAsCo = false;
                    $rootScope.isDataSheetModified = true;
                }
            } else if (!prevent && current && !current.includes('/imgs/') && !$scope.isCardLogoImgURLSameAsCo) {
                $rootScope.isDataSheetModified = true;
            }
        });

        $scope.isMaxStampNrLimitationAtStampingUnlimitedModified = function () {
            if ($scope.isMaxStampNrLimitationAtStampingUnlimited == true) {
                $scope.maxStampNrLimitationAtStamping = null;
            }
            $scope.checkChanges();
        }

        $scope.isNrOfRedeemableRewardsAtOnceUnlimitedModified = function () {
            if ($scope.isNrOfRedeemableRewardsAtOnceUnlimited == true) {
                $scope.nrOfRedeemableRewardsAtOnce = null;
            }
            $scope.checkChanges();
        }

        $scope.isNrOfRedeemableRewardsPerRewardTypesUnlimitedModified = function () {
            if ($scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited == true) {
                $scope.nrOfRedeemableRewardsPerRewardTypes = null;
            }
            $scope.checkChanges();
        }

        $scope.$watch(function () {
            return $rootScope.unstampCropperData.unstampImg;
        }, function (current, prevent) {
            if (prevent && current) {
                if (uniqueUnstamp) {
                    uniqueUnstamp.isLoaded = false;
                    if ($scope.isUnstampedColorEnabled == true) {
                        $scope.stampIconType = 2;
                        $scope.shiftColor(false, true);
                    }
                }
            } else if (current) {
                if (uniqueUnstamp) {
                    uniqueUnstamp.isLoaded = false;
                    if ($scope.isUnstampedColorEnabled == true) {
                        $scope.stampIconType = 2;
                        $scope.shiftColor(false, true);
                    }
                }
            }
        });

        $scope.$watch(function () {
            return $rootScope.stampCropperData.stampImg;
        }, function (current, prevent) {
            if (prevent && current) {
                if (uniqueStamp) {
                    uniqueStamp.isLoaded = false;
                    if ($scope.isStampedColorEnabled == true) {
                        $scope.stampIconType = 2;
                        $scope.shiftColor(true, true);
                    }
                }
            } else if (current) {
                if (uniqueStamp) {
                    uniqueStamp.isLoaded = false;
                    if ($scope.isStampedColorEnabled == true) {
                        $scope.stampIconType = 2;
                        $scope.shiftColor(true, true);
                    }
                }
            }
        });

        $scope.resetPage = function () {
            if ($rootScope.isDataSheetModified == false) {
                $window.scrollTo(0, 0);
                $state.reload();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    $state.reload();
                }, function () {
                });
            }
        }

        var resetVariables = function () {
            $rootScope.isDataSheetModified = false;
            $scope.loyaltyCardData = {};
            if ($rootScope.stampCropperData.cropper) {
                $rootScope.stampCropperData.cropper.destroy();
            }
            if ($rootScope.logoCropperData.cropper) {
                $rootScope.logoCropperData.cropper.destroy();
            }
            if ($rootScope.unstampCropperData.cropper) {
                $rootScope.unstampCropperData.cropper.destroy();
            }
            delete $rootScope.stampCropperData;
            delete $rootScope.logoCropperData;
            delete $rootScope.unstampCropperData;
            $rootScope.stampCropperData = {};
            $rootScope.logoCropperData = {};
            $rootScope.unstampCropperData = {};
            $rootScope.isSelectedFile = false
            $scope.loyaltyCardForm.stampImg.$error = {}
            $scope.loyaltyCardForm.unstampImg.$error = {}
            $scope.loyaltyCardForm.templateImgs.$error = {}
            $scope.detailedDisplayBackgroundColor = "#000000";
            $scope.backButtonColor = "#FFFFFF";
            $scope.cardBackgroundColor = "#FFFFFF";
            $scope.isCompNamePromoSameAsCo = undefined;
            $scope.compNamePromoTextColor = "#4E525C";
            $scope.promotionNameTextColor = "#000000";
            $scope.stampTextPlaceholderColor = "#4E525C";
            $scope.stampCurrentCountTextColor = "#000000";
            $scope.loyaltyCardId = undefined;
            $scope.isBeforeActivation = undefined;
            $scope.promoCategoryId = undefined;
            $scope.showBaseStampDesign = true;
            $scope.baseStampUnstampedFillColor = "#FFFFFF";
            $scope.baseStampUnstampedLineWeight = 1;
            $scope.baseStampUnstampedLineColor = "#e5e8eb";
            $scope.baseStampStampedFillColor = "#00AEEF";
            $scope.baseStampStampedLineWeight = 1;
            $scope.baseStampStampedLineColor = "#e5e8eb";
            $scope.stampIconType = 0;
            $scope.stampIconCategories = null;
            $scope.stampImages = null;
            if ($scope.stampIconCategories) {
                $scope.selectedCategory = $scope.stampIconCategories[0];
                $scope.selectCategory = $scope.stampIconCategories[0];
                $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = 0;
            } else {
                $scope.selectCategory = null;
                $scope.selectedCategory = null;
            }
            $scope.selectTemplateImg = null;
            $scope.selectedTemplateImg = null;
            $scope.isUnstampedColorEnabled = false;
            $scope.isStampedColorEnabled = false;
            $scope.stampedStyle = {
                "background-color": $scope.baseStampStampedFillColor,
                "border": "solid " + $scope.baseStampStampedLineWeight + "px",
                "border-color": $scope.baseStampStampedLineColor,
                "padding": (5 - $scope.baseStampStampedLineWeight) + "px",
                "position": "relative"
            };
            $scope.sizedPreviewStampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            };
            $scope.previewStampedStyle = $scope.stampedStyle;
            $scope.unstampedStyle = {
                "background-color": $scope.baseStampUnstampedFillColor,
                "border": "solid " + $scope.baseStampUnstampedLineWeight + "px",
                "border-color": $scope.baseStampUnstampedLineColor,
                "padding": (5 - $scope.baseStampUnstampedLineWeight) + "px",
                "position": "relative"
            };
            $scope.sizedPreviewUnstampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            }
            $scope.stampIconUnstampedSizing = null;
            $scope.stampIconStampedSizing = null;
            $scope.sizedUnstampStyle = {
                "width": "20px",
                "height": "20px",
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%,-50%)"
            };
            $scope.sizedStampStyle = {
                "width": "20px",
                "height": "20px",
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%,-50%)"
            };
            $scope.previweUnstampedStyle = $scope.unstampedStyle;
            $scope.showStampIcon = false;
            $scope.loyaltyCardPromoStatusCode = null;
            $scope.noOfRewards = 1;
            $scope.isCompStoresSame = false;
            $scope.isCardLogoImgURLSameAsCo = true;
            $scope.isCardLogoImgSameAsCo = undefined;
            $scope.partnerSubscriptionType = undefined;
            $scope.promotionName = undefined
            $scope.stampConditionsDescr = undefined;
            $scope.reward1NrOfStampsNeeded = $scope.MIN_PECSETELESEK_SZAMA;
            $scope.reward1RewardDescr = undefined;
            $scope.setRewardDescr(1, true)
            $scope.reward2NrOfStampsNeeded = undefined;
            $scope.reward2RewardDescr = undefined;
            $scope.reward3NrOfStampsNeeded = undefined;
            $scope.reward3RewardDescr = undefined;
            $scope.reward4NrOfStampsNeeded = undefined;
            $scope.reward4RewardDescr = undefined;
            $scope.reward5NrOfStampsNeeded = undefined;
            $scope.reward5RewardDescr = undefined;
            $scope.sumNrOfStampsNeeded = $scope.MIN_PECSETELESEK_SZAMA;
            $scope.floorNrOfStampsNeeded = Math.ceil($scope.sumNrOfStampsNeeded / 2);
            $scope.stampIconUnstampedColor = null;
            stampIconUnstampedColor = null;
            $("#stampIconUnstampedColorInUniqueStamp")[0].value = "";
            $('#stampIconUnstampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = "";
            $scope.stampIconStampedColor = null;
            stampIconStampedColor = null;
            $("#stampIconStampedColorInUniqueStamp")[0].value = "";
            $('#stampIconStampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = "";
            $scope.maxNoOfDaysBetweenStampingText = $scope.listOfMaxNoOfDaysBetweenStamping[0].displayText;
            if ($rootScope.partnerSubscriptionType == "yalty_start_free" || $rootScope.partnerSubscriptionType == "yalty_start_paid" || $rootScope.partnerSubscriptionType == "basic") {
                $scope.cardValidityDurationText = $scope.listOfCardValidityDuration[0].displayText;
            } else {
                $scope.cardValidityDurationText = $scope.listOfCardValidityDuration[6].displayText;
            }
            $scope.promotionConditionsDescr = undefined;
            $scope.isPhoneOrderAvailable = false;
            $scope.phoneOrderInfoMessage = undefined;
            $scope.isOnlineOrderAvailable = false;
            $scope.onlineOrderInfoMessage = undefined;
            $scope.isYLCPartnerRedeemRemarkEnabled = false;
            $scope.companyStores = [];
            $scope.isAllRedeemLocationIndicator = false;
            $scope.isActivateLoyaltyCard = false;
            $scope.sendButtonDisabled = false;
            $scope.allRedeemLocationIndicator = "select";
            company = [];
            $scope.canBeRedeemedLater = true;
            $scope.redeemCanBePostponed = true;
            $scope.mustBeRedeemedLater = false;
            $scope.nrOfRedeemableRewardsAtOnce = null;
            $scope.nrOfRedeemableRewardsPerRewardTypes = null;
            $scope.isNrOfRedeemableRewardsAtOnceUnlimited = true;
            $scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited = true;
            $scope.maxStampNrLimitationAtStamping = null;
            $scope.isMaxStampNrLimitationAtStampingUnlimited = true;
            $scope.linkedPreviousLoyaltyCardYaltyId = null;
            $scope.isCardHiddenFromPublic = false;
            $scope.publiclyHiddenCardRevealCode = null;

            $scope.lists = [
                {
                    items: [
                        { name: "" }
                    ]
                }
            ];

            $scope.originalLists = [
                {
                    items: [
                        { name: "" }
                    ]
                }
            ];

            $scope.isYLCSelectableProductsEnabled = null;
            $scope.YLCSelectableProducts = null;

            uniqueStamp.stampCanvas = document.getElementById("templateStampCanvas");
            uniqueStamp.ctx = uniqueStamp.stampCanvas.getContext("2d");
            uniqueStamp.ctx.clearRect(0, 0, uniqueStamp.stampCanvas.width, uniqueStamp.stampCanvas.height);
            uniqueStamp.cw = uniqueStamp.stampCanvas.width;
            uniqueStamp.ch = uniqueStamp.stampCanvas.height;
            uniqueStamp.imgData;
            uniqueStamp.data;
            uniqueStamp.originalData;
            uniqueStamp.img = new Image();
            uniqueStamp.isLoaded = false;

            uniqueUnstamp.stampCanvas = document.getElementById("templateUnstampCanvas");
            uniqueUnstamp.ctx = uniqueUnstamp.stampCanvas.getContext("2d");
            uniqueUnstamp.ctx.clearRect(0, 0, uniqueUnstamp.stampCanvas.width, uniqueUnstamp.stampCanvas.height);
            uniqueUnstamp.cw = uniqueUnstamp.stampCanvas.width;
            uniqueUnstamp.ch = uniqueUnstamp.stampCanvas.height;
            uniqueUnstamp.imgData;
            uniqueUnstamp.data;
            uniqueUnstamp.originalData;
            uniqueUnstamp.img = new Image();
            uniqueUnstamp.img.src = "";
            uniqueUnstamp.isLoaded = false;

            //$scope.coloringIcons($scope.backButtonColor);
        };
        // $scope.setIconCanvases = function (hexaColor) {
        //     backCanvas.canvas = document.getElementById("backIconCanvas");
        //     backCanvas.ctx = backCanvas.canvas.getContext("2d");
        //     backCanvas.ctx.clearRect(0, 0, backCanvas.canvas.width, backCanvas.canvas.height);
        //     backCanvas.cw = backCanvas.canvas.width;
        //     backCanvas.ch = backCanvas.canvas.height;
        //     backCanvas.imgData;
        //     backCanvas.data;
        //     backCanvas.originalData;
        //     backCanvas.img = new Image();
        //     backCanvas.img.src = "../imgs/back_line_icon.svg";
        //     backCanvas.isLoaded = false;

        //     shareIconCanvas.canvas = document.getElementById("shareIconCanvas");
        //     shareIconCanvas.ctx = shareIconCanvas.canvas.getContext("2d");
        //     shareIconCanvas.ctx.clearRect(0, 0, shareIconCanvas.canvas.width, shareIconCanvas.canvas.height);
        //     shareIconCanvas.cw = shareIconCanvas.canvas.width;
        //     shareIconCanvas.ch = shareIconCanvas.canvas.height;
        //     shareIconCanvas.imgData;
        //     shareIconCanvas.data;
        //     shareIconCanvas.originalData;
        //     shareIconCanvas.img = new Image();
        //     shareIconCanvas.img.src = "../imgs/share_line_icon.svg";
        //     shareIconCanvas.isLoaded = false;

        //     addIconCanvas.canvas = document.getElementById("addIconCanvas");
        //     addIconCanvas.ctx = addIconCanvas.canvas.getContext("2d");
        //     addIconCanvas.ctx.clearRect(0, 0, addIconCanvas.canvas.width, addIconCanvas.canvas.height);
        //     addIconCanvas.cw = addIconCanvas.canvas.width;
        //     addIconCanvas.ch = addIconCanvas.canvas.height;
        //     addIconCanvas.imgData;
        //     addIconCanvas.data;
        //     addIconCanvas.originalData;
        //     addIconCanvas.img = new Image();
        //     addIconCanvas.img.src = "../imgs/add_icon.png";
        //     addIconCanvas.isLoaded = false;


        //     $scope.coloringIcons(hexaColor);
        // }
        /*$rootScope.$on('coloringCanvas', function (event, args) {
            $scope.setIconCanvases("#FFFFFF");
        });*/
        $scope.checkChanges = function (hexaColor) {
            if ($scope.noOfRewards > 1) {
                $scope.MIN_PECSETELESEK_SZAMA = 1;
            } else {
                $scope.MIN_PECSETELESEK_SZAMA = 2;
            }
            //if (hexaColor) $scope.coloringIcons(hexaColor)
            if ($scope.compNamePromo) {
                $scope.compNamePromo = $scope.compNamePromo.trim();
            }
            if ($scope.promotionName) {
                $scope.promotionName = $scope.promotionName.trim();
            } /*else {
                $scope.promotionName = "Új hűségkártya";
            }*/
            if ($scope.stampConditionsDescr) {
                $scope.stampConditionsDescr = $scope.stampConditionsDescr.trim();
            }
            if ($scope.phoneOrderInfoMessage) {
                $scope.phoneOrderInfoMessage = $scope.phoneOrderInfoMessage.trim();
            }
            if ($scope.onlineOrderInfoMessage) {
                $scope.onlineOrderInfoMessage = $scope.onlineOrderInfoMessage.trim();
            }
            // if ($scope.reward1RewardDescr) {
            //     $scope.reward1RewardDescr = $scope.reward1RewardDescr.trim();
            // }
            // if ($scope.reward2RewardDescr) {
            //     $scope.reward2RewardDescr = $scope.reward2RewardDescr.trim();
            // }
            // if ($scope.reward3RewardDescr) {
            //     $scope.reward3RewardDescr = $scope.reward3RewardDescr.trim();
            // }
            // if ($scope.reward4RewardDescr) {
            //     $scope.reward4RewardDescr = $scope.reward4RewardDescr.trim();
            // }
            // if ($scope.reward5RewardDescr) {
            //     $scope.reward5RewardDescr = $scope.reward5RewardDescr.trim();
            // }
            if ($scope.promotionConditionsDescr) {
                $scope.promotionConditionsDescr = $scope.promotionConditionsDescr.trim();
            }
            if ($rootScope.isDataSheetModified == false) {
                if ($scope.loyaltyCardId) {
                    if ($scope.isCardHiddenFromPublic != $scope.loyaltyCardData.loyaltyCardDetalViewData.isCardHiddenFromPublic) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.publiclyHiddenCardRevealCode != $scope.loyaltyCardData.loyaltyCardDetalViewData.publiclyHiddenCardRevealCode) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.linkedPreviousLoyaltyCardYaltyId != $scope.loyaltyCardData.loyaltyCardDetalViewData.linkedPreviousLoyaltyCardYaltyId) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.canBeRedeemedLater != $scope.loyaltyCardData.loyaltyCardDetalViewData.canBeRedeemedLater) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemCanBePostponed != $scope.loyaltyCardData.loyaltyCardDetalViewData.redeemCanBePostponed) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.mustBeRedeemedLater != $scope.loyaltyCardData.loyaltyCardDetalViewData.mustBeRedeemedLater) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.nrOfRedeemableRewardsAtOnce != $scope.loyaltyCardData.loyaltyCardDetalViewData.nrOfRedeemableRewardsAtOnce) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.nrOfRedeemableRewardsPerRewardTypes != $scope.loyaltyCardData.loyaltyCardDetalViewData.nrOfRedeemableRewardsPerRewardTypes) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.maxStampNrLimitationAtStamping != $scope.loyaltyCardData.loyaltyCardDetalViewData.maxStampNrLimitationAtStamping) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.detailedDisplayBackgroundColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.detailedDisplayBackgroundColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.backButtonColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.backButtonColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.cardBackgroundColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.cardBackgroundColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromo != $scope.loyaltyCardData.loyaltyCardDetalViewData.compNamePromo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isCompNamePromoSameAsCo != $scope.loyaltyCardData.loyaltyCardDetalViewData.isCompNamePromoSameAsCo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromoTextColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.compNamePromoTextColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionName != $scope.loyaltyCardData.loyaltyCardDetalViewData.promotionName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionNameTextColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.promotionNameTextColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampTextPlaceholderColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.stampTextPlaceholderColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampCurrentCountTextColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.stampCurrentCountTextColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampUnstampedFillColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.baseStampUnstampedFillColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampUnstampedLineWeight != $scope.loyaltyCardData.loyaltyCardDetalViewData.baseStampUnstampedLineWeight) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampUnstampedLineColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.baseStampUnstampedLineColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampStampedFillColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.baseStampStampedFillColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampStampedLineWeight != $scope.loyaltyCardData.loyaltyCardDetalViewData.baseStampStampedLineWeight) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampStampedLineColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.baseStampStampedLineColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.showStampIcon != $scope.loyaltyCardData.loyaltyCardDetalViewData.showStampIcon) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.showBaseStampDesign != $scope.loyaltyCardData.loyaltyCardDetalViewData.showBaseStampDesign) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampIconType != $scope.loyaltyCardData.loyaltyCardDetalViewData.stampIconType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampIconUnstampedColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.stampIconUnstampedColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampIconStampedColor != $scope.loyaltyCardData.loyaltyCardDetalViewData.stampIconStampedColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampConditionsDescr != $scope.loyaltyCardData.loyaltyCardDetalViewData.stampConditionsDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isPhoneOrderAvailable != $scope.loyaltyCardData.loyaltyCardDetalViewData.isPhoneOrderAvailable) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.phoneOrderInfoMessage != $scope.loyaltyCardData.loyaltyCardDetalViewData.phoneOrderInfoMessage) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isOnlineOrderAvailable != $scope.loyaltyCardData.loyaltyCardDetalViewData.isOnlineOrderAvailable) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.onlineOrderInfoMessage != $scope.loyaltyCardData.loyaltyCardDetalViewData.onlineOrderInfoMessage) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isYLCPartnerRedeemRemarkEnabled != $scope.loyaltyCardData.loyaltyCardDetalViewData.isYLCPartnerRedeemRemarkEnabled) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards != $scope.loyaltyCardData.loyaltyCardDetalViewData.noOfRewards) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.reward1NrOfStampsNeeded != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward1NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.reward1RewardDescr && $scope.getRewardDescrWithoutPrefix(1) != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward1RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 2 && $scope.reward2NrOfStampsNeeded != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward2NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 2 && $scope.getRewardDescrWithoutPrefix(2) != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward2RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 3 && $scope.reward3NrOfStampsNeeded != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward3NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 3 && $scope.getRewardDescrWithoutPrefix(3) != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward3RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 4 && $scope.reward4NrOfStampsNeeded != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward4NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 4 && $scope.getRewardDescrWithoutPrefix(4) != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward4RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards == 5 && $scope.reward5NrOfStampsNeeded != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward5NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards == 5 && $scope.getRewardDescrWithoutPrefix(5) != $scope.loyaltyCardData.loyaltyCardDetalViewData.reward5RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.maxNoOfDaysBetweenStamping != null || $scope.maxNoOfDaysBetweenStampingText) {
                        if ($scope.maxNoOfDaysBetweenStampingText != $scope.loyaltyCardData.loyaltyCardDetalViewData.maxNoOfDaysBetweenStamping) {
                            $rootScope.isDataSheetModified = true;
                        } else if ($scope.maxNoOfDaysBetweenStamping != null) {
                            if ($scope.maxNoOfDaysBetweenStamping.value != $scope.loyaltyCardData.loyaltyCardDetalViewData.maxNoOfDaysBetweenStamping) {
                                $rootScope.isDataSheetModified = true;
                            }
                        }
                    }
                    if ($scope.cardValidityDuration != null || $scope.cardValidityDurationText) {
                        if ($scope.cardValidityDurationText != $scope.loyaltyCardData.loyaltyCardDetalViewData.cardValidityDuration) {
                            $rootScope.isDataSheetModified = true;
                        } else if ($scope.cardValidityDuration != null) {
                            if ($scope.cardValidityDuration.value != $scope.loyaltyCardData.loyaltyCardDetalViewData.cardValidityDuration) {
                                $rootScope.isDataSheetModified = true;
                            }
                        }
                    }
                    if ($scope.promotionConditionsDescr != $scope.loyaltyCardData.loyaltyCardDetalViewData.promotionConditionsDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promoCategoryId != $scope.mainPromoCategory) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyStores) {
                        if ($scope.companyStores.length != $scope.loyaltyCardData.loyaltyCardRedeemLocations.length) {
                            $rootScope.isDataSheetModified = true;
                        }
                    } else {
                        $rootScope.isDataSheetModified = true;
                    }
                } else {
                    if ($scope.detailedDisplayBackgroundColor != "#FFFFFF") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.backButtonColor != "#000000") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.cardBackgroundColor != "#FFFFFF") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromo != $scope.loyaltyCardData.companyData[0].compNamePromo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isCompNamePromoSameAsCo != true) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromoTextColor != "#4E525C") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionNameTextColor != "#000000") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampTextPlaceholderColor != "#4E525C") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampCurrentCountTextColor != "#000000") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampUnstampedFillColor != "#FFFFFF") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampUnstampedLineWeight != 1) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampUnstampedLineColor != "#e5e8eb") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampStampedFillColor != "#00AEEF") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampStampedLineWeight != 1) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.baseStampStampedLineColor != "#e5e8eb") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.showStampIcon != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.showBaseStampDesign != true) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampIconType != 0) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampIconUnstampedColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampIconStampedColor) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.stampConditionsDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isPhoneOrderAvailable != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.phoneOrderInfoMessage) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isOnlineOrderAvailable != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.onlineOrderInfoMessage) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isYLCPartnerRedeemRemarkEnabled != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards != 1) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.reward1NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.reward1RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 2 && $scope.reward2NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 2 && $scope.reward2RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 3 && $scope.reward3NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 3 && $scope.reward3RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 4 && $scope.reward4NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards >= 4 && $scope.reward4RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards == 5 && $scope.reward5NrOfStampsNeeded) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.noOfRewards == 5 && $scope.reward5RewardDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.maxNoOfDaysBetweenStamping != $scope.listOfMaxNoOfDaysBetweenStamping[0]) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if (($scope.cardValidityDuration != $scope.listOfCardValidityDuration[0] && ($rootScope.partnerSubscriptionType == "yalty_start_free" || $rootScope.partnerSubscriptionType == "yalty_start_paid")) || ($scope.cardValidityDuration != $scope.listOfCardValidityDuration[6] && $rootScope.partnerSubscriptionType != "yalty_start_free" && $rootScope.partnerSubscriptionType != "yalty_start_paid")) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionConditionsDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promoCategoryId != $scope.mainPromoCategory) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyStores) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        }

        $scope.setSumNrOfStampsNeeded = function () {
            if ($scope.reward1NrOfStampsNeeded && $scope.reward2NrOfStampsNeeded == undefined) {
                $scope.sumNrOfStampsNeeded = $scope.reward1NrOfStampsNeeded;
            } else if ($scope.reward2NrOfStampsNeeded && $scope.reward3NrOfStampsNeeded == undefined) {
                $scope.sumNrOfStampsNeeded = $scope.reward2NrOfStampsNeeded;
            } else if ($scope.reward3NrOfStampsNeeded && $scope.reward4NrOfStampsNeeded == undefined) {
                $scope.sumNrOfStampsNeeded = $scope.reward3NrOfStampsNeeded;
            } else if ($scope.reward4NrOfStampsNeeded && $scope.reward5NrOfStampsNeeded == undefined) {
                $scope.sumNrOfStampsNeeded = $scope.reward4NrOfStampsNeeded;
            } else if ($scope.reward5NrOfStampsNeeded) {
                $scope.sumNrOfStampsNeeded = $scope.reward5NrOfStampsNeeded;
            }
            if ($scope.sumNrOfStampsNeeded < 10) {
                $scope.floorNrOfStampsNeeded = Math.ceil($scope.sumNrOfStampsNeeded / 2);
            } else {
                $scope.floorNrOfStampsNeeded = 6;
            }
        }

        $scope.isSameCompanyLogoImg = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isCardLogoImgURLSameAsCo == true) {
                $rootScope.logoCropperData.compLogoImg = $scope.loyaltyCardData.companyData[0].compLogoImgURL;
            } else {
                if ($scope.loyaltyCardId) {
                    $rootScope.logoCropperData.compLogoImg = !$scope.isCardLogoImgSameAsCo ?
                        $scope.loyaltyCardData.loyaltyCardDetalViewData.cardLogoImgURL : null
                } else {
                    $rootScope.logoCropperData = {};
                }
            }
        }

        var addOrUpdateLoyaltyCard = function (loyaltyCardForm, isBeforeActivation) {
            /*if ($scope.loyaltyCardPromoStatusCode == 0) {
                $scope.isActivateLoyaltyCard = false
            }*/
            return $q(function (resolve, reject) {
                if (!loyaltyCardForm.$valid) {

                    angular.forEach(loyaltyCardForm.$error, function (field) {
                        angular.forEach(field, function (errorField) {
                            errorField.$setTouched();
                        });
                    });
                    let errorInEnglishTranslation = null
                    if (!loyaltyCardForm.translatedCompNamePromo.$valid) {
                        errorInEnglishTranslation = 'translatedCompNamePromo'
                    } else if (!loyaltyCardForm.translatedPromotionName.$valid) {
                        errorInEnglishTranslation = 'translatedPromotionName'
                    } else if (!loyaltyCardForm.translatedStampConditionsDescr.$valid) {
                        errorInEnglishTranslation = 'translatedStampConditionsDescr'
                    } else if (!loyaltyCardForm.translatedReward1RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(1) && $scope.handlingErrorInTranslatedDescrField(1))) {
                        errorInEnglishTranslation = 'translatedReward1RewardDescr'
                    } else if (!loyaltyCardForm.translatedReward2RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(2) && $scope.handlingErrorInTranslatedDescrField(2))) {
                        errorInEnglishTranslation = 'translatedReward2RewardDescr'
                    } else if (!loyaltyCardForm.translatedReward3RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(3) && $scope.handlingErrorInTranslatedDescrField(3))) {
                        errorInEnglishTranslation = 'translatedReward3RewardDescr'
                    } else if (!loyaltyCardForm.translatedReward4RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(4) && $scope.handlingErrorInTranslatedDescrField(4))) {
                        errorInEnglishTranslation = 'translatedReward4RewardDescr'
                    } else if (!loyaltyCardForm.translatedReward5RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(5) && $scope.handlingErrorInTranslatedDescrField(5))) {
                        errorInEnglishTranslation = 'translatedReward5RewardDescr'
                    } else if (!loyaltyCardForm.translatedPromotionConditionsDescr.$valid) {
                        errorInEnglishTranslation = 'translatedPromotionConditionsDescr'
                    } else if (!loyaltyCardForm.translatedPhoneOrderInfoMessage.$valid) {
                        errorInEnglishTranslation = 'translatedPhoneOrderInfoMessage'
                    } else if (!loyaltyCardForm.translatedOnlineOrderInfoMessage.$valid) {
                        errorInEnglishTranslation = 'translatedOnlineOrderInfoMessage'
                    }

                    if (errorInEnglishTranslation) {
                        $scope.langName = 'angol'
                        $scope.loyaltyCardForm.languageSelector.$touched = true
                        $scope.loyaltyCardForm.languageSelector.$valid = false
                        $scope.loyaltyCardForm.languageSelector.$invalid = true
                        $scope.loyaltyCardForm.languageSelector.$error = { required: true }
                        document.getElementById('languageSelector').scrollIntoView()
                    }

                    let invalidRewardDescrFields = []
                    let element

                    if ($scope.loyaltyCardForm.reward1RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(1) && $scope.handlingErrorInDescrField(1))) {
                        invalidRewardDescrFields.push('reward1RewardDescr')
                        element = element || document.getElementById("reward1RewardDescr")
                    } else if ($scope.loyaltyCardForm.reward2RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(2) && $scope.handlingErrorInDescrField(2))) {
                        invalidRewardDescrFields.push('reward2RewardDescr')
                        element = element || document.getElementById("reward2RewardDescr")
                    } else if ($scope.loyaltyCardForm.reward3RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(3) && $scope.handlingErrorInDescrField(3))) {
                        invalidRewardDescrFields.push('reward3RewardDescr')
                        element = element || document.getElementById("reward3RewardDescr")
                    } else if ($scope.loyaltyCardForm.reward4RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(4) && $scope.handlingErrorInDescrField(4))) {
                        invalidRewardDescrFields.push('reward4RewardDescr')
                        element = element || document.getElementById("reward4RewardDescr")
                    } else if ($scope.loyaltyCardForm.reward5RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(5) && $scope.handlingErrorInDescrField(5))) {
                        invalidRewardDescrFields.push('reward5RewardDescr')
                        element = element || document.getElementById("reward5RewardDescr")
                    }
                    if (invalidRewardDescrFields.length > 0) {
                        for (const rewardField of invalidRewardDescrFields) {
                            $scope.loyaltyCardForm[rewardField].$touched = true
                            $scope.loyaltyCardForm[rewardField].$valid = false
                            $scope.loyaltyCardForm[rewardField].$invalid = true
                        }
                        element.scrollIntoView();
                        reject(false)
                    }


                    if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                        angular.element($document[0].querySelector('input.ng-invalid')).focus();
                    } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                        angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                    } else {
                        angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                    }
                } else if ($scope.isActivateLoyaltyCard && $rootScope.stampCropperData.stampImg == null && $rootScope.logoCropperData.compLogoImg == null && $rootScope.unstampCropperData.unstampImg == null && $scope.stampIconType == 2 && $scope.showStampIcon == true) {
                    loyaltyCardForm.stampImg.$error.need = true;
                    loyaltyCardForm.logoImage.$error.need = true;
                    loyaltyCardForm.unstampImg.$error.need = true;
                    //$('.main').animate({ scrollTop: $('div#logoImage').offset().top - 150 }, "slow");
                    const element = document.getElementById("logoImage")
                    element.scrollIntoView();
                    reject(false);
                } else if ($rootScope.stampCropperData.stampImg == null && $scope.stampIconType == 2 && $scope.partnerSubscriptionType == "premium" && $scope.showStampIcon == true) {
                    loyaltyCardForm.stampImg.$error.need = true;
                    loyaltyCardForm.templateImgs.$error.need = null;
                    //$('.main').animate({ scrollTop: $('div#stampImg').offset().top - 150 }, "slow");
                    const element = document.getElementById("showStamps")
                    element.scrollIntoView();
                    reject(false);
                } else if ($scope.isActivateLoyaltyCard && $scope.stampIconType == 1 && $scope.showStampIcon && !$scope.selectedTemplateImg) {
                    loyaltyCardForm.templateImgs.$error.need = true;
                    loyaltyCardForm.stampImg.$error.need = null;
                    loyaltyCardForm.unstampImg.$error.need = null;
                    //$('.main').animate({ scrollTop: $('div#templateImgs').offset().top - 150 }, "slow");
                    const element = document.getElementById("showStamps")
                    element.scrollIntoView();
                    reject(false);
                } else if ($scope.isActivateLoyaltyCard && $rootScope.logoCropperData.compLogoImg == null) {
                    loyaltyCardForm.logoImage.$error.need = true;
                    //$('.main').animate({ scrollTop: $('div#logoImage').offset().top - 150 }, "slow");
                    const element = document.getElementById("logoImgContainer")
                    element.scrollIntoView();
                    reject(false);
                } else if ($rootScope.unstampCropperData.unstampImg == null && $scope.stampIconType == 2 && $scope.partnerSubscriptionType == "premium" && $scope.showStampIcon == true) {
                    loyaltyCardForm.unstampImg.$error.need = true;
                    //$('.main').animate({ scrollTop: $('div#unstampImg').offset().top - 150 }, "slow");
                    const element = document.getElementById("unstampImg")
                    element.scrollIntoView();
                    reject(false);
                } else if (($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithStampCounterColor || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor || $scope.isCardBackgroundColorConflictedWithStampFixColor || $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) && $scope.isActivateLoyaltyCard) {
                    var alert = $mdDialog.alert()
                        .title("Kis kontraszt - túl közeli színárnyalatok")
                        .htmlContent("A kártya megfelelő megjelenítéséhez és kényelmes használatához fontos, hogy a különböző elemek színei megfelelő kontrasztban legyenek egymással.<br><br>Jelenleg egy vagy több ponton a kártya elemeinek színei túl közel vannak egymáshoz. Kérjük adjon meg ezeknél markánsabban különböző színárnyalatokat.")
                        .ok('Javítás')
                    $mdDialog.show(alert).then(function () {
                        //$('.main').animate({ scrollTop: $('div#showBaseStampDesign').offset().top - 150 }, "slow");
                        let contrastRatioError = "";
                        if ($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithStampCounterColor || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor || $scope.isCardBackgroundColorConflictedWithStampFixColor) {
                            contrastRatioError = "cardContrastRatioError"
                        } else if ($scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) contrastRatioError = "detailedDisplayContrastRatioError";
                        const element = document.getElementById(contrastRatioError);
                        element.scrollIntoView();
                    });
                } else if ($scope.showStampIcon == 0 && $scope.showBaseStampDesign == 0) {
                    var alert = $mdDialog.alert()
                        .title("Érvénytelen mezőértékek.")
                        .textContent("'Pecsét alap megjelenítése' vagy 'Pecsét kép megjelenítése' mezőértékek szükségesek.")
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        //$('.main').animate({ scrollTop: $('div#showBaseStampDesign').offset().top - 150 }, "slow");
                        const element = document.getElementById("stampsview")
                        element.scrollIntoView();
                    });
                } else if ($scope.isYLCSelectableProductsEnabled == true && ($scope.getListItems()).length == 0) {
                    var alert = $mdDialog.alert()
                        .title("Pecsételési/Beváltási tételek nincsenek megadva")
                        .textContent("Amennyiben szeretné bekapcsolni a 'Pecsételési/Beváltási tételek' funkciót, kérjük adjon meg legalább 1 tételt!")
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        const element = document.getElementById("YLCSelectableProductsDiv")
                        element.scrollIntoView();
                    });
                }
                else if ($scope.redeemCanBePostponed == 0 && $scope.isYLCSelectableProductsEnabled == true) {
                    var alert = $mdDialog.alert()
                        .title("Sikertelen mentés")
                        .textContent("Az 'Azonnali jutalom beváltás' opció a 'A beváltás következő alkalomra használhatósága' mezőben kiválasztott és a 'Pecsételési/Beváltási tételek' funkció nem lehet egyszerre bekapcsolt!")
                        .ok('Javítás')
                    $mdDialog.show(alert).then(function () {
                        const element = document.getElementById("redeemCanBePostponed")
                        element.scrollIntoView();
                    });
                } else {
                    $scope.sendButtonDisabled = true;
                    /*if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }*/
                    var loyaltyCard = {};
                    loyaltyCard.loyaltyCardId = $scope.loyaltyCardId;
                    loyaltyCard.detailedDisplayBackgroundColor = $scope.detailedDisplayBackgroundColor;
                    loyaltyCard.backButtonColor = $scope.backButtonColor;
                    loyaltyCard.cardBackgroundColor = $scope.cardBackgroundColor;
                    loyaltyCard.cardLogoImg = $rootScope.logoCropperData.compLogoImg;
                    loyaltyCard.isCardLogoImgURLSameAsCo = $scope.isCardLogoImgURLSameAsCo ? 1 : 0;
                    loyaltyCard.compNamePromo = $scope.compNamePromo;
                    loyaltyCard.isCompNamePromoSameAsCo = $scope.isCompNamePromoSameAsCo;
                    loyaltyCard.compNamePromoTextColor = $scope.compNamePromoTextColor;
                    loyaltyCard.promotionName = $scope.promotionName;
                    loyaltyCard.promotionNameTextColor = $scope.promotionNameTextColor;
                    loyaltyCard.stampTextPlaceholderColor = $scope.stampTextPlaceholderColor;
                    loyaltyCard.stampCurrentCountTextColor = $scope.stampCurrentCountTextColor;
                    if ($scope.partnerSubscriptionType != "yalty_start_free" && $scope.partnerSubscriptionType != "yalty_start_paid") {
                        loyaltyCard.showBaseStampDesign = $scope.showBaseStampDesign ? 1 : 0;
                        if ($scope.showBaseStampDesign == true) {
                            loyaltyCard.baseStampUnstampedFillColor = $scope.baseStampUnstampedFillColor;
                            loyaltyCard.baseStampUnstampedLineWeight = $scope.baseStampUnstampedLineWeight;
                            loyaltyCard.baseStampUnstampedLineColor = $scope.baseStampUnstampedLineColor;
                            loyaltyCard.baseStampStampedFillColor = $scope.baseStampStampedFillColor;
                            loyaltyCard.baseStampStampedLineWeight = $scope.baseStampStampedLineWeight;
                            loyaltyCard.baseStampStampedLineColor = $scope.baseStampStampedLineColor;
                        } else {
                            loyaltyCard.baseStampUnstampedFillColor = null;
                            loyaltyCard.baseStampUnstampedLineWeight = null;
                            loyaltyCard.baseStampUnstampedLineColor = null;
                            loyaltyCard.baseStampStampedFillColor = null;
                            loyaltyCard.baseStampStampedLineWeight = null;
                            loyaltyCard.baseStampStampedLineColor = null;
                        }
                        loyaltyCard.showStampIcon = $scope.showStampIcon ? 1 : 0;
                    } else {
                        loyaltyCard.showBaseStampDesign = 1;
                        loyaltyCard.baseStampUnstampedFillColor = "#FFFFFF";
                        loyaltyCard.baseStampUnstampedLineWeight = 1;
                        loyaltyCard.baseStampUnstampedLineColor = "#e5e8eb";
                        loyaltyCard.baseStampStampedFillColor = "#00AEEF";
                        loyaltyCard.baseStampStampedLineWeight = 1;
                        loyaltyCard.baseStampStampedLineColor = "#e5e8eb";
                        loyaltyCard.showStampIcon = 0;
                    }
                    if ($scope.showStampIcon == true) {
                        if ($scope.stampIconType) {
                            loyaltyCard.stampIconType = $scope.stampIconType;
                        } else {
                            loyaltyCard.stampIconType = null;
                        }
                        if ($scope.stampIconType == 1 && $scope.selectedTemplateImg && $scope.selectedTemplateImg.stampIconURLPrefix && ($scope.partnerSubscriptionType == "basic" || $scope.partnerSubscriptionType == "premium")) {
                            loyaltyCard.unStampedIcon = $scope.selectedTemplateImg.stampIconURLPrefix;
                            loyaltyCard.stampedIcon = $scope.selectedTemplateImg.stampIconURLPrefix;
                        } else if ($scope.stampIconType == 2 && $rootScope.stampCropperData && $rootScope.stampCropperData.stampImg && $rootScope.unstampCropperData && $rootScope.unstampCropperData.unstampImg && $scope.partnerSubscriptionType == "premium") {
                            if ($rootScope.unstampCropperData.unstampImg.includes('/imgs/')) {
                                loyaltyCard.unStampedIcon = $rootScope.unstampCropperData.unstampImg.split("_unstamped.png")[0];
                            } else if ($rootScope.unstampCropperData.originalUnstampImg.includes('/imgs/')) {
                                loyaltyCard.unStampedIcon = $rootScope.unstampCropperData.originalUnstampImg.split("_unstamped.png")[0];
                            } else {
                                loyaltyCard.unStampedIcon = $rootScope.unstampCropperData.originalUnstampImg;
                            }
                            if ($rootScope.stampCropperData.stampImg.includes('/imgs/')) {
                                loyaltyCard.stampedIcon = $rootScope.stampCropperData.stampImg.split("_stamped.png")[0];
                            } else if ($rootScope.stampCropperData.originalStampImg.includes('/imgs/')) {
                                loyaltyCard.stampedIcon = $rootScope.stampCropperData.originalStampImg.split("_stamped.png")[0];
                            } else {
                                loyaltyCard.stampedIcon = $rootScope.stampCropperData.originalStampImg;
                            }
                        }
                        if ($scope.partnerSubscriptionType != "yalty_start_free" && $scope.partnerSubscriptionType != "yalty_start_paid") {
                            loyaltyCard.stampIconStampedSizing = $scope.stampIconStampedSizing;
                            loyaltyCard.stampIconUnstampedSizing = $scope.stampIconUnstampedSizing;
                        }
                        if ($scope.partnerSubscriptionType == "premium") {
                            loyaltyCard.stampIconUnstampedColor = $scope.stampIconUnstampedColor;
                            loyaltyCard.stampIconStampedColor = $scope.stampIconStampedColor;
                        } else {
                            loyaltyCard.stampIconUnstampedColor = null;
                            loyaltyCard.stampIconStampedColor = null;
                        }
                    } else {
                        loyaltyCard.stampIconType = null;
                        loyaltyCard.stampIconUnstampedColor = null;
                        loyaltyCard.stampIconStampedColor = null;
                        loyaltyCard.stampedIcon = null;
                        loyaltyCard.unStampedIcon = null;
                        loyaltyCard.stampIconStampedSizing = null;
                        loyaltyCard.stampIconUnstampedSizing = null;
                    }
                    loyaltyCard.stampConditionsDescr = $scope.stampConditionsDescr;
                    if ($scope.noOfRewards == 1 && ($scope.reward1NrOfStampsNeeded || $scope.reward1RewardDescr) && $scope.partnerSubscriptionType != "premium") {
                        loyaltyCard.reward1NrOfStampsNeeded = $scope.reward1NrOfStampsNeeded;
                        loyaltyCard.reward1RewardDescr = $scope.getRewardDescrWithoutPrefix(1)
                        loyaltyCard.noOfRewards = $scope.noOfRewards;
                    } else if ($scope.noOfRewards >= 1 && ($scope.reward1NrOfStampsNeeded || $scope.reward1RewardDescr) && $scope.partnerSubscriptionType == "premium") {
                        loyaltyCard.reward1NrOfStampsNeeded = $scope.reward1NrOfStampsNeeded;
                        loyaltyCard.reward1RewardDescr = $scope.getRewardDescrWithoutPrefix(1)
                        loyaltyCard.noOfRewards = $scope.noOfRewards;
                    } else {
                        loyaltyCard.reward1NrOfStampsNeeded = null;
                        loyaltyCard.reward1RewardDescr = null;
                        loyaltyCard.noOfRewards = $scope.noOfRewards;
                    }
                    if ($scope.noOfRewards >= 2 && ($scope.reward2NrOfStampsNeeded || $scope.reward2RewardDescr) && $scope.partnerSubscriptionType == "premium") {
                        loyaltyCard.reward2NrOfStampsNeeded = $scope.reward2NrOfStampsNeeded;
                        loyaltyCard.reward2RewardDescr = $scope.getRewardDescrWithoutPrefix(2)
                    } else {
                        loyaltyCard.reward2NrOfStampsNeeded = null;
                        loyaltyCard.reward2RewardDescr = null;
                    }
                    if ($scope.noOfRewards >= 3 && ($scope.reward3NrOfStampsNeeded || $scope.reward3RewardDescr) && $scope.partnerSubscriptionType == "premium") {
                        loyaltyCard.reward3NrOfStampsNeeded = $scope.reward3NrOfStampsNeeded;
                        loyaltyCard.reward3RewardDescr = $scope.getRewardDescrWithoutPrefix(3)
                    } else {
                        loyaltyCard.reward3NrOfStampsNeeded = null;
                        loyaltyCard.reward3RewardDescr = null;
                    }
                    if ($scope.noOfRewards >= 4 && ($scope.reward4NrOfStampsNeeded || $scope.reward4RewardDescr) && $scope.partnerSubscriptionType == "premium") {
                        loyaltyCard.reward4NrOfStampsNeeded = $scope.reward4NrOfStampsNeeded;
                        loyaltyCard.reward4RewardDescr = $scope.getRewardDescrWithoutPrefix(4)
                    } else {
                        loyaltyCard.reward4NrOfStampsNeeded = null;
                        loyaltyCard.reward4RewardDescr = null;
                    }
                    if ($scope.noOfRewards == 5 && ($scope.reward5NrOfStampsNeeded || $scope.reward5RewardDescr) && $scope.partnerSubscriptionType == "premium") {
                        loyaltyCard.reward5NrOfStampsNeeded = $scope.reward5NrOfStampsNeeded;
                        loyaltyCard.reward5RewardDescr = $scope.getRewardDescrWithoutPrefix(5)
                    } else {
                        loyaltyCard.reward5NrOfStampsNeeded = null;
                        loyaltyCard.reward5RewardDescr = null;
                    }
                    loyaltyCard.canBeRedeemedLater = $scope.canBeRedeemedLater;
                    if (loyaltyCard.canBeRedeemedLater == true) {
                        loyaltyCard.redeemCanBePostponed = null;
                        loyaltyCard.mustBeRedeemedLater = $scope.mustBeRedeemedLater;
                        loyaltyCard.nrOfRedeemableRewardsAtOnce = $scope.isNrOfRedeemableRewardsAtOnceUnlimited == true ? 0 : $scope.nrOfRedeemableRewardsAtOnce;
                        loyaltyCard.nrOfRedeemableRewardsPerRewardTypes = ($scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited == true || $scope.noOfRewards == 1) ? 0 : $scope.nrOfRedeemableRewardsPerRewardTypes;
                    } else {
                        loyaltyCard.redeemCanBePostponed = $scope.redeemCanBePostponed;
                        loyaltyCard.mustBeRedeemedLater = null;
                        loyaltyCard.nrOfRedeemableRewardsAtOnce = null;
                        loyaltyCard.nrOfRedeemableRewardsPerRewardTypes = null;
                    }
                    loyaltyCard.maxStampNrLimitationAtStamping = $scope.isMaxStampNrLimitationAtStampingUnlimited == true ? null : $scope.maxStampNrLimitationAtStamping;
                    if ($scope.partnerSubscriptionType != "yalty_start_free" && $scope.partnerSubscriptionType != "yalty_start_paid") {
                        if ($scope.maxNoOfDaysBetweenStampingText) {
                            const index = $scope.listOfMaxNoOfDaysBetweenStamping.findIndex(x => x.displayText == $scope.maxNoOfDaysBetweenStampingText);
                            if (index != -1) {
                                loyaltyCard.maxNoOfDaysBetweenStamping = $scope.listOfMaxNoOfDaysBetweenStamping[index].value;
                            } else {
                                loyaltyCard.maxNoOfDaysBetweenStamping = $scope.maxNoOfDaysBetweenStampingText;
                            }
                        } else {
                            loyaltyCard.maxNoOfDaysBetweenStamping = null;
                        }
                    } else {
                        loyaltyCard.maxNoOfDaysBetweenStamping = null;
                    }
                    if (loyaltyCard.maxNoOfDaysBetweenStamping == "null") {
                        loyaltyCard.maxNoOfDaysBetweenStamping = null;
                    }
                    if ($scope.cardValidityDurationText) {
                        const index = $scope.listOfCardValidityDuration.findIndex(x => x.displayText == $scope.cardValidityDurationText);
                        if (index != -1) {
                            loyaltyCard.cardValidityDuration = $scope.listOfCardValidityDuration[index].value;
                        } else {
                            loyaltyCard.cardValidityDuration = $scope.cardValidityDurationText;
                        }
                    } else {
                        loyaltyCard.cardValidityDuration = null;
                    }
                    if (loyaltyCard.cardValidityDuration == "null") {
                        loyaltyCard.cardValidityDuration = null;
                    }
                    loyaltyCard.promotionConditionsDescr = $scope.promotionConditionsDescr;
                    loyaltyCard.isPhoneOrderAvailable = $scope.isPhoneOrderAvailable;
                    loyaltyCard.phoneOrderInfoMessage = $scope.isPhoneOrderAvailable == true ? $scope.phoneOrderInfoMessage : undefined;
                    loyaltyCard.isOnlineOrderAvailable = $scope.isOnlineOrderAvailable;
                    loyaltyCard.onlineOrderInfoMessage = $scope.isOnlineOrderAvailable == true ? $scope.onlineOrderInfoMessage : undefined;
                    if ($scope.loyaltyCardData.companyData[0].isPartnerRedeemRemarkEnabled == true) {
                        loyaltyCard.isYLCPartnerRedeemRemarkEnabled = $scope.isYLCPartnerRedeemRemarkEnabled;
                    }
                    var stores = [];
                    for (var i in $scope.companyStores) {
                        stores[i] = $scope.companyStores[i];
                    }
                    loyaltyCard.isRedeemLocationsModified = $scope.isCompStoresSame;
                    loyaltyCard.redeemLocationIndicator = $scope.isAllRedeemLocationIndicator ? 'all' : 'select'
                    loyaltyCard.promoCategoryId = $scope.promoCategoryId;
                    loyaltyCard.redeemLocations = stores;
                    if ($scope.isBeforeActivation) {
                        loyaltyCard.isBeforeActivation = $scope.isBeforeActivation;
                    } else {
                        loyaltyCard.isBeforeActivation = isBeforeActivation;
                    }

                    loyaltyCard.isTranslation_EN_Enabled = $scope.isEnglishTranslationEnabled == 'bekapcsolt'
                    loyaltyCard.compNamePromo_EN = $scope['EN'].compNamePromo
                    loyaltyCard.promotionName_EN = $scope['EN'].promotionName
                    loyaltyCard.stampConditionsDescr_EN = $scope['EN'].stampConditionsDescr
                    loyaltyCard.reward1RewardDescr_EN = $scope['EN'].reward1RewardDescr
                    loyaltyCard.reward2RewardDescr_EN = $scope['EN'].reward2RewardDescr
                    loyaltyCard.reward3RewardDescr_EN = $scope['EN'].reward3RewardDescr
                    loyaltyCard.reward4RewardDescr_EN = $scope['EN'].reward4RewardDescr
                    loyaltyCard.reward5RewardDescr_EN = $scope['EN'].reward5RewardDescr
                    loyaltyCard.promotionConditionsDescr_EN = $scope['EN'].promotionConditionsDescr
                    loyaltyCard.phoneOrderInfoMessage_EN = $scope['EN'].phoneOrderInfoMessage
                    loyaltyCard.onlineOrderInfoMessage_EN = $scope['EN'].onlineOrderInfoMessage
                    loyaltyCard.isCardHiddenFromPublic = $scope.isCardHiddenFromPublic;
                    loyaltyCard.publiclyHiddenCardRevealCode = $scope.publiclyHiddenCardRevealCode;
                    loyaltyCard.linkedPreviousLoyaltyCardYaltyId = $scope.linkedPreviousLoyaltyCardYaltyId;
                    let linkedPreviousLoyaltyCardYaltyIdChainHistory = null;
                    if ($scope.linkedPreviousLoyaltyCardYaltyId != null) {
                        let loyaltyCard = $scope.allLoyaltyCard.find(obj => {
                            return obj.id === $scope.linkedPreviousLoyaltyCardYaltyId
                        })
                        if (loyaltyCard != undefined) {
                            if (loyaltyCard.linkedPreviousLoyaltyCardYaltyIdChainHistory == null) {
                                linkedPreviousLoyaltyCardYaltyIdChainHistory = '' + $scope.linkedPreviousLoyaltyCardYaltyId;
                            } else {
                                linkedPreviousLoyaltyCardYaltyIdChainHistory = loyaltyCard.linkedPreviousLoyaltyCardYaltyIdChainHistory + ';' + $scope.linkedPreviousLoyaltyCardYaltyId;
                            }
                        }
                    }
                    loyaltyCard.linkedPreviousLoyaltyCardYaltyIdChainHistory = linkedPreviousLoyaltyCardYaltyIdChainHistory;
                    loyaltyCard.isYLCSelectableProductsEnabled = $scope.isYLCSelectableProductsEnabled == true;
                    if (loyaltyCard.isYLCSelectableProductsEnabled == true) {
                        loyaltyCard.YLCSelectableProducts = $scope.getListItems();
                    }
                    if ($scope.loyaltyCardId && $scope.loyaltyCardPromoStatusCode == 1 && $scope.loyaltyCardData.loyaltyCardDetalViewData.loyaltyCardPromoStatus == 1) {
                        var confirm = $mdDialog.confirm()
                            .title('A hűségkártyához futó promóció tartozik.')
                            .textContent('Biztosan módosítja a hűségkártya adatait?')
                            .ok('Módosítás')
                            .cancel('Mégsem');
                        $mdDialog.show(confirm).then(function () {
                            /*if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "block";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                            }*/
                            $scope.saveInProgress = true;
                            LoyaltyCardService.addOrUpdateLoyaltyCard(loyaltyCard).then(function (result) {
                                /*if (document.getElementById('loadingOverlay')) {
                                    document.getElementById('loadingOverlay').style.display = "none";
                                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                                }*/
                                $scope.saveInProgress = false;
                                if (result.errorCode == 0) {
                                    resolve(true);
                                } else {
                                    $scope.sendButtonDisabled = false;
                                    reject(result.errorCode);
                                }
                            }, function () {

                            })
                        }, function () {
                            /*if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }*/
                            $scope.saveInProgress = false;
                            $scope.sendButtonDisabled = false;
                        })
                    } else {
                        /*if (document.getElementById('loadingOverlay')) {
                            document.getElementById('loadingOverlay').style.display = "block";
                            document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                        }*/
                        $scope.saveInProgress = true;
                        LoyaltyCardService.addOrUpdateLoyaltyCard(loyaltyCard).then(function (result) {
                            /*if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }*/
                            $scope.saveInProgress = false;
                            if (result.errorCode == 0) {
                                $scope.loyaltyCardId = result.loyaltyCardYaltyId;
                                resolve(true);
                            } else {
                                $scope.sendButtonDisabled = false;
                                reject(result.errorCode);
                            }
                        }, function () {
                            $scope.saveInProgress = false;
                        })
                    }
                }
            })
        }

        $scope.addOrUpdateLoyaltyCard = function (form) {
            if ($rootScope.isDataSheetModified == false) {
                const alert = $mdDialog.alert()
                    .title("A sablonon nem történt változtatás.")
                    .textContent("Az üres sablonon nem történt változtatás. Változtatás nélkül nem mentődik el a lap.")
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () { });
            } else {
                addOrUpdateLoyaltyCard(form, false).then(function (result) {
                    if (result == true) {
                        let alert;
                        if ($scope.loyaltyCardId) {
                            if ($scope.isActivateLoyaltyCard) {
                                alert = $mdDialog.alert()
                                    .title("A hűségkártya módosításait elmentettük")
                                    .ok('Rendben')
                            } else {
                                alert = $mdDialog.alert()
                                    .title("A hűségkártyát elmentettük")
                                    .htmlContent("Hűségkártya aktiválása: a hűségkártyát visszatöltve a lap jobb felső sarkában találja az aktiválás gombot, amivel az adott hűségkártyát aktiválni, vagyis a felhasználók számára elérhetővé tenni tudja. <br><br> Aktiválni csak olyan hűségkártyát lehet, amely esetén a lap minden mezője kitöltött.")
                                    .ok('Rendben')
                            }
                        } else {
                            alert = $mdDialog.alert()
                                .title("A hűségkártyát elmentettük")
                                .htmlContent("Hűségkártya aktiválása: a hűségkártyát visszatöltve a lap jobb felső sarkában találja az aktiválás gombot, amivel az adott hűségkártyát aktiválni, vagyis a felhasználók számára elérhetővé tenni tudja. <br><br> Aktiválni csak olyan hűségkártyát lehet, amely esetén a lap minden mezője kitöltött.")
                                .ok('Rendben')
                        }
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $state.reload();
                        });
                    }
                }, function (result) {
                    if (result == 1) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result == 2) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $scope.sendButtonDisabled = false;
                            $state.go('partner_HU');
                        });
                    } else if (result == 3) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Hűségkártya státusza miatt nem módosítható.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result == 4) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Az mentéshez nincsen a megfelelő előfizetési státuszban!")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result == 5) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Az aktiváláshoz legalább 1 beváltóhelyet ki kell választani.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    }
                })
            }
        }

        $scope.isUnstampedColorEnabledChanged = function () {
            if ($scope.isUnstampedColorEnabled == false) {
                $scope.stampIconUnstampedColor = null;
                $("#stampIconUnstampedColorInUniqueStamp")[0].value = "";
                $('#stampIconUnstampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = "";
                if ($rootScope.unstampCropperData.originalUnstampImg && $rootScope.unstampCropperData.previewUnstampImg && $scope.stampIconType == 2) {
                    $rootScope.unstampCropperData.previewUnstampImg = $rootScope.unstampCropperData.originalUnstampImg;
                } else if ($scope.selectedTemplateImg && $scope.stampIconType == 1) {
                    //$rootScope.unstampCropperData.previewUnstampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
                }
            } else {
                if (stampIconUnstampedColor) {
                    $scope.stampIconUnstampedColor = stampIconUnstampedColor;
                    $("#stampIconUnstampedColorInUniqueStamp")[0].value = stampIconUnstampedColor;
                    $('#stampIconUnstampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = stampIconUnstampedColor;
                    $scope.shiftColor(false, false);
                }
            }
        }

        $scope.isStampedColorEnabledChanged = function () {
            if ($scope.isStampedColorEnabled == false) {
                $scope.stampIconStampedColor = null;
                $("#stampIconStampedColorInUniqueStamp")[0].value = "";
                $('#stampIconStampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = "";
                if ($rootScope.stampCropperData.originalStampImg && $rootScope.stampCropperData.previewStampImg && $scope.stampIconType == 2) {
                    $rootScope.stampCropperData.previewStampImg = $rootScope.stampCropperData.originalStampImg;
                } else if ($scope.selectedTemplateImg && $scope.stampIconType == 1) {
                    //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                }
            } else {
                if (stampIconStampedColor) {
                    $scope.stampIconStampedColor = stampIconStampedColor;
                    $("#stampIconStampedColorInUniqueStamp")[0].value = stampIconStampedColor;
                    $('#stampIconStampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = stampIconStampedColor;
                    $scope.shiftColor(true, false);
                }
            }
        }

        $scope.selectCompStore = function () {
            $scope.isCompStoresSame = true;
        }
        const setAllCompStores = function () {
            $scope.isCompStoresSame = true;
            if ($scope.companyStores == (null || undefined || '')) {
                if ($scope.allCompStores != null) {
                    for (var i = 0; i < $scope.allCompStores.length; i++) {
                        company.push($scope.allCompStores[i].id);
                    }
                    $scope.companyStores = company;
                    $scope.checkAll = false;
                }
            } else {
                $scope.companyStores = [];
                $scope.checkAll = true;
                company = [];
            }
        }
        $scope.checkRedeemLocations = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isAllRedeemLocationIndicator === true) {
                $scope.allRedeemLocationIndicator = "all";
            } else {
                $scope.allRedeemLocationIndicator = "select";
            }
            $scope.checkAll = true;
            if ($scope.allCompStores != null) {
                $scope.companyStores = [];
                company = [];
                for (var i = 0; i < $scope.allCompStores.length; i++) {
                    company.push($scope.allCompStores[i].id);
                }
                $scope.companyStores = company;
            }
        }
        $scope.$watch('companyStores', function (current) {
            if (current) {
                if (current.length === $scope.allCompStores.length) {
                    $scope.checkAll = true;
                } else {
                    $scope.checkAll = false;
                }
            } else {
                $scope.checkAll = false;
            }
        })
        $scope.$watch('isCardHiddenFromPublic', function (current) {
            if (current == true && $scope.publiclyHiddenCardRevealCode == null) {
                $scope.publiclyHiddenCardRevealCode = makeRevealCode();
            }
        })

        function makeRevealCode() {
            let result = '1';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < 7) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }

        /*$scope.coloringIcons = function (color) {
            
            backCanvas.img.src = "../imgs/back_line_icon.svg";
            shareIconCanvas.img.src = "../imgs/share_line_icon.svg";
            backCanvas.img.onload = function () {
                backCanvas.cw = backCanvas.canvas.width = backCanvas.img.width;
                backCanvas.ch = backCanvas.canvas.height = backCanvas.img.height;
                backCanvas.ctx.drawImage(backCanvas.img, 0, 0);
                backCanvas.imgData = backCanvas.ctx.getImageData(0, 0, backCanvas.cw, backCanvas.ch);
                backCanvas.data = backCanvas.imgData.data;
                backCanvas.imgData1 = backCanvas.ctx.getImageData(0, 0, backCanvas.cw, backCanvas.ch);
                backCanvas.originalData = backCanvas.imgData1.data;
                if (color) {
                    for (var i = 0; i < backCanvas.data.length; i += 4) {
                        alpha = backCanvas.originalData[i + 3];
                        if (alpha < 50) { continue; }
                        var r = parseInt(color.substr(1, 2), 16);
                        var g = parseInt(color.substr(3, 2), 16);
                        var b = parseInt(color.substr(5, 2), 16);
                        backCanvas.data[i + 0] = r
                        backCanvas.data[i + 1] = g
                        backCanvas.data[i + 2] = b
                        backCanvas.data[i + 3] = 255;
                    }
                    backCanvas.ctx.putImageData(backCanvas.imgData, 0, 0);
                }
            }
            shareIconCanvas.img.onload = function () {
                shareIconCanvas.cw = shareIconCanvas.canvas.width = shareIconCanvas.img.width;
                shareIconCanvas.ch = shareIconCanvas.canvas.height = shareIconCanvas.img.height;
                shareIconCanvas.ctx.drawImage(shareIconCanvas.img, 0, 0);
                shareIconCanvas.imgData = shareIconCanvas.ctx.getImageData(0, 0, shareIconCanvas.cw, shareIconCanvas.ch);
                shareIconCanvas.data = shareIconCanvas.imgData.data;
                shareIconCanvas.imgData1 = shareIconCanvas.ctx.getImageData(0, 0, shareIconCanvas.cw, shareIconCanvas.ch);
                shareIconCanvas.originalData = shareIconCanvas.imgData1.data;
                if (color) {
                    for (var i = 0; i < shareIconCanvas.data.length; i += 4) {
                        alpha = shareIconCanvas.originalData[i + 3];
                        if (alpha < 50) { continue; }
                        var r = parseInt(color.substr(1, 2), 16);
                        var g = parseInt(color.substr(3, 2), 16);
                        var b = parseInt(color.substr(5, 2), 16);
                        shareIconCanvas.data[i + 0] = r
                        shareIconCanvas.data[i + 1] = g
                        shareIconCanvas.data[i + 2] = b
                        shareIconCanvas.data[i + 3] = 255;
                    }
                    shareIconCanvas.ctx.putImageData(shareIconCanvas.imgData, 0, 0);
                }
            }
        }*/
        $scope.shiftColor = function (isStamp, isDataLoading, isTemplateSaved) {
            if (isDataLoading == false) {
                if ($rootScope.isDataSheetModified == false) {
                    $rootScope.isDataSheetModified = true;
                }
            }
            if (isStamp == true) {
                if (($rootScope.stampCropperData.stampImg || $scope.selectedTemplateImg || $scope.selectTemplateImg) && $scope.isStampedColorEnabled == true) {
                    if (uniqueStamp.isLoaded == false) {
                        if (isTemplateSaved == false) {
                            if ($scope.selectTemplateImg) {
                                uniqueStamp.img.src = $scope.selectTemplateImg.stampIconURLPrefix + "_stamped.png";
                            }
                        } else {
                            if ($scope.stampIconType == 1 && $scope.selectedTemplateImg) {
                                uniqueStamp.img.src = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                            } else if ($scope.stampIconType == 2 && $rootScope.stampCropperData.stampImg) {
                                uniqueStamp.img.src = $rootScope.stampCropperData.stampImg;
                            }
                        }
                        uniqueStamp.img.onload = function () {
                            uniqueStamp.isLoaded = true;
                            uniqueStamp.cw = uniqueStamp.stampCanvas.width = uniqueStamp.img.width;
                            uniqueStamp.ch = uniqueStamp.stampCanvas.height = uniqueStamp.img.height;
                            uniqueStamp.ctx.drawImage(uniqueStamp.img, 0, 0);
                            uniqueStamp.imgData = uniqueStamp.ctx.getImageData(0, 0, uniqueStamp.cw, uniqueStamp.ch);
                            uniqueStamp.data = uniqueStamp.imgData.data;
                            uniqueStamp.imgData1 = uniqueStamp.ctx.getImageData(0, 0, uniqueStamp.cw, uniqueStamp.ch);
                            uniqueStamp.originalData = uniqueStamp.imgData1.data;
                            if ($scope.stampIconStampedColor) {
                                for (var i = 0; i < uniqueStamp.data.length; i += 4) {
                                    const alpha = uniqueStamp.originalData[i + 3];
                                    if (alpha < 50) { continue; }
                                    var r = parseInt($scope.stampIconStampedColor.substr(1, 2), 16);
                                    var g = parseInt($scope.stampIconStampedColor.substr(3, 2), 16);
                                    var b = parseInt($scope.stampIconStampedColor.substr(5, 2), 16);
                                    uniqueStamp.data[i + 0] = r
                                    uniqueStamp.data[i + 1] = g
                                    uniqueStamp.data[i + 2] = b
                                    uniqueStamp.data[i + 3] = 255;
                                }
                                stampIconStampedColor = $scope.stampIconStampedColor;
                                uniqueStamp.ctx.putImageData(uniqueStamp.imgData, 0, 0);
                                if (isTemplateSaved == false) {
                                    if ($scope.selectTemplateImg.stampIconURLPrefix) {
                                        $scope.selectTemplateImg.stampIconColored = uniqueStamp.stampCanvas.toDataURL("image/png");
                                    }
                                } else {
                                    if ($scope.stampIconType == 1 && $scope.selectedTemplateImg.stampIconURLPrefix) {
                                        $scope.selectedTemplateImg.stampIconColored = uniqueStamp.stampCanvas.toDataURL("image/png");
                                    } else if ($scope.stampIconType == 2 && $rootScope.stampCropperData.stampImg) {
                                        //$rootScope.stampCropperData.stampImg = uniqueStamp.stampCanvas.toDataURL("image/png");
                                        $rootScope.stampCropperData.previewStampImg = uniqueStamp.stampCanvas.toDataURL("image/png");
                                    }
                                }
                            }
                        }
                    } else {
                        if ($scope.stampIconStampedColor) {
                            for (var i = 0; i < uniqueStamp.data.length; i += 4) {
                                const alpha = uniqueStamp.originalData[i + 3];
                                if (alpha < 50) { continue; }
                                var r = parseInt($scope.stampIconStampedColor.substr(1, 2), 16);
                                var g = parseInt($scope.stampIconStampedColor.substr(3, 2), 16);
                                var b = parseInt($scope.stampIconStampedColor.substr(5, 2), 16);
                                uniqueStamp.data[i + 0] = r
                                uniqueStamp.data[i + 1] = g
                                uniqueStamp.data[i + 2] = b
                                uniqueStamp.data[i + 3] = 255;
                            }
                            stampIconStampedColor = $scope.stampIconStampedColor;
                            uniqueStamp.ctx.putImageData(uniqueStamp.imgData, 0, 0);
                            if (isTemplateSaved == false) {
                                if ($scope.selectTemplateImg.stampIconURLPrefix) {
                                    $scope.selectTemplateImg.stampIconColored = uniqueStamp.stampCanvas.toDataURL("image/png");
                                }
                            } else {
                                if ($scope.stampIconType == 1 && $scope.selectedTemplateImg.stampIconURLPrefix) {
                                    $scope.selectedTemplateImg.stampIconColored = uniqueStamp.stampCanvas.toDataURL("image/png");
                                } else if ($scope.stampIconType == 2 && $rootScope.stampCropperData.stampImg) {
                                    //$rootScope.stampCropperData.stampImg = uniqueStamp.stampCanvas.toDataURL("image/png");
                                    $rootScope.stampCropperData.previewStampImg = uniqueStamp.stampCanvas.toDataURL("image/png");
                                }
                            }
                        }
                    }
                }
            } else {
                if (($rootScope.unstampCropperData.unstampImg || $scope.selectedTemplateImg || $scope.selectTemplateImg) && $scope.isUnstampedColorEnabled == true) {
                    if (uniqueUnstamp.isLoaded == false) {
                        if (isTemplateSaved == false) {
                            if ($scope.selectTemplateImg) {
                                uniqueUnstamp.img.src = $scope.selectTemplateImg.stampIconURLPrefix + "_unstamped.png";
                            }
                        } else {
                            if ($scope.stampIconType == 1 && $scope.selectedTemplateImg) {
                                uniqueUnstamp.img.src = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
                            } else if ($scope.stampIconType == 2 && $rootScope.unstampCropperData.unstampImg) {
                                uniqueUnstamp.img.src = $rootScope.unstampCropperData.unstampImg;
                            }
                        }
                        uniqueUnstamp.img.onload = function () {
                            uniqueUnstamp.isLoaded = true;
                            uniqueUnstamp.cw = uniqueUnstamp.stampCanvas.width = uniqueUnstamp.img.width;
                            uniqueUnstamp.ch = uniqueUnstamp.stampCanvas.height = uniqueUnstamp.img.height;
                            uniqueUnstamp.ctx.drawImage(uniqueUnstamp.img, 0, 0);
                            uniqueUnstamp.imgData = uniqueUnstamp.ctx.getImageData(0, 0, uniqueUnstamp.cw, uniqueUnstamp.ch);
                            uniqueUnstamp.data = uniqueUnstamp.imgData.data;
                            uniqueUnstamp.imgData1 = uniqueUnstamp.ctx.getImageData(0, 0, uniqueUnstamp.cw, uniqueUnstamp.ch);
                            uniqueUnstamp.originalData = uniqueUnstamp.imgData1.data;
                            if ($scope.stampIconUnstampedColor) {
                                for (var i = 0; i < uniqueUnstamp.data.length; i += 4) {
                                    const alpha = uniqueUnstamp.originalData[i + 3];
                                    if (alpha < 50) { continue; }
                                    var r = parseInt($scope.stampIconUnstampedColor.substr(1, 2), 16);
                                    var g = parseInt($scope.stampIconUnstampedColor.substr(3, 2), 16);
                                    var b = parseInt($scope.stampIconUnstampedColor.substr(5, 2), 16);
                                    uniqueUnstamp.data[i + 0] = r
                                    uniqueUnstamp.data[i + 1] = g
                                    uniqueUnstamp.data[i + 2] = b
                                    uniqueUnstamp.data[i + 3] = 255;
                                }
                                stampIconUnstampedColor = $scope.stampIconUnstampedColor;
                                uniqueUnstamp.ctx.putImageData(uniqueUnstamp.imgData, 0, 0);
                                if (isTemplateSaved == false) {
                                    if ($scope.selectTemplateImg.stampIconURLPrefix) {
                                        $scope.selectTemplateImg.unstampIconColored = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                    }
                                } else {
                                    if ($scope.stampIconType == 1 && $scope.selectedTemplateImg.stampIconURLPrefix) {
                                        $scope.selectedTemplateImg.unstampIconColored = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                    } else if ($scope.stampIconType == 2 && $rootScope.unstampCropperData.unstampImg) {
                                        //$rootScope.unstampCropperData.unstampImg = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                        $rootScope.unstampCropperData.previewUnstampImg = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                    }
                                }
                            }
                        }
                    } else {
                        if ($scope.stampIconUnstampedColor) {
                            for (let i = 0; i < uniqueUnstamp.data.length; i += 4) {
                                const alpha = uniqueUnstamp.originalData[i + 3];
                                if (alpha < 50) { continue; }
                                const r = parseInt($scope.stampIconUnstampedColor.substr(1, 2), 16);
                                const g = parseInt($scope.stampIconUnstampedColor.substr(3, 2), 16);
                                const b = parseInt($scope.stampIconUnstampedColor.substr(5, 2), 16);
                                uniqueUnstamp.data[i + 0] = r
                                uniqueUnstamp.data[i + 1] = g
                                uniqueUnstamp.data[i + 2] = b
                                uniqueUnstamp.data[i + 3] = 255;
                            }
                            stampIconUnstampedColor = $scope.stampIconUnstampedColor;
                            uniqueUnstamp.ctx.putImageData(uniqueUnstamp.imgData, 0, 0);
                            if (isTemplateSaved == false) {
                                if ($scope.selectTemplateImg.stampIconURLPrefix) {
                                    $scope.selectTemplateImg.unstampIconColored = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                }
                            } else {
                                if ($scope.stampIconType == 1 && $scope.selectedTemplateImg.stampIconURLPrefix) {
                                    $scope.selectedTemplateImg.unstampIconColored = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                } else if ($scope.stampIconType == 2 && $rootScope.unstampCropperData.unstampImg) {
                                    //$rootScope.unstampCropperData.unstampImg = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                    $rootScope.unstampCropperData.previewUnstampImg = uniqueUnstamp.stampCanvas.toDataURL("image/png");
                                }
                            }
                        }
                    }
                }
            }

        }

        $scope.checkStampStyles = function () {
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.baseStampStampedLineWeight == null && $scope.baseStampUnstampedLineWeight == null
                && $scope.baseStampUnstampedFillColor == null && $scope.baseStampUnstampedLineColor == null
                && $scope.baseStampStampedFillColor == null && $scope.baseStampStampedLineColor == null) {
                $scope.baseStampUnstampedFillColor = "#FFFFFF";
                $scope.baseStampUnstampedLineWeight = 1;
                $scope.baseStampUnstampedLineColor = "#e5e8eb";
                $scope.baseStampStampedFillColor = "#00AEEF";
                $scope.baseStampStampedLineWeight = 1;
                $scope.baseStampStampedLineColor = "#e5e8eb";
            }
            if ($scope.showBaseStampDesign == true) {
                $scope.stampedStyle = {
                    "background-color": $scope.baseStampStampedFillColor,
                    "border": "solid " + $scope.baseStampStampedLineWeight + "px",
                    "border-color": $scope.baseStampStampedLineColor,
                    "padding": (5 - $scope.baseStampStampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.previewStampedStyle = $scope.stampedStyle;
                $scope.unstampedStyle = {
                    "background-color": $scope.baseStampUnstampedFillColor,
                    "border": "solid " + $scope.baseStampUnstampedLineWeight + "px",
                    "border-color": $scope.baseStampUnstampedLineColor,
                    "padding": (5 - $scope.baseStampUnstampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.previweUnstampedStyle = $scope.unstampedStyle;
            } else {
                $scope.stampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
                $scope.previewStampedStyle = {
                    "background-color": $scope.baseStampStampedFillColor,
                    "border": "solid " + $scope.baseStampStampedLineWeight + "px",
                    "border-color": $scope.baseStampStampedLineColor,
                    "padding": (5 - $scope.baseStampStampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.unstampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
                $scope.previweUnstampedStyle = {
                    "background-color": $scope.baseStampUnstampedFillColor,
                    "border": "solid " + $scope.baseStampUnstampedLineWeight + "px",
                    "border-color": $scope.baseStampUnstampedLineColor,
                    "padding": (5 - $scope.baseStampUnstampedLineWeight) + "px",
                    "position": "relative"
                };
            }
        }

        $scope.checkStampedStyle = function () {
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.showBaseStampDesign == true) {
                $scope.stampedStyle = {
                    "background-color": $scope.baseStampStampedFillColor,
                    "border": "solid " + $scope.baseStampStampedLineWeight + "px",
                    "border-color": $scope.baseStampStampedLineColor,
                    "padding": (5 - $scope.baseStampStampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.previewStampedStyle = $scope.stampedStyle;
            } else {
                $scope.stampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
            }
        }

        $scope.checkUnstampedStyle = function () {
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.showBaseStampDesign == true) {
                $scope.unstampedStyle = {
                    "background-color": $scope.baseStampUnstampedFillColor,
                    "border": "solid " + $scope.baseStampUnstampedLineWeight + "px",
                    "border-color": $scope.baseStampUnstampedLineColor,
                    "padding": (5 - $scope.baseStampUnstampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.previweUnstampedStyle = $scope.unstampedStyle;
            } else {
                $scope.unstampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
            }
        }

        $scope.changeStampIconType = function () {
            uniqueStamp.isLoaded = false;
            uniqueUnstamp.isLoaded = false;
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.stampIconType == 1) {
                $scope.loyaltyCardForm.stampImg.$error.need = null
                $scope.loyaltyCardForm.stampImg.$error.need = null
            } else {
                $scope.loyaltyCardForm.templateImgs.$error.need = null
            }
            if ($scope.stampIconType == 1 && ($scope.partnerSubscriptionType == "basic" || $scope.partnerSubscriptionType == "premium") && $scope.selectedTemplateImg
                && $scope.isUnstampedColorEnabled != true && $scope.isStampedColorEnabled != true) {
                //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
            } else if ($scope.stampIconType == 1 && $scope.selectedTemplateImg && ($scope.isUnstampedColorEnabled == true || $scope.isStampedColorEnabled == true)) {
                if ($scope.isUnstampedColorEnabled == true/* && $scope.selectedTemplateImg.unstampIconColored*/) {
                    $scope.shiftColor(false, false)
                    //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.unstampIconColored;
                } else {
                    //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
                }
                if ($scope.isStampedColorEnabled == true/* && $scope.selectedTemplateImg.stampIconColored*/) {
                    $scope.shiftColor(true, false)
                    //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconColored;
                } else {
                    //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                }
            } else if ($scope.stampIconType == 2 && $scope.partnerSubscriptionType == "premium" && ($rootScope.stampCropperData || $rootScope.unstampCropperData) && $scope.isUnstampedColorEnabled != true && $scope.isStampedColorEnabled != true) {
                if ($rootScope.stampCropperData.originalStampImg) {
                    $rootScope.stampCropperData.stampImg = $rootScope.stampCropperData.originalStampImg;
                } else {
                    $rootScope.stampCropperData.stampImg = undefined;
                }
                if ($rootScope.unstampCropperData.originalUnstampImg) {
                    $rootScope.unstampCropperData.unstampImg = $rootScope.unstampCropperData.originalUnstampImg;
                } else {
                    $rootScope.unstampCropperData.unstampImg = undefined;
                }
            } else if ($scope.stampIconType == 2 && $rootScope.stampCropperData && $rootScope.unstampCropperData && ($scope.isUnstampedColorEnabled == true || $scope.isStampedColorEnabled == true)) {
                if ($scope.isUnstampedColorEnabled == true && $rootScope.unstampCropperData.previewUnstampImg) {
                    $rootScope.unstampCropperData.unstampImg = $rootScope.unstampCropperData.previewUnstampImg;
                    $scope.shiftColor(false, false)
                } else {
                    $rootScope.unstampCropperData.unstampImg = $rootScope.unstampCropperData.originalUnstampImg;
                }
                if ($scope.isStampedColorEnabled == true && $rootScope.stampCropperData.previewStampImg) {
                    $rootScope.stampCropperData.stampImg = $rootScope.stampCropperData.previewStampImg;
                    $scope.shiftColor(true, false)
                } else {
                    $rootScope.stampCropperData.stampImg = $rootScope.stampCropperData.originalStampImg;
                }
            } else {
                $rootScope.stampCropperData.stampImg = undefined;
                $rootScope.unstampCropperData.unstampImg = undefined;
            }
        }

        $scope.checkStampIconType = function () {
            $rootScope.isDataSheetModified = true;
            $scope.loyaltyCardForm.templateImgs.$error.need = null
            $scope.loyaltyCardForm.unstampImg.$error.need = null
            $scope.loyaltyCardForm.stampImg.$error.need = null
            if ($scope.showStampIcon == false) {
                //$rootScope.stampCropperData.previewStampImg = $rootScope.stampCropperData.stampImg;
                //$rootScope.unstampCropperData.previewUnstampImg = $rootScope.unstampCropperData.unstampImg;
                //$rootScope.stampCropperData.stampImg = undefined;
                //$rootScope.unstampCropperData.unstampImg = undefined;
                //$scope.stampIconStampedSizing = null;
                //$scope.stampIconUnstampedSizing = null;
            } else {
                if ($scope.stampIconType == 0 || $scope.stampIconType == null) {
                    $scope.stampIconType = 1;
                }
                if ($scope.loyaltyCardData) {
                    if ($scope.loyaltyCardData.loyaltyCardDetalViewData) {
                        if ($scope.loyaltyCardData.loyaltyCardDetalViewData.stampIconStampedSizing != null) {
                            $scope.stampIconStampedSizing = $scope.loyaltyCardData.loyaltyCardDetalViewData.stampIconStampedSizing;
                        } else {
                            $scope.stampIconStampedSizing = 2;
                        }
                        if ($scope.loyaltyCardData.loyaltyCardDetalViewData.stampIconUnstampedSizing != null) {
                            $scope.stampIconUnstampedSizing = $scope.loyaltyCardData.loyaltyCardDetalViewData.stampIconUnstampedSizing;
                        } else {
                            $scope.stampIconUnstampedSizing = 2;
                        }
                    } else {
                        $scope.stampIconStampedSizing = 2;
                        $scope.stampIconUnstampedSizing = 2;
                    }
                } else {
                    $scope.stampIconStampedSizing = 2;
                    $scope.stampIconUnstampedSizing = 2;
                }
                if ($scope.stampIconType == 1 && $scope.selectedTemplateImg && $scope.isUnstampedColorEnabled != true && $scope.isStampedColorEnabled != true) {
                    //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                    //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
                } else if ($scope.stampIconType == 1 && $scope.selectedTemplateImg && ($scope.isUnstampedColorEnabled == true || $scope.isStampedColorEnabled == true)) {
                    if ($scope.isUnstampedColorEnabled == true && $scope.selectedTemplateImg.unstampIconColored) {
                        //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.unstampIconColored;
                    } else {
                        //$rootScope.unstampCropperData.unstampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_unstamped.png";
                    }
                    if ($scope.isStampedColorEnabled == true && $scope.selectedTemplateImg.stampIconColored) {
                        //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconColored;
                    } else {
                        //$rootScope.stampCropperData.stampImg = $scope.selectedTemplateImg.stampIconURLPrefix + "_stamped.png";
                    }
                } else if ($scope.stampIconType == 2 && $rootScope.stampCropperData && $rootScope.unstampCropperData && $scope.isUnstampedColorEnabled != true && $scope.isStampedColorEnabled != true) {
                    if ($rootScope.stampCropperData.originalStampImg && $rootScope.unstampCropperData.originalUnstampImg) {
                        $rootScope.stampCropperData.stampImg = $rootScope.stampCropperData.originalStampImg;
                        $rootScope.unstampCropperData.unstampImg = $rootScope.unstampCropperData.originalUnstampImg;
                    } else {
                        $rootScope.stampCropperData.stampImg = undefined;
                        $rootScope.unstampCropperData.unstampImg = undefined;
                    }
                } else if ($scope.stampIconType == 2 && $rootScope.stampCropperData && $rootScope.unstampCropperData && ($scope.isUnstampedColorEnabled == true || $scope.isStampedColorEnabled == true)) {
                    if ($scope.isUnstampedColorEnabled == true && $rootScope.unstampCropperData.previewUnstampImg) {
                        $rootScope.unstampCropperData.unstampImg = $rootScope.unstampCropperData.previewUnstampImg;
                    } else {
                        $rootScope.unstampCropperData.unstampImg = $rootScope.unstampCropperData.originalUnstampImg;
                    }
                    if ($scope.isStampedColorEnabled == true && $rootScope.stampCropperData.previewStampImg) {
                        $rootScope.stampCropperData.stampImg = $rootScope.stampCropperData.previewStampImg;
                    } else {
                        $rootScope.stampCropperData.stampImg = $rootScope.stampCropperData.originalStampImg;
                    }
                } else {
                    $rootScope.stampCropperData.stampImg = undefined;
                    $rootScope.unstampCropperData.unstampImg = undefined;
                }
            }
        }

        $scope.setListStampSize = (stampType) => {
            if (stampType == 'stamp') {
                $scope.isStampIconLarge = $scope.isStampIconLarge == undefined ? true : !$scope.isStampIconLarge
                const height = $scope.isStampIconLarge ? 80 : 38
                const width = $scope.isStampIconLarge ? 80 : 38
                $('.stampIconSize').css({ 'height': height, 'width': width });
            } else {
                $scope.isUnstampIconLarge = $scope.isUnstampIconLarge == undefined ? true : !$scope.isUnstampIconLarge
                const height = $scope.isUnstampIconLarge ? 80 : 38
                const width = $scope.isUnstampIconLarge ? 80 : 38
                $('.unstampIconSize').css({ 'height': height, 'width': width });
            }
        }

        $scope.setStampIconSize = function (test) {
            $rootScope.isDataSheetModified = true
            if ($scope.stampIconUnstampedSizing == 5) {
                $scope.sizedUnstampStyle = {
                    "width": "35px",
                    "height": "35px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewUnstampStyle = {
                    "width": "45px",
                    "height": "45px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                }
            } else if ($scope.stampIconUnstampedSizing == 4) {
                $scope.sizedUnstampStyle = {
                    "width": "30px",
                    "height": "30px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewUnstampStyle = {
                    "width": "40px",
                    "height": "40px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                }
            } else if ($scope.stampIconUnstampedSizing == 3) {
                $scope.sizedUnstampStyle = {
                    "width": "25px",
                    "height": "25px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewUnstampStyle = {
                    "width": "35px",
                    "height": "35px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                }
            } else if ($scope.stampIconUnstampedSizing == 2) {
                $scope.sizedUnstampStyle = {
                    "width": "20px",
                    "height": "20px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewUnstampStyle = {
                    "width": "30px",
                    "height": "30px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                }
            } else if ($scope.stampIconUnstampedSizing == 1) {
                $scope.sizedUnstampStyle = {
                    "width": "15px",
                    "height": "15px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewUnstampStyle = {
                    "width": "25px",
                    "height": "25px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                }
            }
            if ($scope.stampIconStampedSizing == 5) {
                $scope.sizedStampStyle = {
                    "width": "35px",
                    "height": "35px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewStampStyle = {
                    "width": "45px",
                    "height": "45px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
            } else if ($scope.stampIconStampedSizing == 4) {
                $scope.sizedStampStyle = {
                    "width": "30px",
                    "height": "30px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewStampStyle = {
                    "width": "40px",
                    "height": "40px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
            } else if ($scope.stampIconStampedSizing == 3) {
                $scope.sizedStampStyle = {
                    "width": "25px",
                    "height": "25px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewStampStyle = {
                    "width": "35px",
                    "height": "35px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
            } else if ($scope.stampIconStampedSizing == 2) {
                $scope.sizedStampStyle = {
                    "width": "20px",
                    "height": "20px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewStampStyle = {
                    "width": "30px",
                    "height": "30px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
            } else if ($scope.stampIconStampedSizing == 1) {
                $scope.sizedStampStyle = {
                    "width": "15px",
                    "height": "15px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedPreviewStampStyle = {
                    "width": "25px",
                    "height": "25px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
            }
        }

        $scope.setAllCompStores = function () {
            setAllCompStores();
        };

        $scope.compNamePromoSameAsCo = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isCompNamePromoSameAsCo == true) {
                $scope.compNamePromo = $scope.loyaltyCardData.companyData[0].compNamePromo;
            } else {
                $scope.compNamePromo = undefined;
            }
        }
        $scope.selectedElementToText = function (param) {
            if (param == 'maxNoOfDay') {
                if ($scope.maxNoOfDaysBetweenStamping) {
                    let selectedElement = {};
                    selectedElement = $scope.maxNoOfDaysBetweenStamping;
                    $scope.maxNoOfDaysBetweenStamping = null;
                    setTimeout(function () {
                        $scope.maxNoOfDaysBetweenStampingText = selectedElement.displayText.toString();
                        var element = angular.element("#cardValidityDuration");
                        element.focus();
                    }, 50)
                }
            } else if (param == 'cardValidity') {
                if ($scope.cardValidityDuration) {
                    let selectedElement = {};
                    selectedElement = $scope.cardValidityDuration;
                    $scope.cardValidityDuration = null;
                    setTimeout(function () {
                        $scope.cardValidityDurationText = selectedElement.displayText.toString();
                        var element = angular.element("#maxNoOfDaysBetweenStamping");
                        element.focus();
                    }, 50)
                }
            }
        }

        var modifyLoyaltyCard = function ({ id, isTemplate }) {
            $scope.loyaltyCardId = id;
            LoyaltyCardService.modifyLoyaltyCard(id).then(function (result) {
                if (result.errorCode == 0) {
                    $scope.child.hideLCList()
                    resetVariables();
                    $scope.loyaltyCardData = result;
                    if ($rootScope.partnerSubscriptionType == "custom") {
                        $scope.partnerSubscriptionType = 'premium';
                    } else {
                        $scope.partnerSubscriptionType = $rootScope.partnerSubscriptionType;
                    }
                    $scope.displaySubscriptionType = SubscriptionNames[$rootScope.partnerSubscriptionType]//$cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[4] : '';
                    $scope.loyaltyCardPromoStatusText = $scope.promoStatusText[result.loyaltyCardDetalViewData.loyaltyCardPromoStatus].text;
                    var date = new Date(result.loyaltyCardDetalViewData.lastUpdatedTimestamp);
                    var year = $filter('date')(date, "yyyy");
                    var month = $filter('date')(date, "MM");
                    var day = $filter('date')(date, "dd");
                    var monthInHungarian = getMonth(month);
                    $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";
                    //$scope.buttonClass = "btn";
                    $scope.animateMobile = "yalty-animateTo-mobile";
                    $scope.openVirtual = { "margin-top": "0px" };
                    //$scope.isOpened = "fa fa-chevron-down";
                    //$scope.newButtonClass = "activeBtn";
                    $scope.allCompStores = result.companyStoreData;
                    $scope.compNamePromo = result.loyaltyCardDetalViewData.compNamePromo;
                    $scope.linkedPreviousLoyaltyCardYaltyId = result.loyaltyCardDetalViewData.linkedPreviousLoyaltyCardYaltyId;
                    $scope.isCompNamePromoSameAsCo = result.loyaltyCardDetalViewData.isCompNamePromoSameAsCo;
                    $scope.promoCategories = result.promoCategories;
                    $scope.mainPromoCategory = result.companyData[0].mainPromoCategory;

                    $scope.isEnglishTranslationEnabled = result.companyData[0].isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt'
                    $scope.translationEnabled = result.companyData[0].isTranslation_EN_Enabled
                    $scope.languages = [
                        { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                        {
                            id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                            url: "../imgs/translation_flag_EN.png",
                            windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                        }
                    ]
                    $scope.partnerData = {}
                    $scope.languageSelector = 1
                    $scope['EN'] = {}
                    $scope['EN'].compNamePromo = result.loyaltyCardDetalViewData.compNamePromo_EN
                    $scope['EN'].promotionName = result.loyaltyCardDetalViewData.promotionName_EN
                    $scope['EN'].stampConditionsDescr = result.loyaltyCardDetalViewData.stampConditionsDescr_EN
                    $scope['EN'].reward1RewardDescr = result.loyaltyCardDetalViewData.reward1RewardDescr_EN
                    $scope['EN'].reward2RewardDescr = result.loyaltyCardDetalViewData.reward2RewardDescr_EN
                    $scope['EN'].reward3RewardDescr = result.loyaltyCardDetalViewData.reward3RewardDescr_EN
                    $scope['EN'].reward4RewardDescr = result.loyaltyCardDetalViewData.reward4RewardDescr_EN
                    $scope['EN'].reward5RewardDescr = result.loyaltyCardDetalViewData.reward5RewardDescr_EN
                    $scope['EN'].promotionConditionsDescr = result.loyaltyCardDetalViewData.promotionConditionsDescr_EN
                    $scope['EN'].phoneOrderInfoMessage = result.loyaltyCardDetalViewData.phoneOrderInfoMessage_EN
                    $scope['EN'].onlineOrderInfoMessage = result.loyaltyCardDetalViewData.onlineOrderInfoMessage_EN
                    $scope.translatedCompNamePromo = result.loyaltyCardDetalViewData.compNamePromo_EN
                    $scope.partnerData.translatedCompNamePromo = result.companyData[0].compNamePromo_EN
                    $scope.translatedPromotionName = result.loyaltyCardDetalViewData.promotionName_EN
                    $scope.translatedStampConditionsDescr = result.loyaltyCardDetalViewData.stampConditionsDescr_EN
                    $scope.translatedReward1RewardDescr = result.loyaltyCardDetalViewData.reward1RewardDescr_EN
                    $scope.translatedReward2RewardDescr = result.loyaltyCardDetalViewData.reward2RewardDescr_EN
                    $scope.translatedReward3RewardDescr = result.loyaltyCardDetalViewData.reward3RewardDescr_EN
                    $scope.translatedReward4RewardDescr = result.loyaltyCardDetalViewData.reward4RewardDescr_EN
                    $scope.translatedReward5RewardDescr = result.loyaltyCardDetalViewData.reward5RewardDescr_EN
                    $scope.translatedPromotionConditionsDescr = result.loyaltyCardDetalViewData.promotionConditionsDescr_EN
                    $scope.translatedPhoneOrderInfoMessage = result.loyaltyCardDetalViewData.phoneOrderInfoMessage_EN
                    $scope.translatedOnlineOrderInfoMessage = result.loyaltyCardDetalViewData.onlineOrderInfoMessage_EN

                    $scope.promoCategoryId = result.loyaltyCardDetalViewData.promoCategoryId;
                    if (isTemplate == false) {
                        $scope.loyaltyCardId = result.loyaltyCardDetalViewData.id;
                        $scope.promotionName = result.loyaltyCardDetalViewData.promotionName;
                        $scope.loyaltyCardPromoStatusCode = result.loyaltyCardDetalViewData.loyaltyCardPromoStatus;
                        $rootScope.logoCropperData.compLogoImg = result.loyaltyCardDetalViewData.cardLogoImgURL;
                        $scope.isCardHiddenFromPublic = result.loyaltyCardDetalViewData.isCardHiddenFromPublic;
                        $scope.publiclyHiddenCardRevealCode = result.loyaltyCardDetalViewData.publiclyHiddenCardRevealCode;
                    } else {
                        $scope.loyaltyCardPromoStatusCode = 0;
                        $scope.loyaltyCardId = undefined;

                        var logoImg = new Image();
                        var logoCanvas = document.getElementById("logoCanvas");
                        var logoContext = logoCanvas.getContext("2d");
                        logoImg.src = result.loyaltyCardDetalViewData.cardLogoImgURL;
                        logoImg.onload = function () {
                            logoContext.canvas.height = logoImg.height;
                            logoContext.canvas.width = logoImg.width;
                            logoContext.drawImage(logoImg, 0, 0);
                            const format = result.loyaltyCardDetalViewData.cardLogoImgURL.split('.')[1]
                            const dataURL = logoCanvas.toDataURL(`image/${format}`)
                            $rootScope.logoCropperData.compLogoImg = dataURL
                        }

                        if (result.loyaltyCardDetalViewData.stampIconType == 2) {
                            var stampImg = new Image();
                            var stampCanvas = document.getElementById("stampCanvas");
                            var stampContext = stampCanvas.getContext("2d");
                            stampImg.src = result.loyaltyCardDetalViewData.stampIconURL + "_stamped.png";
                            stampImg.onload = function () {
                                stampContext.canvas.height = stampImg.height;
                                stampContext.canvas.width = stampImg.width;
                                stampContext.drawImage(stampImg, 0, 0);
                                const dataURL = stampCanvas.toDataURL("image/png")
                                $rootScope.stampCropperData.previewStampImg = dataURL
                                $rootScope.stampCropperData.stampImg = dataURL
                                $rootScope.stampCropperData.originalStampImg = dataURL
                            }

                            var unstampImg = new Image();
                            var unstampCanvas = document.getElementById("unstampCanvas");
                            var unstampContext = unstampCanvas.getContext("2d");
                            unstampImg.src = result.loyaltyCardDetalViewData.stampIconURL + "_unstamped.png";
                            unstampImg.onload = function () {
                                unstampContext.canvas.height = unstampImg.height;
                                unstampContext.canvas.width = unstampImg.width;
                                unstampContext.drawImage(unstampImg, 0, 0);
                                const dataURL = unstampCanvas.toDataURL("image/png")
                                $rootScope.unstampCropperData.previewUnstampImg = dataURL
                                $rootScope.unstampCropperData.unstampImg = dataURL
                                $rootScope.unstampCropperData.originalUnstampImg = dataURL
                            }
                        }
                        /*if (result.loyaltyCardDetalViewData.promotionName != null && result.loyaltyCardDetalViewData.promotionName != "") {
                            if ((result.loyaltyCardDetalViewData.promotionName + " (MÁSOLAT)").length <= 60) {*/
                        $scope.promotionName = result.loyaltyCardDetalViewData.promotionName/* + " (MÁSOLAT)";
                            } else {
                                var slice;
                                if (result.loyaltyCardDetalViewData.promotionName.length > 47) {
                                    slice = result.loyaltyCardDetalViewData.promotionName.slice(0, 47);
                                }
                                $scope.promotionName = slice + "... (MÁSOLAT)";
                            }
                        } else {
                            $scope.promotionName = null;
                        }*/
                    }
                    $scope.detailedDisplayBackgroundColor = result.loyaltyCardDetalViewData.detailedDisplayBackgroundColor;
                    $scope.backButtonColor = result.loyaltyCardDetalViewData.backButtonColor;
                    //$scope.coloringIcons($scope.backButtonColor);
                    $scope.cardBackgroundColor = result.loyaltyCardDetalViewData.cardBackgroundColor;
                    $scope.isCardLogoImgURLSameAsCo = result.loyaltyCardDetalViewData.isCardLogoImgURLSameAsCo;
                    $scope.isCardLogoImgSameAsCo = result.loyaltyCardDetalViewData.isCardLogoImgURLSameAsCo;
                    $scope.compNamePromoTextColor = result.loyaltyCardDetalViewData.compNamePromoTextColor;
                    $scope.promotionNameTextColor = result.loyaltyCardDetalViewData.promotionNameTextColor;
                    $scope.stampTextPlaceholderColor = result.loyaltyCardDetalViewData.stampTextPlaceholderColor;
                    $scope.stampCurrentCountTextColor = result.loyaltyCardDetalViewData.stampCurrentCountTextColor;
                    $scope.baseStampUnstampedFillColor = result.loyaltyCardDetalViewData.baseStampUnstampedFillColor;
                    $scope.baseStampUnstampedLineWeight = result.loyaltyCardDetalViewData.baseStampUnstampedLineWeight;
                    $scope.baseStampUnstampedLineColor = result.loyaltyCardDetalViewData.baseStampUnstampedLineColor;
                    $scope.baseStampStampedFillColor = result.loyaltyCardDetalViewData.baseStampStampedFillColor;
                    $scope.baseStampStampedLineWeight = result.loyaltyCardDetalViewData.baseStampStampedLineWeight;
                    $scope.baseStampStampedLineColor = result.loyaltyCardDetalViewData.baseStampStampedLineColor;
                    $scope.showBaseStampDesign = result.loyaltyCardDetalViewData.showBaseStampDesign;
                    //kontrasztot ellenőrző metódus defeault lefutása
                    $scope.checkColorContrastConflitions('cardBackgroundColor', $scope.cardBackgroundColor);
                    $scope.checkColorContrastConflitions('detailedDisplayBackgroundColor', $scope.detailedDisplayBackgroundColor);

                    if ($scope.showBaseStampDesign == false) {
                        $scope.checkStampStyles();
                    } else {
                        $scope.checkStampStyles();
                    }
                    $scope.showStampIcon = result.loyaltyCardDetalViewData.showStampIcon;
                    $scope.stampIconType = result.loyaltyCardDetalViewData.stampIconType;
                    if (result.loyaltyCardDetalViewData.stampIconType == 0) {
                        //$rootScope.unstampCropperData.unstampImg = result.loyaltyCardDetalViewData.stampIconURL;
                        //$rootScope.stampCropperData.stampImg = result.loyaltyCardDetalViewData.stampIconURL;
                    } else if (result.loyaltyCardDetalViewData.stampIconType == 1) {
                        $scope.getStampIconTemplateList('set', result.loyaltyCardDetalViewData.stampIconURL)
                    } else if (!isTemplate && result.loyaltyCardDetalViewData.stampIconType == 2) {
                        $rootScope.unstampCropperData.unstampImg = result.loyaltyCardDetalViewData.stampIconURL + "_unstamped.png";
                        $rootScope.unstampCropperData.originalUnstampImg = result.loyaltyCardDetalViewData.stampIconURL + "_unstamped.png";
                        $rootScope.unstampCropperData.previewUnstampImg = $rootScope.unstampCropperData.unstampImg;
                        $rootScope.stampCropperData.stampImg = result.loyaltyCardDetalViewData.stampIconURL + "_stamped.png";
                        $rootScope.stampCropperData.originalStampImg = result.loyaltyCardDetalViewData.stampIconURL + "_stamped.png";
                        $rootScope.stampCropperData.previewStampImg = $rootScope.stampCropperData.stampImg;
                    }
                    if (result.loyaltyCardDetalViewData.stampIconUnstampedColor) {
                        $scope.isUnstampedColorEnabled = true;
                        $scope.stampIconUnstampedColor = result.loyaltyCardDetalViewData.stampIconUnstampedColor;
                        stampIconUnstampedColor = result.loyaltyCardDetalViewData.stampIconUnstampedColor;
                        $("#stampIconUnstampedColorInUniqueStamp")[0].value = result.loyaltyCardDetalViewData.stampIconUnstampedColor;
                        $('#stampIconUnstampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = result.loyaltyCardDetalViewData.stampIconUnstampedColor;
                    }
                    if (result.loyaltyCardDetalViewData.stampIconStampedColor) {
                        $scope.isStampedColorEnabled = true;
                        $scope.stampIconStampedColor = result.loyaltyCardDetalViewData.stampIconStampedColor;
                        stampIconStampedColor = result.loyaltyCardDetalViewData.stampIconStampedColor;
                        $("#stampIconStampedColorInUniqueStamp")[0].value = result.loyaltyCardDetalViewData.stampIconStampedColor;
                        $('#stampIconStampedColorInUniqueStampContainer span.minicolors-swatch-color')[0].style.backgroundColor = result.loyaltyCardDetalViewData.stampIconStampedColor;
                    }
                    if ($scope.partnerSubscriptionType != "yalty_start_free" && $scope.partnerSubscriptionType != "yalty_start_paid") {
                        $scope.stampIconStampedSizing = result.loyaltyCardDetalViewData.stampIconStampedSizing;
                        $scope.stampIconUnstampedSizing = result.loyaltyCardDetalViewData.stampIconUnstampedSizing;
                        $scope.setStampIconSize();
                    }
                    $scope.stampConditionsDescr = result.loyaltyCardDetalViewData.stampConditionsDescr;
                    $scope.reward1NrOfStampsNeeded = result.loyaltyCardDetalViewData.reward1NrOfStampsNeeded;
                    $scope.reward1RewardDescr = result.loyaltyCardDetalViewData.reward1RewardDescr;
                    $scope.noOfRewards = result.loyaltyCardDetalViewData.noOfRewards;
                    if ($scope.noOfRewards > 1) {
                        $scope.MIN_PECSETELESEK_SZAMA = 1;
                    } else {
                        $scope.MIN_PECSETELESEK_SZAMA = 2;
                    }
                    $scope.reward2NrOfStampsNeeded = result.loyaltyCardDetalViewData.reward2NrOfStampsNeeded;
                    $scope.reward2RewardDescr = result.loyaltyCardDetalViewData.reward2RewardDescr;
                    $scope.reward3NrOfStampsNeeded = result.loyaltyCardDetalViewData.reward3NrOfStampsNeeded;
                    $scope.reward3RewardDescr = result.loyaltyCardDetalViewData.reward3RewardDescr;
                    $scope.reward4NrOfStampsNeeded = result.loyaltyCardDetalViewData.reward4NrOfStampsNeeded;
                    $scope.reward4RewardDescr = result.loyaltyCardDetalViewData.reward4RewardDescr;
                    $scope.reward5NrOfStampsNeeded = result.loyaltyCardDetalViewData.reward5NrOfStampsNeeded;
                    $scope.reward5RewardDescr = result.loyaltyCardDetalViewData.reward5RewardDescr;
                    $scope.canBeRedeemedLater = result.loyaltyCardDetalViewData.canBeRedeemedLater;
                    $scope.redeemCanBePostponed = result.loyaltyCardDetalViewData.redeemCanBePostponed;
                    $scope.mustBeRedeemedLater = result.loyaltyCardDetalViewData.mustBeRedeemedLater;
                    $scope.nrOfRedeemableRewardsAtOnce = result.loyaltyCardDetalViewData.nrOfRedeemableRewardsAtOnce == 0 ? null : result.loyaltyCardDetalViewData.nrOfRedeemableRewardsAtOnce;
                    $scope.nrOfRedeemableRewardsPerRewardTypes = result.loyaltyCardDetalViewData.nrOfRedeemableRewardsPerRewardTypes == 0 ? null : result.loyaltyCardDetalViewData.nrOfRedeemableRewardsPerRewardTypes;
                    if (result.loyaltyCardDetalViewData.nrOfRedeemableRewardsAtOnce == 0) {
                        $scope.isNrOfRedeemableRewardsAtOnceUnlimited = true;
                    } else {
                        $scope.isNrOfRedeemableRewardsAtOnceUnlimited = false;
                    }
                    if (result.loyaltyCardDetalViewData.nrOfRedeemableRewardsPerRewardTypes == 0) {
                        $scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited = true;
                    } else {
                        $scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited = false;
                    }
                    $scope.maxStampNrLimitationAtStamping = result.loyaltyCardDetalViewData.maxStampNrLimitationAtStamping;
                    if (result.loyaltyCardDetalViewData.maxStampNrLimitationAtStamping == null) {
                        $scope.isMaxStampNrLimitationAtStampingUnlimited = true;
                    } else {
                        $scope.isMaxStampNrLimitationAtStampingUnlimited = false;
                    }
                    $scope.isYLCSelectableProductsEnabled = result.loyaltyCardDetalViewData.isYLCSelectableProductsEnabled;
                    if (result.loyaltyCardDetalViewData.YLCSelectableProducts != null) {
                        $scope.YLCSelectableProducts = JSON.parse(result.loyaltyCardDetalViewData.YLCSelectableProducts);
                        if ($scope.YLCSelectableProducts.length > 0) {
                            $scope.originalLists[0].items.splice(0, 1);
                            $scope.lists[0].items.splice(0, 1);
                            for (let i = 0; i < $scope.YLCSelectableProducts.length; i++) {
                                $scope.originalLists[0].items.push({ name: $scope.YLCSelectableProducts[i] });
                                addListItem($scope.YLCSelectableProducts[i]);
                            }
                        }
                    }
                    $scope.setSumNrOfStampsNeeded();
                    if (result.loyaltyCardDetalViewData.maxNoOfDaysBetweenStamping == null) {
                        $scope.maxNoOfDaysBetweenStampingText = $scope.listOfMaxNoOfDaysBetweenStamping[0].displayText;
                    } else {
                        const index = $scope.listOfMaxNoOfDaysBetweenStamping.findIndex(x => x.value == result.loyaltyCardDetalViewData.maxNoOfDaysBetweenStamping);
                        if (index != -1) {
                            $scope.maxNoOfDaysBetweenStampingText = $scope.listOfMaxNoOfDaysBetweenStamping[index].displayText;
                        } else {
                            $scope.maxNoOfDaysBetweenStampingText = result.loyaltyCardDetalViewData.maxNoOfDaysBetweenStamping.toString();
                        }
                    }
                    if (result.loyaltyCardDetalViewData.cardValidityDuration == null) {
                        $scope.cardValidityDurationText = $scope.listOfCardValidityDuration[0].displayText;
                    } else {
                        const index = $scope.listOfCardValidityDuration.findIndex(x => x.value == result.loyaltyCardDetalViewData.cardValidityDuration);
                        if (index != -1) {
                            $scope.cardValidityDurationText = $scope.listOfCardValidityDuration[index].displayText;
                        } else {
                            $scope.cardValidityDurationText = result.loyaltyCardDetalViewData.cardValidityDuration.toString();
                        }
                    }
                    $scope.promotionConditionsDescr = result.loyaltyCardDetalViewData.promotionConditionsDescr;
                    $scope.shiftColor(true, true);
                    $scope.shiftColor(false, true);
                    $scope.isPhoneOrderAvailable = result.loyaltyCardDetalViewData.isPhoneOrderAvailable;
                    $scope.phoneOrderInfoMessage = result.loyaltyCardDetalViewData.phoneOrderInfoMessage;
                    $scope.isOnlineOrderAvailable = result.loyaltyCardDetalViewData.isOnlineOrderAvailable;
                    $scope.onlineOrderInfoMessage = result.loyaltyCardDetalViewData.onlineOrderInfoMessage;
                    if (result.companyData[0].isPartnerRedeemRemarkEnabled == true) {
                        $scope.isYLCPartnerRedeemRemarkEnabled = result.loyaltyCardDetalViewData.isYLCPartnerRedeemRemarkEnabled;
                    }
                    if (result.loyaltyCardDetalViewData.redeemLocationIndicator == "all") {
                        $scope.allRedeemLocationIndicator = "all";
                        $scope.isAllRedeemLocationIndicator = true;
                        setAllCompStores();
                    } else {
                        $scope.allRedeemLocationIndicator = "select";
                        $scope.isAllRedeemLocationIndicator = false;
                        for (var i = 0; i < result.loyaltyCardRedeemLocations.length; i++) {
                            company.push(result.loyaltyCardRedeemLocations[i].companyStoreId);
                        }
                        $scope.companyStores = company;
                    }
                    if ($scope.loyaltyCardPromoStatusCode == 1) {
                        $scope.isBeforeActivation = true;
                        $scope.isActivateLoyaltyCard = true;
                    }
                    for (let i = 1; i < 6; i++) {
                        $scope.setRewardDescr(i, true);
                        $scope.setTranslatedRewardDescr(i, true)
                        $scope.handlingErrorInTranslatedDescrField(i)
                    }
                    if (isTemplate == false) {
                        $rootScope.isDataSheetModified = false;
                    } else {
                        $rootScope.isDataSheetModified = true;
                    }
                    $scope.showLoyaltys = false;
                    $scope.showSetLoyalty = true;
                    $scope.sendButtonDisabled = true;
                } else {
                    var alert = $mdDialog.alert()
                        .title('Sikertelen azonosítás')
                        .textContent('Felhasználó azonosítása sikertelen, ezért ki kell léptetnünk az oldalról.')
                        .ariaLabel('Session expired.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $rootScope.isDataSheetModified = false;
                        $cookies.remove('firstName');
                        $cookies.remove('lastName');
                        $cookies.remove('nameTitle');
                        $cookies.remove('areRedeemPageFilterSettingsPredefined');
                        $cookies.remove('defaultRedeemPageShowFilter');
                        $cookies.remove('defaultRedeemPageCityFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('userEmail');
                        $cookies.remove('userCompany');
                        $cookies.remove('compLogo');
                        $cookies.remove('authorizationLevel');
                        $cookies.remove('currentUserType');
                        $cookies.remove('expdt');
                        $cookies.remove('alrtdt');
                        $cookies.remove('redeemPageShowFilter');
                        $cookies.remove('redeemPageCompanyStoreFilter');
                        $cookies.remove('redeemPageCityFilter');
                        $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
                        $cookies.remove('redeemPageCityFilterCheckAll');
                        $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
                        $cookies.remove('canSendIndividualPushMessages');
                        $cookies.remove('freePackageSubscriptionExpTimestamp');
                        $cookies.remove('isYaltyStartEnabled');
                        $window.localStorage.clear();
                        $rootScope.authorizationLevel = null;
                        $rootScope.$$listeners['timer-tick'] = [];
                        $rootScope.$$listeners['timer-stopped'] = [];
                        $rootScope.$broadcast('timer-stop');
                        $state.go('partner_HU.login');
                    });
                }
            }, function () {

            })
        }

        $scope.modifyLoyaltyCard = function (paramObj) {
            if ($rootScope.isDataSheetModified == false) {
                modifyLoyaltyCard(paramObj);
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    modifyLoyaltyCard(paramObj);
                }, function () {
                });
            }
        }

        $scope.activateOrDeactivateloyaltyCard = function (id, activateOrDeactivate, form) {
            $scope.isActivateLoyaltyCard = true;
            form.$setSubmitted();
            let sumCount;
            /*if ($scope.stopCardIssuingCount > 0) {
                sumCount = ($scope.activeCount + $scope.stopCardIssuingCount) - 1;
            } else {*/
            sumCount = $scope.activeCount;
            //}
            if (activateOrDeactivate == "activate") {
                for (let i = 1; i < 6; i++) {
                    $scope.handlingErrorInDescrField(i)
                    $scope.handlingErrorInTranslatedDescrField(i)
                }
                setTimeout(function () {
                    if (form.$valid) {
                        let stopPreviousCardIssuing = false;
                        let previousLinkedLoyaltyCardPromotionName = null;
                        if ($scope.linkedPreviousLoyaltyCardYaltyId != null) {
                            let loyaltyCard = $scope.allLoyaltyCard.find(obj => {
                                return obj.id === $scope.linkedPreviousLoyaltyCardYaltyId
                            })
                            if (loyaltyCard != undefined) {
                                previousLinkedLoyaltyCardPromotionName = loyaltyCard.promotionName;
                                if (loyaltyCard.loyaltyCardPromoStatus == 1) {
                                    stopPreviousCardIssuing = true;
                                }
                            }
                        }
                        if ($rootScope.stampCropperData.stampImg == null && $rootScope.logoCropperData.compLogoImg == null && $rootScope.unstampCropperData.unstampImg == null && $scope.stampIconType == 2 && $scope.showStampIcon == true) {
                            form.stampImg.$error.need = true;
                            form.logoImage.$error.need = true;
                            form.unstampImg.$error.need = true;
                            //$('.main').animate({ scrollTop: $('div#logoImage').offset().top - 150 }, "slow");
                            const element = document.getElementById("logoImage")
                            element.scrollIntoView();
                        } else if ($rootScope.stampCropperData.stampImg == null && $scope.stampIconType == 2 && $scope.partnerSubscriptionType == "premium" && $scope.showStampIcon == true) {
                            form.stampImg.$error.need = true;
                            form.templateImgs.$error.need = null;
                            //$('.main').animate({ scrollTop: $('div#stampImg').offset().top - 150 }, "slow");
                            const element = document.getElementById("showStamps")
                            element.scrollIntoView();
                        } else if ($scope.stampIconType == 1 && $scope.showStampIcon && !$scope.selectedTemplateImg) {
                            form.templateImgs.$error.need = true;
                            form.stampImg.$error.need = null;
                            form.unstampImg.$error.need = null;
                            //$('.main').animate({ scrollTop: $('div#templateImgs').offset().top - 150 }, "slow");
                            const element = document.getElementById("showStamps")
                            element.scrollIntoView();
                        } else if ($rootScope.logoCropperData.compLogoImg == null) {
                            form.logoImage.$error.need = true;
                            //$('.main').animate({ scrollTop: $('div#logoImage').offset().top - 150 }, "slow");
                            const element = document.getElementById("logoImgContainer")
                            element.scrollIntoView();
                        } else if ($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithStampCounterColor || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor || $scope.isCardBackgroundColorConflictedWithStampFixColor || $scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) {
                            var alert = $mdDialog.alert()
                                .title("Kis kontraszt - túl közeli színárnyalatok")
                                .htmlContent("A kártya megfelelő megjelenítéséhez és kényelmes használatához fontos, hogy a különböző elemek színei megfelelő kontrasztban legyenek egymással.<br><br>Jelenleg egy vagy több ponton a kártya elemeinek színei túl közel vannak egymáshoz. Kérjük adjon meg ezeknél markánsabban különböző színárnyalatokat.")
                                .ok('Javítás')
                            $mdDialog.show(alert).then(function () {
                                let contrastRatioError = "";
                                if ($scope.isCardBackgroundColorConflictedWithDiscountName || $scope.isCardBackgroundColorConflictedWithPromotionCompanyName || $scope.isCardBackgroundColorConflictedWithStampCounterColor || $scope.isCardBackgroundColorConflictedWithDetailedDisplayBackgroundColor || $scope.isCardBackgroundColorConflictedWithStampFixColor) {
                                    contrastRatioError = "cardContrastRatioError"
                                } else if ($scope.isDetailedDisplayBackgroundColorConflictedWithBackAndFavoritesButtons) contrastRatioError = "detailedDisplayContrastRatioError";
                                const element = document.getElementById(contrastRatioError);
                                element.scrollIntoView();
                            });
                        } else if ($scope.redeemCanBePostponed == 0 && $scope.isYLCSelectableProductsEnabled == true) {
                            var alert = $mdDialog.alert()
                                .title("Sikertelen aktiválás")
                                .textContent("Az 'Azonnali jutalom beváltás' opció a 'A beváltás következő alkalomra használhatósága' mezőben kiválasztott és a 'Pecsételési/Beváltási tételek' funkció nem lehet egyszerre bekapcsolt!")
                                .ok('Javítás')
                            $mdDialog.show(alert).then(function () {
                                const element = document.getElementById("redeemCanBePostponed")
                                element.scrollIntoView();
                            });
                        } else if ($rootScope.unstampCropperData.unstampImg == null && $scope.stampIconType == 2 && $scope.partnerSubscriptionType == "premium" && $scope.showStampIcon == true) {
                            form.unstampImg.$error.need = true;
                            //$('.main').animate({ scrollTop: $('div#unstampImg').offset().top - 150 }, "slow");
                            const element = document.getElementById("unstampImg")
                            element.scrollIntoView();
                        } else if ($scope.showStampIcon == 0 && $scope.showBaseStampDesign == 0) {
                            var alert = $mdDialog.alert()
                                .title("Érvénytelen mezőértékek")
                                .textContent("'Pecsét alap megjelenítése' vagy 'Pecsét kép megjelenítése' mezőértékek szükségesek.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                //$('.main').animate({ scrollTop: $('div#showBaseStampDesign').offset().top - 150 }, "slow");
                                var element = document.getElementById("stampsview")
                                element.scrollIntoView();
                            });
                        } else if (loyaltyCardCount != 0 && (sumCount >= loyaltyCardCount) && stopPreviousCardIssuing == false) {
                            $mdDialog.show({
                                template:
                                    '<md-dialog>' +
                                    '  <md-dialog-content class="md-dialog-content">' +
                                    'Elérte a jelenlegi előfizetési csomagja (<b>{{partnerSubscriptionName}}' +
                                    '</b>) szerinti aktiválható promóciók maximális számát ({{count}}' +
                                    ' db futó promóció). Futtassa vagy törölje ki az egyik futó promóciót ' +
                                    'vagy váltson magasabb előfizetési csomagra. <a ng-click="navigate()">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>' +
                                    '  </md-dialog-content>' +
                                    '  <md-dialog-actions>' +
                                    '    <md-button ng-click="closeDialog()" class="md-primary">' +
                                    '      Rendben' +
                                    '    </md-button>' +
                                    '  </md-dialog-actions>' +
                                    '</md-dialog>',
                                locals: {
                                    partnerSubscriptionName: SubscriptionNames[$rootScope.partnerSubscriptionType],
                                    partnerSubscriptionType: $rootScope.partnerSubscriptionType,
                                    count: loyaltyCardCount
                                },
                                controller: DialogCtrl
                            });
                        } else if (($scope.promotionName) && ($scope.stampConditionsDescr) && ($scope.maxStampNrLimitationAtStamping != null || $scope.isMaxStampNrLimitationAtStampingUnlimited == true) && ( ($scope.canBeRedeemedLater == false && $scope.redeemCanBePostponed !=null) || ($scope.canBeRedeemedLater == true && $scope.mustBeRedeemedLater != null && ($scope.nrOfRedeemableRewardsAtOnce != null || $scope.isNrOfRedeemableRewardsAtOnceUnlimited == true) && ($scope.nrOfRedeemableRewardsPerRewardTypes != null || $scope.isNrOfRedeemableRewardsPerRewardTypesUnlimited == true || $scope.noOfRewards == 1))) &&
                            (($scope.partnerSubscriptionType == "basic" && $scope.noOfRewards == 1 && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr) ||
                                ($scope.partnerSubscriptionType == "yalty_start_free" && $scope.noOfRewards == 1 && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr) ||
                                ($scope.partnerSubscriptionType == "yalty_start_paid" && $scope.noOfRewards == 1 && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr) ||
                                ($scope.partnerSubscriptionType == "premium" && $scope.noOfRewards == 1 && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr) ||
                                ($scope.partnerSubscriptionType == "premium" && $scope.noOfRewards == 2 && $scope.reward2NrOfStampsNeeded && $scope.reward2RewardDescr && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr) ||
                                ($scope.partnerSubscriptionType == "premium" && $scope.noOfRewards == 3 && $scope.reward3NrOfStampsNeeded && $scope.reward3RewardDescr && $scope.reward2NrOfStampsNeeded && $scope.reward2RewardDescr && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr) ||
                                ($scope.partnerSubscriptionType == "premium" && $scope.noOfRewards == 4 && $scope.reward4NrOfStampsNeeded && $scope.reward4RewardDescr && $scope.reward3NrOfStampsNeeded && $scope.reward3RewardDescr && $scope.reward2NrOfStampsNeeded && $scope.reward2RewardDescr && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr) ||
                                ($scope.partnerSubscriptionType == "premium" && $scope.noOfRewards == 5 && $scope.reward5NrOfStampsNeeded && $scope.reward5RewardDescr && $scope.reward4NrOfStampsNeeded && $scope.reward4RewardDescr && $scope.reward3NrOfStampsNeeded && $scope.reward3RewardDescr && $scope.reward2NrOfStampsNeeded && $scope.reward2RewardDescr && $scope.reward1NrOfStampsNeeded && $scope.reward1RewardDescr)) &&
                            ($scope.companyStores.length > 0 && $scope.allRedeemLocationIndicator)) {
                            let htmlContent = "Aktiválás esetén a hűségkártyát élesítjük vagyis a felhasználók számára elérhetővé tesszük. <br>" +
                                "<br>" +
                                "A kártya sikeres aktiváció után már megjelenik a mobil applikációban, ott ellenőrizni tudja a kívánt végleges megjelenést. <br>";
                            if (previousLinkedLoyaltyCardPromotionName != null) {
                                htmlContent += "<br><b>Összekapcsolt kártya: “" + previousLinkedLoyaltyCardPromotionName + "”.</b>"
                                if (stopPreviousCardIssuing == true) {
                                    htmlContent += "<b> Ezt a kártyát most az új kártya aktiválásakor automatikusan kifuttatjuk.</b><br>"
                                } else {
                                    htmlContent += "<br>";
                                }
                            }
                            htmlContent += "<br>Megjegyzés: ha a Yalty applikáció már nyitva volt a telefonján, akkor egy kis időnek el kell telnie mire az alkalmazás újra " +
                                "megnyitásakor az új kártyát magától frissíti. Ilyenkor kezdeményezheti a frissítést standard módon úgy is, ha bármelyik lista " +
                                "esetén az alkalmazásban (hűségkártyák, partnerek listája stb.) a lista tetejét tovább húzza lefelé. Illetve, ha az " +
                                "alkalmazást teljesen lecsukja és újraindítja, az olyankor is automatikusan frissül."
                            var confirm = $mdDialog.confirm()
                                .title('Aktiválás megerősítése')
                                .htmlContent(htmlContent)
                                .ok('Aktiválás')
                                .cancel('Mégsem');
                            $mdDialog.show(confirm).then(function () {
                                $scope.sendButtonDisabled = true;
                                $scope.activationInProgress = true;
                                addOrUpdateLoyaltyCard(form, true).then(function () {
                                    id = $scope.loyaltyCardId;
                                    LoyaltyCardService.activateOrDeactivateLoyaltyCard(id, activateOrDeactivate, stopPreviousCardIssuing).then(function (result) {
                                        $scope.activationInProgress = false;
                                        if (result.errorCode == 0) {
                                            let alert;
                                            let subscriptionType = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[0] : '';
                                            let subscriptionEndTimestamp = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[6] : ''
                                            let sbcrtyp = "";
                                            if (subscriptionType == 'yalty_start_free' && subscriptionEndTimestamp != '9999-12-31T23:59:59.997Z') {
                                                for (let i = 0; i < 6; i++) {
                                                    sbcrtyp += $cookies.get('sbcrtyp').split('&')[i];
                                                    sbcrtyp += "&";
                                                }
                                                sbcrtyp += "9999-12-31T23:59:59.997Z";
                                                $cookies.put('sbcrtyp', sbcrtyp);
                                            }
                                            if (stopPreviousCardIssuing == true && $scope.linkedPreviousLoyaltyCardYaltyId != null) {
                                                LoyaltyCardService.activateOrDeactivateLoyaltyCard($scope.linkedPreviousLoyaltyCardYaltyId, "stopNewCardIssuing").then(function (result) {
                                                    alert = $mdDialog.alert()
                                                        .title("Sikeres aktiváció")
                                                        .textContent("A hűségkártya aktiválása sikeres.")
                                                        .ok('Rendben')
                                                    $mdDialog.show(alert).then(function () {
                                                        $rootScope.isDataSheetModified = false;
                                                        $scope.sendButtonDisabled = false;
                                                        $state.reload();
                                                    });
                                                }, function () {
                                                    $scope.sendButtonDisabled = false;
                                                    alert = $mdDialog.alert()
                                                        .title("Sikeres aktiváció")
                                                        .textContent("A hűségkártya aktiválása sikeres.")
                                                        .ok('Rendben')
                                                    $mdDialog.show(alert).then(function () {
                                                        $rootScope.isDataSheetModified = false;
                                                        $scope.sendButtonDisabled = false;
                                                        $state.reload();
                                                    });
                                                });
                                            } else {
                                                alert = $mdDialog.alert()
                                                    .title("Sikeres aktiváció")
                                                    .textContent("A hűségkártya aktiválása sikeres.")
                                                    .ok('Rendben')
                                                $mdDialog.show(alert).then(function () {
                                                    $rootScope.isDataSheetModified = false;
                                                    $scope.sendButtonDisabled = false;
                                                    $state.reload();
                                                });
                                            }
                                        } else if (result.errorCode == 1) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = false;
                                            });
                                        } else if (result.errorCode == 2) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $rootScope.isDataSheetModified = false;
                                                $scope.sendButtonDisabled = false;
                                                $state.go('partner_HU');
                                            });
                                        } else if (result.errorCode == 3) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("A hűségkártya nincsen 'még nem prómótált' státuszban.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = false;
                                            });
                                        } else if (result.errorCode == 4) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("A hűségkártya nincsen 'futó promóció' státuszban.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = false;
                                            });
                                        } else if (result.errorCode == 5) {
                                            const alert = $mdDialog.alert()
                                                .title("Sikertelen művelet")
                                                .textContent("Az aktiváláshoz nincsen a megfelelő előfizetési státuszban!")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = false;
                                            });
                                        } else if (result.errorCode == 6) {
                                            $mdDialog.show({
                                                template:
                                                    '<md-dialog>' +
                                                    '  <md-dialog-content class="md-dialog-content">' +
                                                    'Elérte a jelenlegi előfizetési csomagja (<b>{{partnerSubscriptionName}}' +
                                                    '</b>) szerinti aktiválható promóciók maximális számát ({{count}}' +
                                                    ' db futó promóció). Futtassa vagy törölje ki az egyik futó promóciót ' +
                                                    'vagy váltson magasabb előfizetési csomagra. <a ng-click="navigate()">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>' +
                                                    '  </md-dialog-content>' +
                                                    '  <md-dialog-actions>' +
                                                    '    <md-button ng-click="closeDialog()" class="md-primary">' +
                                                    '      Rendben' +
                                                    '    </md-button>' +
                                                    '  </md-dialog-actions>' +
                                                    '</md-dialog>',
                                                locals: {
                                                    partnerSubscriptionType: $rootScope.partnerSubscriptionType,
                                                    partnerSubscriptionName: SubscriptionNames[$rootScope.partnerSubscriptionType],
                                                    count: loyaltyCardCount
                                                },
                                                controller: DialogCtrl
                                            });
                                        } else if (result.errorCode == 7) {
                                            const alert = $mdDialog.alert()
                                                .title("FIGYELEM: A promóciót nem aktiváltuk!")
                                                .textContent("A promócióhoz nem tartozik olyan üzlet, amely aktív Yalty városhoz tartozik. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                                    + "Állítsa be a promócióhoz tartozó üzlet adatlapján a Yalty város mezőt és ezután aktiválja újra a promóciót.\n\n"
                                                    + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $scope.sendButtonDisabled = true;
                                                $rootScope.isDataSheetModified = false;
                                            });
                                        } else if (result.errorCode == 8) {
                                            const alert = $mdDialog.alert()
                                                .title("FIGYELEM: A promóciót aktiváltuk, de egy vagy több, a promócióhoz tartozó üzlet városához nem fog megjelenni!")
                                                .textContent("A promócióhoz tartozik egy vagy több olyan üzlet, amely nem köthető Yalty városhoz. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                                    + "Állítsa be a Yalty város mezőt a promócióhoz tartozó ezen üzlet(ek) adatlapján.\n\n"
                                                    + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () {
                                                $rootScope.isDataSheetModified = false;
                                                $scope.sendButtonDisabled = false;
                                                $state.reload();
                                            });
                                        }
                                    }, function (result) {
                                        $scope.activationInProgress = false;
                                        $scope.sendButtonDisabled = false;
                                    })
                                }, function () {
                                    $scope.activationInProgress = false;
                                    $scope.sendButtonDisabled = false;
                                });
                            }, function (result) {
                                $scope.sendButtonDisabled = false;
                                if (result != false) {
                                    if (result == 1) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    } else if (result == 2) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $rootScope.isDataSheetModified = false;
                                            $scope.sendButtonDisabled = false;
                                            $state.go('partner_HU');
                                        });
                                    } else if (result == 3) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Hűségkártya státusza miatt nem módosítható.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    } else if (result == 4) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Az mentéshez nincsen a megfelelő előfizetési státuszban!")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    } else if (result == 5) {
                                        const alert = $mdDialog.alert()
                                            .title("Sikertelen művelet")
                                            .textContent("Az aktiváláshoz legalább 1 beváltóhelyet ki kell választani.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = false;
                                        });
                                    }
                                }
                            })
                        } else {
                            if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                                angular.element($document[0].querySelector('input.ng-invalid')).focus();
                            } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                                angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                            } else if (angular.element($document[0].querySelector('textarea.ng-invalid')).length > 0) {
                                angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                            } else {
                                console.log("hidden error")
                            }
                        }
                    } else {

                        let errorInEnglishTranslation = null
                        if ($scope.translationEnabled) {
                            if (!form.translatedCompNamePromo.$valid) {
                                errorInEnglishTranslation = 'translatedCompNamePromo'
                            } else if (!form.translatedPromotionName.$valid) {
                                errorInEnglishTranslation = 'translatedPromotionName'
                            } else if (!form.translatedStampConditionsDescr.$valid) {
                                errorInEnglishTranslation = 'translatedStampConditionsDescr'
                            } else if (!form.translatedReward1RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(1) && $scope.handlingErrorInTranslatedDescrField(1))) {
                                errorInEnglishTranslation = 'translatedReward1RewardDescr'
                            } else if (!form.translatedReward2RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(2) && $scope.handlingErrorInTranslatedDescrField(2))) {
                                errorInEnglishTranslation = 'translatedReward2RewardDescr'
                            } else if (!form.translatedReward3RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(3) && $scope.handlingErrorInTranslatedDescrField(3))) {
                                errorInEnglishTranslation = 'translatedReward3RewardDescr'
                            } else if (!form.translatedReward4RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(4) && $scope.handlingErrorInTranslatedDescrField(4))) {
                                errorInEnglishTranslation = 'translatedReward4RewardDescr'
                            } else if (!form.translatedReward5RewardDescr.$valid || (!$scope.getTranslatedRewardDescrWithoutPrefix(5) && $scope.handlingErrorInTranslatedDescrField(5))) {
                                errorInEnglishTranslation = 'translatedReward5RewardDescr'
                            } else if (!form.translatedPromotionConditionsDescr.$valid) {
                                errorInEnglishTranslation = 'translatedPromotionConditionsDescr'
                            } else if (!form.translatedPhoneOrderInfoMessage.$valid) {
                                errorInEnglishTranslation = 'translatedPhoneOrderInfoMessage'
                            } else if (!form.translatedOnlineOrderInfoMessage.$valid) {
                                errorInEnglishTranslation = 'translatedOnlineOrderInfoMessage'
                            }
                        }
                        if (errorInEnglishTranslation) {
                            $scope.langName = 'angol'
                            //$scope.selectorError = true
                            $scope.loyaltyCardForm.languageSelector.$touched = true
                            $scope.loyaltyCardForm.languageSelector.$valid = false
                            $scope.loyaltyCardForm.languageSelector.$invalid = true
                            $scope.loyaltyCardForm.languageSelector.$error = { required: true }
                            //$scope.languageSelectorError = true
                            document.getElementById('languageSelector').scrollIntoView()
                        }

                        let invalidRewardDescrFields = []
                        let element

                        if ($scope.loyaltyCardForm.reward1RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(1) && $scope.handlingErrorInDescrField(1))) {
                            invalidRewardDescrFields.push('reward1RewardDescr')
                            element = element || document.getElementById("reward1RewardDescr")
                        } else if ($scope.loyaltyCardForm.reward2RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(2) && $scope.handlingErrorInDescrField(2))) {
                            invalidRewardDescrFields.push('reward2RewardDescr')
                            element = element || document.getElementById("reward2RewardDescr")
                        } else if ($scope.loyaltyCardForm.reward3RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(3) && $scope.handlingErrorInDescrField(3))) {
                            invalidRewardDescrFields.push('reward3RewardDescr')
                            element = element || document.getElementById("reward3RewardDescr")
                        } else if ($scope.loyaltyCardForm.reward4RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(4) && $scope.handlingErrorInDescrField(4))) {
                            invalidRewardDescrFields.push('reward4RewardDescr')
                            element = element || document.getElementById("reward4RewardDescr")
                        } else if ($scope.loyaltyCardForm.reward5RewardDescr.$invalid || (!$scope.getRewardDescrWithoutPrefix(5) && $scope.handlingErrorInDescrField(5))) {
                            invalidRewardDescrFields.push('reward5RewardDescr')
                            element = element || document.getElementById("reward5RewardDescr")
                        }
                        if (invalidRewardDescrFields.length > 0) {
                            for (const rewardField of invalidRewardDescrFields) {
                                $scope.loyaltyCardForm[rewardField].$touched = true
                                $scope.loyaltyCardForm[rewardField].$valid = false
                                $scope.loyaltyCardForm[rewardField].$invalid = true
                            }
                            element.scrollIntoView();
                            return
                        }

                        if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                            angular.element($document[0].querySelector('input.ng-invalid')).focus();
                        } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {

                            angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                        } else if (angular.element($document[0].querySelector('textarea.ng-invalid')).length > 0) {
                            angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                        } else {
                            console.log('hidden error');
                        }

                    }
                }, 500);
            } else if (activateOrDeactivate == "stopNewCardIssuing") {
                const confirm = $mdDialog.confirm()
                    .title('Kifuttatás megerősítése')
                    .htmlContent('<div style="width: 600px;">'
                        + '<p>A promóció kifuttatása esetén a hűségkártyát új felhasználók már nem tudják megkezdeni. ' +
                        'A felhasználói eszközökről a következő app adatfrissítés (pl. app indítás) alkalmával a hűségkártya törlésre kerül.<br><br>' +
                        'Azon felhasználóink, akik már elkezdték használni a kártyát és legalább egy pecsétet gyűjtöttek is rá, ' +
                        'a kártyájuk érvényességén belül tovább használhatják azt,ők nem tapasztalnak majd semmilyen változást. <br><br>' +
                        'Azon felhasználóink részére viszont, akik elmentették ugyan a kártyát, de még nem pecsételtek rá, ' +
                        'üzenetet jelenítünk meg a kifuttatásról - a továbbiakban ők már nem tudják felhasználni a kártyát.<br><br>' +
                        'FONTOS: amennyiben ehelyett a kártya helyett új kártyát hoz létre ennek folytatásaként, akkor javasoljuk, hogy a külön kifuttatás ' +
                        'helyett az új kártya részletes lapjának alján található “Összekapcsolás előző kártyával” mezőben kösse hozzá ezt a kártyát az új kártyához. ' +
                        'Ilyenkor az új kártya aktiválásával egyidőben ezt a régi, még futó promóciót a rendszer automatikusan kifuttatja. Így a két kártya üzemileg folyamatosan, ' +
                        'egyidőben váltja/egészíti ki egymást.</p></div>')
                    .ariaLabel('Kifuttatás megerősítése')
                    .ok('Kifuttatás')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $scope.sendButtonDisabled = true;
                    /*if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }*/
                    $scope.stopNewCardIssuingInProgress = true;
                    LoyaltyCardService.activateOrDeactivateLoyaltyCard(id, activateOrDeactivate).then(function (result) {
                        $scope.stopNewCardIssuingInProgress = false;
                        /*if (document.getElementById('loadingOverlay')) {
                            document.getElementById('loadingOverlay').style.display = "none";
                            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                        }*/
                        if (result.errorCode == 0) {
                            const alert = $mdDialog.alert()
                                .title("Sikeres kifuttatás")
                                .textContent("A hűségkártya promóciójának kifuttatás sikeres.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.reload();
                            });
                        } else if (result.errorCode == 1) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 2) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.go('partner_HU');
                            });
                        } else if (result.errorCode == 3) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A hűségkártya nincsen 'még nem prómótált' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 4) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A hűségkártya nincsen 'futó promóció' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 5) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Az aktiváláshoz nincsen a megfelelő előfizetési státuszban!")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        }
                    }, function () {
                        $scope.sendButtonDisabled = false;
                        $scope.stopNewCardIssuingInProgress = false;
                    });
                }, function () { });
            } else {
                const confirm = $mdDialog.confirm()
                    .title('Törlés megerősítése')
                    .htmlContent('<div><p>A promóció törlése esetén a hűségkártya megjelenését és további használatát visszavonjuk. ' +
                        'A felhasználói eszközökről a következő app adatfrissítés (pl. app indítás) alkalmával a hűségkártya törlésre kerül.<br><br>' +
                        'Azon felhasználóinknak, akiknek van nyitott, használatban lévő kártyája vagy akár csak elmentették ' +
                        'a kártyát, üzenetet jelenítünk meg a törlésről. <br><br>' +
                        'A törlés után már EGYIK FELHASZNÁLÓNK SEM tudja majd felhasználni a kártyát - ezért az esetek többségében a TÖRLÉS HELYETT A KIFUTTATÁST JAVASOLJUK!<br><br>' +
                        'FONTOS: amennyiben törölné a kártyát és ehelyett a kártya helyett új kártyát hoz létre ennek folytatásaként, akkor javasoljuk, hogy az új kártya részletes ' +
                        'lapjának alján található “Összekapcsolás előző kártyával” mezőben kösse hozzá ezt a kártyát az új kártyához. Ilyenkor a rendszer a két kártyát egymás ' +
                        'folytatásaként kezeli. Ennek előnyeiről kérjük olvassa el az “Összekapcsolás előző kártyával” mezőhöz tartozó infókat.</p></div>')
                    .ariaLabel('Törlés megerősítése')
                    .ok('Törlés')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $scope.sendButtonDisabled = true;
                    /*if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }*/
                    $scope.deactivationInProgress = true;
                    LoyaltyCardService.activateOrDeactivateLoyaltyCard(id, activateOrDeactivate).then(function (result) {
                        /*if (document.getElementById('loadingOverlay')) {
                            document.getElementById('loadingOverlay').style.display = "none";
                            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                        }*/
                        $scope.deactivationInProgress = false;
                        if (result.errorCode == 0) {
                            const alert = $mdDialog.alert()
                                .title("A hűségkártya promóciót sikeresen töröltük.")
                                .htmlContent('<div><p>Kérjük tájékoztassa ügyfeleit, ' +
                                    'hogy a kártyát a továbbiakban már nem tudják majd használni.<br><br> ' +
                                    'Ha most hozott létre új kártyát is egyben a régi, törölt helyett, akkor a tájékoztatással ' +
                                    'együtt promóciózza számukra ezt az új kártyát is.</p></div>')
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.reload();
                            });
                        } else if (result.errorCode == 1) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 2) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.go('partner_HU');
                            });
                        } else if (result.errorCode == 3) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A hűségkártya nincsen 'még nem prómótált' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 4) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A hűségkártya nincsen 'futó promóció' státuszban.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.errorCode == 5) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Az aktiváláshoz nincsen a megfelelő előfizetési státuszban!")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                            });
                        }
                    }, function (result) {
                        $scope.deactivationInProgress = false;
                        $scope.sendButtonDisabled = false;
                    })
                }, function () { })
            }
        }

        $scope.insertTemplateText = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.promotionConditionsDescr) {
                var confirm = $mdDialog.confirm({
                    controller: DialogController,
                    templateUrl: 'promotionConditionConfirm.html'
                })
                $mdDialog.show(confirm).then(function (result) {
                    if (result == "copyText") {
                        $scope.promotionConditionsDescr = $scope.promotionConditionsDescr + "\n\n" + $scope.promotionConditionsDescrTemplate;
                    } else if (result == "overwrite") {
                        $scope.promotionConditionsDescr = $scope.promotionConditionsDescrTemplate;
                    }
                }, function () { });
            } else {
                $scope.promotionConditionsDescr = $scope.promotionConditionsDescrTemplate;
            }
        }

        $scope.insertTemplateTextEN = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.translatedPromotionConditionsDescr) {
                var confirm = $mdDialog.confirm({
                    controller: DialogController,
                    templateUrl: 'promotionConditionConfirm.html'
                })
                $mdDialog.show(confirm).then(function (result) {
                    if (result == "copyText") {
                        $scope.translatedPromotionConditionsDescr = $scope.translatedPromotionConditionsDescr + "\n\n" + $scope.promotionConditionsDescrTemplateEN;
                    } else if (result == "overwrite") {
                        $scope.translatedPromotionConditionsDescr = $scope.promotionConditionsDescrTemplateEN;
                    }
                }, function () { });
            } else {
                $scope.translatedPromotionConditionsDescr = $scope.promotionConditionsDescrTemplateEN;
            }
        }


        function DialogController($scope, $mdDialog) {
            $scope.cancelPromotionConditionConfirm = function () {
                $mdDialog.cancel("cancel");
            }
            $scope.insertPromotionConditionText = function () {
                $mdDialog.hide("overwrite");
            }
            $scope.copyTextEndOfPromotionCondition = function () {
                $mdDialog.hide("copyText");
            }
        }

        $scope.removeLoyaltyCard = function (ev) {
            var title = $scope.isActivateLoyaltyCard ? 'A hűségkártyához futó promóció tartozik. A hűségkártya törlésével a promóció is törlődik. A hűségkártyát jelenleg is használó felhasználók törlés után már nem tudják tovább használni kártyáikat vagy beváltani a kedvezményeiket. Biztosan törli a kártyát?'
                : 'Biztosan törli a kártyát?'
            var confirm = $mdDialog.confirm()
                .title(title)
                .ariaLabel('Törlés megerősítése')
                .targetEvent(ev)
                .ok('Igen')
                .cancel('Mégsem');

            $mdDialog.show(confirm).then(function () {
                if ($scope.loyaltyCardId) {
                    /*if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }*/
                    $scope.removeInProgress = true;
                    if ($rootScope.isDataSheetModified == true) {
                        $rootScope.isDataSheetModified = false;
                    }
                    var loyaltyCard = {};
                    loyaltyCard.loyaltyCardId = $scope.loyaltyCardId;
                    $http.post(API_PREFIX.url + '/loyaltyCard/removeLoyaltyCardStamp_v2', loyaltyCard).then(function (result) {
                        $scope.removeInProgress = false;
                        if (result.data.errorCode == 0) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_törlés_sikeres",
                                "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya törlése sikeres")
                            /*if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }*/
                            const alert = $mdDialog.alert()
                                .title("Sikeres művelet")
                                .textContent("Sikeres hűségkártya törlés.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.reload();
                            });
                        } else if (result.data.errorCode == 1) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_törlés_sikertelen",
                                "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya törlése sikertelen")
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 2) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_törlés_sikertelen",
                                "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya törlése sikertelen")
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                $scope.sendButtonDisabled = false;
                                $state.go('partner_HU');
                            });
                        }
                    }, function () {
                        $scope.removeInProgress = false;
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_törlés_sikertelen",
                            "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya törlése sikertelen")
                    });
                }
            }, function () {
            });
        }

        $scope.getLoyaltys = function () {
            if ($scope.showLoyaltys) {
                //$scope.buttonClass = "btn";
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.showLoyaltys = false;
                $scope.openVirtual = { "margin-top": "0px" };
                //$scope.isOpened = "fa fa-chevron-down";
            } else {
                //$scope.buttonClass = "activeBtn";
                $scope.animateMobile = "yalty-animateFrom-mobile"
                $scope.showLoyaltys = true;
                $scope.openVirtual = { "margin-top": "-" + (virtualSize) + "px" };
                //$scope.openVirtual = { "margin-top": "0px" };
                //$scope.isOpened = "fa fa-chevron-up";
            }
        }

        $scope.setRewardDescr = function (rewardIndex, isInit) {
            const rewardDescrField = `reward${rewardIndex}RewardDescr`
            const prefix = `${$scope[`reward${rewardIndex}NrOfStampsNeeded`] || '? darab'} pecsét összegyűjtése esetén `
            const description = ($scope[rewardDescrField] || '').trim();
            $scope[`reward${rewardIndex}DescrMaxLength`] = $scope[`reward${rewardIndex}NrOfStampsNeeded`] >= 10 ? 231 : 230;
            $scope[`translatedReward${rewardIndex}DescrMaxLength`] = $scope[`reward${rewardIndex}NrOfStampsNeeded`] >= 10 ? 232 : 231;
            if (isInit) {
                $scope[rewardDescrField] = prefix + ($scope[rewardDescrField] || '')
                return
            }
            if ($scope[rewardDescrField] && $scope[rewardDescrField].split(prefix)[1]) {
                $scope[`reward${rewardIndex}DescrMinLength`] = 0;
                const descriptionPart = description.split(prefix)[1]
                $scope[rewardDescrField] = prefix + descriptionPart
            } else {
                $scope[rewardDescrField] = prefix
                $scope.handlingErrorInDescrField(rewardIndex)
            }
        }

        $scope.setTranslatedRewardDescr = function (rewardIndex, isInit, setPreviousSavedValue) {
            const rewardDescrField = `translatedReward${rewardIndex}RewardDescr`
            const prefix = `In case of collecting ${$scope[`reward${rewardIndex}NrOfStampsNeeded`] || '? piece'} stamps `
            const description = ($scope[rewardDescrField] || '').trim();
            $scope[`translatedReward${rewardIndex}DescrMaxLength`] = $scope[`reward${rewardIndex}NrOfStampsNeeded`] >= 10 ? 232 : 231;
            if (isInit) {
                $scope[rewardDescrField] = prefix + ($scope[rewardDescrField] || '')
                return
            }
            if (setPreviousSavedValue) {
                $scope[rewardDescrField] = prefix + ($scope[$scope.lang][`reward${rewardIndex}RewardDescr`] || '')
                return
            }
            if ($scope[rewardDescrField] && $scope[rewardDescrField].split(prefix)[1]) {
                $scope[`translatedReward${rewardIndex}DescrMinLength`] = 0;
                const descriptionPart = description.split(prefix)[1]
                $scope[rewardDescrField] = prefix + descriptionPart
            } else {
                $scope[rewardDescrField] = prefix
                $scope.handlingErrorInTranslatedDescrField(rewardIndex)
            }
        }

        $scope.getRewardDescrWithoutPrefix = function (rewardIndex) {
            const rewardDescrField = `reward${rewardIndex}RewardDescr`
            const prefix = `${$scope[`reward${rewardIndex}NrOfStampsNeeded`] || '? darab'} pecsét összegyűjtése esetén `
            return $scope[rewardDescrField] ? $scope[rewardDescrField].split(prefix)[1] : ''
        }

        $scope.getTranslatedRewardDescrWithoutPrefix = function (rewardIndex) {
            const rewardDescrField = `translatedReward${rewardIndex}RewardDescr`
            const prefix = `In case of collecting ${$scope[`reward${rewardIndex}NrOfStampsNeeded`] || '? piece'} stamps `
            return ($scope[rewardDescrField] != null && $scope[rewardDescrField].split(prefix)[1] != '') ? $scope[rewardDescrField].split(prefix)[1] : null
        }

        $scope.handlingErrorInDescrField = function (rewardIndex) {
            if ($scope.isActivateLoyaltyCard && $scope.noOfRewards >= rewardIndex && (rewardIndex == 1 || $scope.partnerSubscriptionType == 'premium')) {
                $scope[`reward${rewardIndex}DescrMinLength`] = $scope[`reward${rewardIndex}NrOfStampsNeeded`] >= 10 ? 32 : 31;
                return true
            } else {
                $scope[`reward${rewardIndex}DescrMinLength`] = 0
                return false
            }
        }

        $scope.handlingErrorInTranslatedDescrField = function (rewardIndex) {
            if ($scope.isActivateLoyaltyCard && $scope.translationEnabled && $scope.noOfRewards >= rewardIndex && (rewardIndex == 1 || $scope.partnerSubscriptionType == 'premium')) {
                $scope[`translatedReward${rewardIndex}DescrMinLength`] = $scope[`reward${rewardIndex}NrOfStampsNeeded`] >= 10 ? 33 : 32;
                return true
            } else {
                $scope[`translatedReward${rewardIndex}DescrMinLength`] = 0
                return false
            }
        }

        $scope.handlingMaxLengthErrorInDescrFields = function () {
            for (let i = 1; i < 6; i++) {
                const rewardDescrField = `reward${i}RewardDescr`
                const translatedRewardDescrField = `translatedReward${i}RewardDescr`
                if (i > $scope.noOfRewards) {
                    $scope[rewardDescrField] = $scope[rewardDescrField] ? $scope[rewardDescrField].substring(0, $scope[`reward${i}DescrMaxLength`] - 1) : ''
                    $scope[translatedRewardDescrField] = $scope[translatedRewardDescrField] ? $scope[translatedRewardDescrField].substring(0, $scope[`translatedReward${i}DescrMaxLength`] - 1) : ''
                    $scope[$scope.lang][rewardDescrField] = $scope[$scope.lang] && $scope[$scope.lang][rewardDescrField] ? $scope[$scope.lang][rewardDescrField].substring(0, 200) : ''
                }
            }
        }

        $scope.child = {}

        var setLoyalty = function () {
            LoyaltyCardService.modifyLoyaltyCard(null).then(function (result) {
                $scope.child.hideLCList()
                resetVariables();
                $scope.loyaltyCardData = result;
                if ($rootScope.partnerSubscriptionType == "custom") {
                    $scope.partnerSubscriptionType = 'premium';
                } else {
                    $scope.partnerSubscriptionType = $rootScope.partnerSubscriptionType;
                }
                $scope.displaySubscriptionType = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[4] : '';
                $scope.allCompStores = result.companyStoreData;
                $scope.compNamePromo = result.companyData[0].compNamePromo;
                $scope.promoCategories = result.promoCategories;
                $rootScope.logoCropperData.compLogoImg = result.companyData[0].compLogoImgURL;
                $scope.mainPromoCategory = result.companyData[0].mainPromoCategory;
                $scope.promoCategoryId = $scope.mainPromoCategory;
                $scope.isCompNamePromoSameAsCo = true;
                //$rootScope.unstampCropperData.unstampImg = "/imgs/loyaltyCardYalty/stamp/default";
                //$rootScope.stampCropperData.stampImg = "/imgs/loyaltyCardYalty/stamp/default";
                //$scope.buttonClass = "btn";
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.openVirtual = { "margin-top": "0px" };
                //$scope.isOpened = "fa fa-chevron-down";
                $scope.newloyaltyCard = "Új hűségkártya";
                //$scope.newButtonClass = "activeBtn";
                var date = new Date();
                var year = $filter('date')(date, "yyyy");
                var month = $filter('date')(date, "MM");
                var day = $filter('date')(date, "dd");
                var monthInHungarian = getMonth(month);
                $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";

                $scope.isEnglishTranslationEnabled = result.companyData[0].isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt'
                $scope.translationEnabled = result.companyData[0].isTranslation_EN_Enabled
                $scope.languages = [
                    { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                    {
                        id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                        url: "../imgs/translation_flag_EN.png",
                        windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                    }
                ]
                $scope.languageSelector = 1
                $scope['EN'] = {}
                $scope['EN'].compNamePromo = null
                $scope['EN'].promotionName = null
                $scope['EN'].stampConditionsDescr = null
                $scope['EN'].reward1RewardDescr = null
                $scope['EN'].reward2RewardDescr = null
                $scope['EN'].reward3RewardDescr = null
                $scope['EN'].reward4RewardDescr = null
                $scope['EN'].reward5RewardDescr = null
                $scope['EN'].promotionConditionsDescr = null
                $scope['EN'].phoneOrderInfoMessage = null
                $scope['EN'].onlineOrderInfoMessage = null
                $scope.partnerData = {}
                $scope.partnerData.translatedCompNamePromo = result.companyData[0].compNamePromo_EN



                setTimeout(function () {
                    $scope.showLoyaltys = false;
                    $scope.showSetLoyalty = true;
                }, 50)
                $scope.sendButtonDisabled = true;
            }, function (result) {
            })
        }
        $scope.setLoyalty = function () {
            if ($rootScope.isDataSheetModified == false) {
                setLoyalty();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    setLoyalty();
                }, function () {
                });
            }
        }

        if ($stateParams.loyaltyCardId && $stateParams.loyaltyCardId != 0) {
            $scope.modifyLoyaltyCard({ id: $stateParams.loyaltyCardId, isTemplate: false });
        } else {
            $scope.showLoyaltys = true;
            $scope.showSetLoyalty = false;
            //$scope.buttonClass = "activeBtn";
            //$scope.newButtonClass = "btn";
            $scope.animateMobile = "";
        }

        function DialogCtrl($scope, $mdDialog, partnerSubscriptionName, partnerSubscriptionType, count) {
            $scope.count = count;
            $scope.partnerSubscriptionName = partnerSubscriptionName;
            $scope.partnerSubscriptionType = partnerSubscriptionType;
            $scope.closeDialog = function () {
                $mdDialog.hide();
            }
            $scope.navigate = function () {
                $mdDialog.hide();
                $state.go("home.subscription")
            }
        }

        function getMonth(month) {
            if (month == "01") {
                return "január";
            } else if (month == "02") {
                return "február";
            } else if (month == "03") {
                return "március";
            } else if (month == "04") {
                return "április";
            } else if (month == "05") {
                return "május";
            } else if (month == "06") {
                return "június";
            } else if (month == "07") {
                return "július";
            } else if (month == "08") {
                return "augusztus";
            } else if (month == "09") {
                return "szeptember";
            } else if (month == "10") {
                return "október";
            } else if (month == "11") {
                return "november";
            } else if (month == "12") {
                return "december";
            }
        }
    })