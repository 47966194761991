// @ts-nocheck

import * as moment from 'moment-timezone';

angular.module('yaltyApp')
    .filter("limitText", function () {
        return function (input) {
            var output = "";
            if (input != (null || undefined)) {
                if (!hasWhiteSpace(input)) {
                    for (var i = 0; i < input.length; i = i + 22) {
                        output = output + input.substring(i, i + 22) + '\n';
                    }
                } else {
                    output = input;
                }
            }
            return output;
        }
    })
    .filter('linebreaks', function () {
        return function (text) {
            if (text != (null || undefined)) {
                return text.replace(/\n/g, "<br>");
            } else {
                return "";
            }
        }
    })
    .filter("limitDetailText", function () {
        return function (input) {
            var output = "";
            if (input != (null || undefined)) {
                if (!hasWhiteSpace(input)) {
                    for (var i = 0; i < input.length; i = i + 18) {
                        output = output + input.substring(i, i + 18) + '\n';
                    }
                } else {
                    output = input;
                }
            }
            return output;
        }
    })
    .directive('capitalize', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                var capitalize = function (inputValue) {
                    if (inputValue == undefined) inputValue = '';
                    var capitalized = inputValue.toUpperCase();
                    if (capitalized !== inputValue) {
                        modelCtrl.$setViewValue(capitalized);
                        modelCtrl.$render();
                    }
                    return capitalized;
                }
                modelCtrl.$parsers.push(capitalize);
                capitalize(scope[attrs.ngModel]); // capitalize initial value
            }
        };
    })
    .controller('kuponCtrl', function ($scope, $rootScope, $filter, $http, $cookies, $location, $state, $window, $document, $q, $mdDialog, API_PREFIX, $stateParams, CouponService, GoogleAnalyticsService) {
        $scope.data = {
            model: {},
            couponValue: { 1: 'kedvezmény', 2: 'kedvezmény', 3: '', 4: 'Akciós ár', 5: 'Ajándék', 6: '', 7: '' }
        };
        $scope.couponRedeemTypes = [
            {
                id: 1, type: 1, listName: 'Kupon képernyőn felmutatása',
                redeemPartnerResponseRequired: false
            },
            {
                id: 2, type: 10, listName: 'Beváltási kód (vonalkód, QR-kód stb.) képernyőn felmutatása',
                redeemPartnerResponseRequired: false
            },
            {
                id: 4, type: 100, listName: 'Yalty partneri visszaigazolás (kötelezően)',
                redeemPartnerResponseRequired: true
            }
        ]
        $scope.barcodeTypes = [
            {
                id: 1, displayName: 'Vonalkód (“1D vonalkód”)'
            },
            {
                id: 10, displayName: 'QR-kód (“2D vonalkód”)'
            }
        ]
        $scope.barcodeSubTypes = [
            {
                id: 21, displayName: 'EAN-13'
            },
            {
                id: 20, displayName: 'EAN-8'
            }
        ]
        $scope.barcodeSourceOptions = [
            {
                id: 1, displayName: 'Kód megadása alapján'
            },
            {
                id: 0, displayName: 'Képként feltöltve'
            }
        ]
        $scope.showCouponCollectionSettings = $cookies.get("adminUserType") != null
        //$scope.barcodeSourceCodeLength = 10
        $scope.length == null
        $scope.timeZones = moment.tz.names();
        $rootScope.isDataSheetModified = false;
        $rootScope.zoomSlider = 0;
        $scope.sliderMin = 0;
        $scope.sliderMax = 10;
        $scope.displayStartDate = new Date();
        $scope.redeemStartDate = new Date();
        $scope.redeemEndDate = new Date();
        $rootScope.detailCropperData = {};
        $rootScope.barcodeCropperData = {};
        $rootScope.listCropperData = {};
        $scope.couponDetail = {};
        $scope.couponDetail.couponDetailViewData = {};
        $scope.couponDetail.couponRedeemLocations = {};
        $scope.promotionConditionsDescr = "";
        $scope.promotionDetailedDescr = "";
        $scope.showCoupons = false;
        $scope.buttonClass = "activeBtn";
        $scope.newButtonClass = "btn";
        $scope.animateMobile = "";
        $scope.isCompStoresSame = false;
        $scope.sendButtonDisabled = false;
        $scope.redeemOveralLimitation = false;
        $scope.redeemPerUserLimitation = false;
        $scope.dateNow = new Date();
        $scope.orderBy = [{ id: '1', value: 'Utolsó módosítás dátuma szerint legújabb elől', order: 'lastUpdatedTimestamp' },
        { id: '2', value: 'Utolsó módosítás dátuma szerint legrégebbi elől', order: 'lastUpdatedTimestamp' },
        { id: '3', value: 'Promóció megnevezése szerint A-Z', order: 'promotionName' },
        { id: '4', value: 'Promóció megnevezése szerint Z-A', order: 'promotionName' },
        { id: '5', value: 'Promóció kezdete szerint legújabb elől', order: 'displayStartDate' },
        { id: '6', value: 'Promóció kezdete szerint legrégebbi elől', order: 'displayStartDate' },
        { id: '7', value: 'Promóció vége szerint legújabb elől', order: 'redeemEndDate' },
        { id: '8', value: 'Promóció vége szerint legrégebbi elől', order: 'redeemEndDate' }];
        $scope.order = $scope.orderBy[0];
        $scope.orderCoupon = "'" + $scope.orderBy[0].order + "'";
        $scope.reverseCoupon = false;
        $scope.orderChange = function (id) {
            if (id == 1 || id == 4 || id == 6 || id == 8) {
                $scope.orderCoupon = "'" + $scope.orderBy[(id - 1)].order + "'";
                $scope.reverseCoupon = true;
            } else {
                $scope.orderCoupon = "'" + $scope.orderBy[(id - 1)].order + "'";
                $scope.reverseCoupon = false;
            }
        }
        $scope.orderChange(1);
        //checked érték esetén régi ár mező megjelenítése
        //string érték esetén a szöveges mező megjelenítése
        $scope.names = [{ id: '1', name: 'Százalék kedvezmény', value: 'kedvezmény', checked: false, style: '%', string: false, em: "6em" },
        { id: '2', name: 'Forint kedvezmény', value: 'kedvezmény', checked: false, style: ' FT', string: false, em: "6em" },
        { id: '3', name: 'Árleszállítás', value: '', checked: true, style: ' FT', string: false, em: "3em" },
        { id: '4', name: 'Kedvezményes ár', value: 'Akciós ár', checked: false, style: ' FT', string: false, em: "6em" },
        { id: '5', name: 'Ajándék', value: 'Ajándék', checked: false, style: '', string: true, em: "6em" },
        { id: '6', name: 'Egyedi kedvezmény szöveg', value: '', checked: false, style: '', string: true, em: "3em" },
        { id: '0', name: 'Nincs kedvezmény megjelölés', value: '', checked: false, style: '', string: false, em: "9em" }];

        $scope.promoStatusText = [{ code: 0, text: "még nem promótált kupon" },
        { code: 1, text: "futó promóció" },
        { code: 2, text: "lejárt promóció" },
        { code: 3, text: "törölt promóció" }];

        $scope.redeem = { overallQuantity: 0 };
        $scope.redeem = { perUserQuantity: 0 };
        $scope.blockingObject = { block: true };
        $rootScope.$watch('listCropperData.listCroppedImage', function (current, prevent) {
            if (prevent && current) {
                if ($rootScope.isDataSheetModified == false) {
                    if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        });
        $rootScope.$watch('detailCropperData.detailCroppedImage', function (current, prevent) {
            if (prevent && current) {
                if ($rootScope.isDataSheetModified == false) {
                    if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        });
        $rootScope.$watch('barcodeCropperData.barcodeImg', function (current, prevent) {
            if (prevent && current) {
                if ($rootScope.isDataSheetModified == false) {
                    if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        });
        $rootScope.$watch('isDataSheetModified', function () {
            if ($rootScope.isDataSheetModified == false) {
                $scope.sendButtonDisabled = true;
            } else {
                $scope.sendButtonDisabled = false;
            }
        });
        $scope.checkChanges = function () {
            /*if ($scope.isDisplayStartDateActualOnActivation == true) {
                if ($scope.couponDetail) {
                    if ($scope.couponDetail.couponDetailViewData) {
                        var displayStart = new Date($scope.couponDetail.couponDetailViewData.displayStartDate);
                        displayStart.setSeconds(0, 0);
                        displayStart.setUTCMinutes(displayStart.getMinutes() + displayStart.getTimezoneOffset());
                        var timeZoneOffset = moment.tz.zone($scope.datesTimeZone).utcOffset(displayStart.getTime());
                        displayStart.setUTCMinutes(displayStart.getMinutes() - timeZoneOffset);
                        if ($scope.displayStartDate > displayStart || $scope.displayStartDate < displayStart) {
                            $scope.isDisplayStartDateActualOnActivation = false;
                        }
                    }
                }
            }*/
            if ($scope.discountText) {
                $scope.discountText = $scope.discountText.trim();
            }
            if ($scope.discountText2) {
                $scope.discountText2 = $scope.discountText2.trim();
            }
            if ($scope.promotionDetailedDescr) {
                $scope.promotionDetailedDescr = $scope.promotionDetailedDescr.trim();
            }
            if ($scope.promotionConditionsDescr) {
                $scope.promotionConditionsDescr = $scope.promotionConditionsDescr.trim();
            }
            if ($rootScope.isDataSheetModified == false) {
                if ($scope.couponId) {
                    if ($scope.compNamePromo != $scope.couponDetail.couponDetailViewData.compNamePromo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isCompNamePromoSameAsCo != $scope.couponDetail.couponDetailViewData.isCompNamePromoSameAsCo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionName != $scope.couponDetail.couponDetailViewData.promotionName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.data.model.id != $scope.couponDetail.couponDetailViewData.promotionType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountAmountPercent != $scope.couponDetail.couponDetailViewData.discountAmount) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountAmount != $scope.couponDetail.couponDetailViewData.discountAmount) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountOldPrice != $scope.couponDetail.couponDetailViewData.discountOldPrice) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountText != $scope.couponDetail.couponDetailViewData.discountText) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountText2 != $scope.couponDetail.couponDetailViewData.discountText2) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionDetailedDescr != $scope.couponDetail.couponDetailViewData.promotionDetailedDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionConditionsDescr != $scope.couponDetail.couponDetailViewData.promotionConditionsDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemOveralLimitation == false && $scope.couponDetail.couponDetailViewData.redeemOveralLimitation == "limited") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeem.redeemOveralAmount != $scope.couponDetail.couponDetailViewData.redeemOveralAmount) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemPerUserLimitation == false && $scope.couponDetail.couponDetailViewData.redeemPerUserLimitation == "limited") {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeem.redeemPerUserAmount != $scope.couponDetail.couponDetailViewData.redeemPerUserAmount) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemType != $scope.initRedeemType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeType != $scope.couponDetail.couponDetailViewData.barcodeType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSourceType != $scope.couponDetail.couponDetailViewData.barcodeSourceType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSubType != $scope.couponDetail.couponDetailViewData.barcodeSubType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSourceCode != $scope.couponDetail.couponDetailViewData.barcodeSourceCode) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promoCategory != $scope.couponDetail.couponDetailViewData.promoCategoryId) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyStores.length != $scope.couponDetail.couponRedeemLocations.length) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.displayStartDate != $scope.couponDetail.couponDetailViewData.redeemStartDate) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemStartDate != $scope.couponDetail.couponDetailViewData.redeemStartDate) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemEndDate != $scope.couponDetail.couponDetailViewData.redeemEndDate) {
                        $rootScope.isDataSheetModified = true;
                    }
                } else {
                    if ($scope.compNamePromo != $scope.allCompStores[0].compNamePromo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isCompNamePromoSameAsCo != true) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.data.model) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountAmountPercent) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountAmount) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountOldPrice) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountText) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.discountText2) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionDetailedDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promotionConditionsDescr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemOveralLimitation != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeem.redeemOveralAmount) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemPerUserLimitation != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeem.redeemPerUserAmount) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemType != $scope.initRedeemType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeType != $scope.couponDetail.couponDetailViewData.barcodeType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSourceType != $scope.couponDetail.couponDetailViewData.barcodeSourceType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSubType != $scope.couponDetail.couponDetailViewData.barcodeSubType) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.barcodeSourceCode != $scope.couponDetail.couponDetailViewData.barcodeSourceCode) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.promoCategory != $scope.mainPromoCategory) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyStores) {
                        $rootScope.isDataSheetModified = true;
                    }
                    var dispDate = new Date($scope.displayStartDate.getFullYear(), $scope.displayStartDate.getMonth(), $scope.displayStartDate.getDate(), 0, 0);
                    if ($scope.displayStartDate != dispDate) {
                        $rootScope.isDataSheetModified = true;
                    }
                    var startDate = new Date($scope.redeemStartDate.getFullYear(), $scope.redeemStartDate.getMonth(), $scope.redeemStartDate.getDate(), 0, 0);
                    if ($scope.redeemStartDate != startDate) {
                        $rootScope.isDataSheetModified = true;
                    }
                    var endDate = new Date($scope.redeemEndDate.getFullYear(), $scope.redeemEndDate.getMonth(), $scope.redeemEndDate.getDate(), 23, 59);
                    if ($scope.redeemEndDate != endDate) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        }

        $scope.redeemTypeCheck = () => {
            if (!$scope.isRedeemPartnerResponseRequired && $scope.redeemType == 4) {
                $scope.couponForm1.redeemType.$setValidity('partnerConfirmationOff', false);
            } else {
                $scope.couponForm1.redeemType.$setValidity('partnerConfirmationOff', true);
            }
            $scope.checkChanges()
        }

        $scope.initOpacityValue = () => {
            $scope.listViewImgBlackOverlayOpacityOptions = [
                {
                    id: 1, displayValue: 'Nincs sötétítés (0%)', value: 0
                },
                {
                    id: 2, displayValue: 'Halvány (10%)', value: 10
                },
                {
                    id: 3, displayValue: 'Közepes (25%)', value: 25
                },
                {
                    id: 4, displayValue: 'Sötét (40%)', value: 40
                },
                {
                    id: 6, displayValue: 'Nagyon sötét (60%)', value: 60
                }
            ]
            const opacityId = getOpacityIdByValue($scope.listViewImgBlackOverlayOpacityValue)
            if (opacityId === null) {
                $scope.listViewImgBlackOverlayOpacityOptions.unshift({
                    id: 0, displayValue: `${$scope.listViewImgBlackOverlayOpacityValue}%`, value: $scope.listViewImgBlackOverlayOpacityValue
                })
            }
            $scope.listViewImgBlackOverlayOpacity = opacityId || 0
        }

        $scope.changeOpacityValue = () => {
            const opacityValue = getOpacityValueById($scope.listViewImgBlackOverlayOpacity)
            if (opacityValue !== null) {
                $scope.listViewImgBlackOverlayOpacityValue = opacityValue
            }
            $scope.checkChanges()
        }

        const getOpacityValueById = (opacityId) => {
            const opacityOption = $scope.listViewImgBlackOverlayOpacityOptions.find(
                opacityOption => opacityOption.id == opacityId)
            return (opacityOption !== undefined) ? opacityOption.value : null
        }

        const getOpacityIdByValue = (opacityValue) => {
            const opacityOption = $scope.listViewImgBlackOverlayOpacityOptions.find(
                opacityOption => opacityOption.value == opacityValue)
            return (opacityOption !== undefined) ? opacityOption.id : null
        }

        /*$scope.removeNewLineChars = () => {
            //$('#barcodeSourceCode').val( $('#barcodeSourceCode').val().replace( /\r?\n/gi, '' ) );
            //$scope.barcodeSourceCode = $scope.barcodeSourceCode.trim()
            //console.log($scope.barcodeSourceCode);
        }*/

        $scope.setBarcodeSourceCodeLength = () => {
            /*$scope.length = true
            if ($scope.barcodeSubType == 20) {
                 $scope.barcodeSourceCodeLength == 8
            } else {
                $scope.barcodeSourceCodeLength == 13
            }
            $scope.codeValidation()
            setTimeout(function(){ $scope.length = null }, 0);*/
        }

        $scope.setBarcodeSourceCodeValidity = () => {
            $scope.couponForm1.barcodeSourceCode.$setValidity('invalidLength', true);
            $scope.couponForm1.barcodeSourceCode.$setValidity('notOnlyNumbers', true);
            $scope.barcodeSubType = null
            $scope.barcodeSourceCode = null
        }

        $scope.codeValidation = () => {
            if (!$scope.barcodeSourceCode) return
            let regexErrSet = false
            if ($scope.barcodeType == 1) {
                if ($scope.barcodeSourceCode.match('^[0-9]{0,}$')) {
                    $scope.couponForm1.barcodeSourceCode.$setValidity('notOnlyNumbers', true);
                } else {
                    regexErrSet = true
                    $scope.couponForm1.barcodeSourceCode.$setValidity('notOnlyNumbers', false);
                }
                if (!regexErrSet && $scope.barcodeSubType == 20) {
                    if ($scope.barcodeSourceCode.match('^[0-9]{8}$')) {
                        $scope.couponForm1.barcodeSourceCode.$setValidity('invalidLength', true);
                    } else {
                        $scope.couponForm1.barcodeSourceCode.$setTouched()
                        regexErrSet = true
                        $scope.invalidLengthErrorText = 'Az EAN-8 kód 8 számjegyet kell tartalmazzon.'
                        $scope.couponForm1.barcodeSourceCode.$setValidity('invalidLength', false);
                    }
                } else if (!regexErrSet) {
                    if ($scope.barcodeSourceCode.match('^[0-9]{13}$')) {
                        $scope.couponForm1.barcodeSourceCode.$setValidity('invalidLength', true);
                    } else {
                        $scope.couponForm1.barcodeSourceCode.$setTouched()
                        regexErrSet = true
                        $scope.invalidLengthErrorText = 'Az EAN-13 kód 13 számjegyet kell tartalmazzon.'
                        $scope.couponForm1.barcodeSourceCode.$setValidity('invalidLength', false);
                    }
                }
            }
            if (!regexErrSet) generatePreviewBarcodeImg()
        }

        const generatePreviewBarcodeImg = () => {
            try {
                $scope.couponForm1.barcodeSourceCode.$setValidity('invalidCheckSum', true);
                if ($scope.barcodeSubType == 20) {
                    resetBarcodePreviewCanvas()
                    JsBarcode("#barcodePreview")
                        .options({ font: "OCR-B", width: 3, height: 80 }) // Will affect all barcodes
                        .EAN8($scope.barcodeSourceCode, { fontSize: 18, textMargin: 0 })
                        //.blank(20) // Create space between the barcodes
                        .render();

                } else if ($scope.barcodeSubType == 21) {
                    resetBarcodePreviewCanvas()
                    JsBarcode("#barcodePreview")
                        .options({ font: "OCR-B", width: 2, height: 80 }) // Will affect all barcodes
                        .EAN13($scope.barcodeSourceCode, { fontSize: 18, textMargin: 0 })
                        //.blank(20) // Create space between the barcodes
                        .render();
                } else {
                    QRCode.toCanvas(document.getElementById('barcodePreview'), $scope.barcodeSourceCode, {
                        width: 250/*,
                        margin: 2, 
                        color: {
                            dark : "#FFFFFF",
                            light: "#00AEEF"
                        }*/
                    }, function (error) {
                        if (error) console.error(error)
                    })
                }
            } catch (err) {
                console.log(err);
                $scope.couponForm1.barcodeSourceCode.$setValidity('invalidCheckSum', false);
            }
        }

        const resetBarcodePreviewCanvas = () => {
            var canvas = $('#barcodePreview')[0]; // or document.getElementById('canvas');
            canvas.style = ""
        }

        $('#barcodeSourceCode').keypress(function (e) {
            if (e.keyCode == 13) {
                return false
            }
        });

        $scope.isDisplayStartDateOnActivation = function () {
            $rootScope.isDataSheetModified = true;
            var now = new Date();
            if ($scope.isDisplayStartDateActualOnActivation == true) {
                $scope.displayStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes());
            } else {
                $scope.displayStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);
            }
            $scope.invalidErrorInDisplayStart = false;
            $scope.invalidErrorInDisplayStartDate = false;
        }
        $scope.isDisplayStartDateInBegin = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.displayStartDateInBegin == true) {
                if ($scope.displayStartDate != null) {
                    const date = new Date($scope.displayStartDate.getFullYear(), $scope.displayStartDate.getMonth(), $scope.displayStartDate.getDate(), 0, 0);
                    $scope.displayStartDate = date;
                    $scope.isValidDate();
                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);
                    $scope.displayStartDate = date;
                    $scope.isValidDate();
                }
            } else {
                if ($scope.displayStartDate != null) {
                    const date = new Date($scope.displayStartDate.getFullYear(), $scope.displayStartDate.getMonth(), $scope.displayStartDate.getDate(), 0, 0);
                    $scope.displayStartDate = date;
                    $scope.isValidDate();
                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);
                    $scope.displayStartDate = date;
                    $scope.isValidDate();
                }
            }
        }
        $scope.isRedeemStartDateInBegin = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.redeemStartDateInBegin == true) {
                if ($scope.redeemStartDate) {
                    const date = new Date($scope.redeemStartDate.getFullYear(), $scope.redeemStartDate.getMonth(), $scope.redeemStartDate.getDate(), 0, 0);
                    $scope.redeemStartDate = date;
                    $scope.isValidDate();
                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);
                    $scope.redeemStartDate = date;
                    $scope.isValidDate();
                }
            } else {
                if ($scope.redeemStartDate) {
                    const date = new Date($scope.redeemStartDate.getFullYear(), $scope.redeemStartDate.getMonth(), $scope.redeemStartDate.getDate(), 0, 0);
                    $scope.redeemStartDate = date;
                    $scope.isValidDate();
                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);
                    $scope.redeemStartDate = date;
                    $scope.isValidDate();
                }
            }
        }
        $scope.isRedeemEndDateInEnd = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.redeemEndDateInEnd == true) {
                if ($scope.redeemEndDate) {
                    const date = new Date($scope.redeemEndDate.getFullYear(), $scope.redeemEndDate.getMonth(), $scope.redeemEndDate.getDate(), 23, 59);
                    $scope.redeemEndDate = date;
                    $scope.isValidDate();
                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), 23, 59);
                    $scope.redeemEndDate = date;
                    $scope.isValidDate();
                }
            } else {
                if ($scope.redeemEndDate) {
                    const date = new Date($scope.redeemEndDate.getFullYear(), $scope.redeemEndDate.getMonth(), $scope.redeemEndDate.getDate(), 23, 59);
                    $scope.redeemEndDate = date;
                    $scope.isValidDate();
                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), 23, 59);
                    $scope.redeemEndDate = date;
                    $scope.isValidDate();
                }
            }
        }
        $scope.checkTimeHaveDate = function (param) {
            if (param == "displayStart") {
                if ($scope.displayStartDate) {

                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                    $scope.displayStartDate = date;
                }
            } else if (param == "redeemStart") {
                if ($scope.redeemStartDate) {

                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                    $scope.redeemStartDate = date;
                }
            } else if (param == "redeemEnd") {
                if ($scope.redeemEndDate) {

                } else {
                    const now = new Date();
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                    $scope.redeemEndDate = date;
                }
            }
        }
        $scope.resetPage = function () {
            if ($rootScope.isDataSheetModified == false) {
                $window.scrollTo(0, 0);
                $state.reload();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    $state.reload();
                }, function () {
                });
            }
        }
        $scope.shop = {
            "font-size": "12px",
            "text-align": "left",
            "text-transform": "uppercase",
            "margin": "0px",
            "color": "#4E525C"
        };
        $scope.discount = {
            "font-size": "12px",
            "font-weight": "bold",
            "text-align": "left",
            "text-transform": "uppercase",
            "margin": "0px",
            "word-wrap": "break-word"
        };
        $scope.oldDiscount = {
            "text-decoration": "line-through",
            "font-size": "15px",
            "text-align": "left",
            "margin": "0px"
        };
        $scope.description = {
            "font-size": "12px",
            "text-align": "left",
            "word-wrap": "break-word"
        };
        $scope.style = {
            "color": "coral",
            "font-size": "12px",
            "text-align": "center",
            "margin": "0px",
            "font-weight": "bold"
        };
        $scope.select = {
            "font-size": "12px",
            "text-align": "center",
            "margin": "0px"
        };
        $scope.valid = {
            "font-size": "12px",
            "text-align": "left",
            "text-transform": "uppercase",
            "font-weight": "bold",
            "margin": "0px"
        };
        var virtualSize = $window.innerHeight - 300 //* 0.55;
        var company = [];
        $scope.containerSize = { "height": virtualSize + "px", "width": "300px", "margin-left": "8px" };
        /*angular.element($window).on('resize', function () {
            console.log($window.innerHeight);
        });*/

        $scope.showCoupons = true;
        $scope.isOpened = "fa fa-chevron-up";
        $scope.getCoupons = function () {
            if ($scope.showCoupons) {
                $scope.buttonClass = "btn";
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.showCoupons = false;
                $scope.openVirtual = { "margin-top": "0px" };
                $scope.isOpened = "fa fa-chevron-down";
            } else {
                $scope.buttonClass = "activeBtn";
                $scope.animateMobile = "yalty-animateFrom-mobile"
                $scope.showCoupons = true;
                $scope.openVirtual = { "margin-top": "-" + (virtualSize - 50) + "px" };
                $scope.isOpened = "fa fa-chevron-up";
            }
        }
        $scope.newCoupon = null;
        $scope.companyStores = [];
        var resetVariables = function () {
            //document.getElementById("couponForm1").reset();
            //$scope.couponForm1.$setUntouched()
            if ($rootScope.detailCropperData.cropper) {
                $rootScope.detailCropperData.cropper.destroy();
            }
            if ($rootScope.listCropperData.listcropper) {
                $rootScope.listCropperData.listcropper.destroy();
            }
            delete $rootScope.detailCropperData;
            delete $rootScope.listCropperData;
            $rootScope.detailCropperData = {};
            $rootScope.barcodeCropperData = {};
            $rootScope.listCropperData = {};
            $rootScope.isSelectedFile = false
            $scope.datesTimeZone = moment.tz.guess();
            $scope.isCompStoresSame = false;
            $scope.couponId = null;
            $scope.couponDetail = null;
            $rootScope.listCropperData.listCroppedImage = null;
            $scope.listImage = null;
            $rootScope.detailCropperData.detailCroppedImage = null;
            $scope.detailImage = null;
            $rootScope.listCropperData.b = null;
            $scope.promotionName = null;
            $scope.data.model = null;
            $scope.discountAmount = undefined;
            $scope.discountAmountPercent = undefined;
            $scope.discountOldPrice = undefined;
            $scope.discountText = undefined;
            $scope.discountText2 = undefined;
            $scope.promotionConditionsDescr = "";
            $scope.promotionDetailedDescr = "";
            //$scope.couponForm1.promotionDetailedDescr = ""
            $scope.redeemPartnerResponseRequired = false;
            $scope.redeemOveralLimitation = false;
            $scope.redeem.redeemOveralAmount = null;
            $scope.redeemPerUserLimitation = false;
            $scope.redeem.redeemPerUserAmount = null;
            $scope.promoCategory = $scope.mainPromoCategory;
            $scope.compNamePromo = $scope.allCompStores[0].compNamePromo;
            $scope.isCompNamePromoSameAsCo = true;
            $scope.companyStores = [];
            company = [];
            $scope.allRedeemLocationIndicator = undefined;
            $scope.amount = null;
            $scope.redeemStartDate = new Date();
            $scope.redeemStartDate.setHours(0, 0);
            $scope.redeemStartDate.setSeconds(0, 0);
            $scope.redeemStartDateInBegin = true;
            $scope.redeemEndDate = new Date();
            $scope.redeemEndDate.setMonth($scope.redeemStartDate.getMonth() + 1);
            $scope.redeemEndDate.setHours(23, 59);
            $scope.redeemEndDate.setSeconds(0, 0);
            $scope.redeemEndDateInEnd = true;
            $scope.displayStartDate = new Date();
            $scope.displayStartDate.setMilliseconds(0);
            $scope.displayStartDateInBegin = true;
            $scope.isDisplayStartDateActualOnActivation = true;
            $scope.couponPromoStatusCode = 0;
            $scope.invalidErrorInRedeemStartDate = false;
            $scope.invalidErrorInRedeemStartDateInBeginTime = false;
            $scope.invalidErrorInRedeemEndDate = false;
            $scope.invalidErrorInRedeemEndDateInEndTime = false;
            $scope.isAllRedeemLocationIndicator = false
            $scope.barcodeType = null
            $scope.redeemType = null
            $scope.barcodeSubType = null
            $scope.barcodeSourceCode = null
            $scope.barcodeSourceType = 1
            $scope.appearsOutsideOfCollection = false
            $scope.couponCollection = 0
            $rootScope.isDataSheetModified = false;
        };
        var setCoupon = function () {
            resetVariables();
            CouponService.getCouponCollections(null, 0).then(function (collectionRes) {
                const collections = collectionRes.couponCollections ? collectionRes.couponCollections.recordset : []
                setCouponCollectionOptions(collections, collectionRes.errorCode)
            })
            $scope.buttonClass = "btn";
            $scope.animateMobile = "yalty-animateTo-mobile";
            $scope.openVirtual = { "margin-top": "0px" };
            $scope.showCoupons = false;
            $scope.isOpened = "fa fa-chevron-down";
            $scope.newCoupon = "ÚJ KUPON";
            $scope.showSetCoupon = true;
            $scope.newButtonClass = "activeBtn";
            $scope.listViewImgBlackOverlayOpacityValue = 0
            $scope.initOpacityValue()
            var date = new Date();
            var year = $filter('date')(date, "yyyy");
            var month = $filter('date')(date, "MM");
            var day = $filter('date')(date, "dd");
            var monthInHungarian = getMonth(month);
            $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";

            $scope.isEnglishTranslationEnabled = $scope.isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt'
            $scope.translationEnabled = $scope.isTranslation_EN_Enabled
            $scope.languages = [
                { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                {
                    id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                    url: "../imgs/translation_flag_EN.png",
                    windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                }
            ]
            $scope.languageSelector = 1
            $scope['EN'] = {}




            $rootScope.isDataSheetModified = false;
        }
        $scope.setCoupon = function () {
            if ($rootScope.isDataSheetModified == false) {
                setCoupon();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    setCoupon();
                }, function () {
                });
            }
        }
        $scope.hungarianMonth = function (date) {
            var year = $filter('date')(date, "yyyy");
            var month = $filter('date')(date, "MM");
            var day = $filter('date')(date, "dd");
            var monthInHungarian = getMonth(month);
            return year + ". " + monthInHungarian + " " + day + ".";
        }
        const setAllCompStores = function () {
            if ($scope.companyStores == (null || undefined || '')) {
                if ($scope.allCompStores != null) {
                    for (var i = 0; i < $scope.allCompStores.length; i++) {
                        company.push($scope.allCompStores[i].id);
                    }
                    $scope.companyStores = company;
                    $scope.checkAll = true;
                }
            } else {
                $scope.companyStores = [];
                $scope.checkAll = false;
                company = [];
            }
        }
        $scope.setAllCompStores = function () {
            setAllCompStores();
        };
        $scope.checkRedeemLocations = function () {
            if ($scope.isAllRedeemLocationIndicator === true) {
                $scope.allRedeemLocationIndicator = "all";
            } else {
                $scope.allRedeemLocationIndicator = "select";
            }
            $scope.checkAll = true;
            if ($scope.allCompStores != null) {
                $scope.companyStores = [];
                company = [];
                for (var i = 0; i < $scope.allCompStores.length; i++) {
                    company.push($scope.allCompStores[i].id);
                }
                $scope.companyStores = company;
            }
        }
        $scope.$watch('companyStores', function (current) {
            if (current) {
                if (current.length === $scope.allCompStores.length) {
                    $scope.checkAll = true;
                } else {
                    $scope.checkAll = false;
                }
            } else {
                $scope.checkAll = false;
            }
        })
        $scope.isValidDate = function () {
            if (moment($scope.redeemStartDate).isBefore($scope.redeemEndDate)) {
                $scope.invalidErrorInRedeemEndDate = false;
                $scope.invalidErrorInRedeemEndDateInEndTime = false;
            }
            if (moment($scope.displayStartDate).isSameOrBefore($scope.redeemStartDate, 'day')) {
                $scope.invalidErrorInRedeemStartDate = false;
                $scope.invalidErrorInRedeemStartDateInBeginTime = false;
            }
            if ($scope.displayStartDate != null && $scope.isDisplayStartDateActualOnActivation == false) {
                $scope.invalidErrorInDisplayStart = false;
                $scope.invalidErrorInDisplayStartDate = false;
            }
        }
        $scope.compNamePromoSameAsCo = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isCompNamePromoSameAsCo == true) {
                $scope.compNamePromo = $scope.allCompStores[0].compNamePromo;
            } else {
                $scope.compNamePromo = undefined;
            }
        }
        $scope.activateOrDeactivateCoupon = function (couponId, actionType) {
            if (actionType == "activate") {
                if ($cookies.get("adminUserType")) {
                    if ($scope.isDisplayStartDateActualOnActivation == true) {
                        var date = new Date();
                        $scope.displayStartDate = date;
                    }
                    $scope.invalidErrorInDisplayStart = false;
                    $scope.invalidErrorInDisplayStartDate = false;
                    $scope.invalidErrorInRedeemStartDate = false;
                    $scope.invalidErrorInRedeemStartDateInBeginTime = false;
                    $scope.invalidErrorInRedeemEndDate = false;
                    $scope.invalidErrorInRedeemEndDateInEndTime = false;
                    if ((moment($scope.displayStartDate).isSameOrBefore($scope.redeemStartDate, 'day')
                        && moment($scope.redeemStartDate).isBefore($scope.redeemEndDate)
                        && moment($scope.displayStartDate).isBefore($scope.redeemEndDate))
                        && $scope.couponDetail.couponDetailViewData.redeemStartDate != null
                        && $scope.couponDetail.couponDetailViewData.redeemEndDate != null
                        && $scope.couponDetail.couponDetailViewData.displayStartDate != null
                        && ($scope.displayStartDate == $scope.couponDetail.couponDetailViewData.displayStartDate
                            && $scope.redeemStartDate == $scope.couponDetail.couponDetailViewData.redeemStartDate
                            && $scope.redeemEndDate == $scope.couponDetail.couponDetailViewData.redeemEndDate)) {
                        const confirm = $mdDialog.confirm()
                            .title('Aktiválás megerősítése')
                            .textContent('Aktiválás esetén a kupont élesítjük.')
                            .ariaLabel('Aktiválás megerősítése')
                            .ok('Aktiválás')
                            .cancel('Mégse');
                        $mdDialog.show(confirm).then(function () {
                            CouponService.activateOrDeactivateCoupon(couponId, actionType, $scope.datesTimeZone).then(function (result) {
                                if (result.errorCode == 0) {
                                    const alert = $mdDialog.alert()
                                        .textContent('A kupon aktivációja sikeres.')
                                        .ariaLabel('Sikeres aktiváció')
                                        .ok('Rendben')
                                    $mdDialog.show(alert).then(function () {
                                        $rootScope.isDataSheetModified = false;
                                        $state.reload();
                                    });
                                } else if (result.errorCode == 6) {
                                    $mdDialog.show({
                                        template:
                                            '<md-dialog>' +
                                            '  <md-dialog-content class="md-dialog-content">' +
                                            'Kupon aktiválását egyelőre csak Yalty ügyintéző tudja végezni. ' +
                                            'Kérjük hívja Yalty partneri kapcsolattartóját vagy kérje kuponja aktiválását az ' +
                                            '<a href="mailto:ugyfelszolgalat@yalty.com?subject=Kupon%20aktiválása%20-%20{{compNamePromo}}%20|%20{{promotionName}}">ugyfelszolgalat@yalty.com</a> címünkön' +
                                            '  </md-dialog-content>' +
                                            '  <md-dialog-actions>' +
                                            '    <md-button ng-click="closeDialog()" class="md-primary">' +
                                            '      Rendben' +
                                            '    </md-button>' +
                                            '  </md-dialog-actions>' +
                                            '</md-dialog>',
                                        locals: {
                                            compNamePromo: $scope.compNamePromo,
                                            promotionName: $scope.promotionName
                                        },
                                        controller: DialogCtrl
                                    });
                                } else if (result.errorCode == 7) {
                                    const alert = $mdDialog.alert()
                                        .title("FIGYELEM: A promóciót nem aktiváltuk!")
                                        .textContent("A promócióhoz nem tartozik olyan üzlet, amely aktív Yalty városhoz tartozik. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                            + "Állítsa be a promócióhoz tartozó üzlet adatlapján a Yalty város mezőt és ezután aktiválja újra a promóciót.\n\n"
                                            + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                        .ok('Rendben')
                                    $mdDialog.show(alert).then(function () {
                                        $scope.sendButtonDisabled = true;
                                        $rootScope.isDataSheetModified = false;
                                    });
                                } else if (result.errorCode == 8) {
                                    const alert = $mdDialog.alert()
                                        .title("FIGYELEM: A promóciót aktiváltuk, de egy vagy több, a promócióhoz tartozó üzlet városához nem fog megjelenni!")
                                        .textContent("A promócióhoz tartozik egy vagy több olyan üzlet, amely nem köthető Yalty városhoz. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                            + "Állítsa be a Yalty város mezőt a promócióhoz tartozó ezen üzlet(ek) adatlapján.\n\n"
                                            + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                        .ok('Rendben')
                                    $mdDialog.show(alert).then(function () {
                                        $rootScope.isDataSheetModified = false;
                                        $scope.sendButtonDisabled = false;
                                        $state.reload();
                                    });
                                } else {
                                    const alert = $mdDialog.alert()
                                        .title(result.msg)
                                        .textContent(result.msg)
                                        .ariaLabel(result.msg)
                                        .ok('Rendben')
                                    $mdDialog.show(alert).then(function () {

                                    });
                                }
                            });
                        }, function () {

                        });
                    } else if ((moment($scope.displayStartDate).isSameOrBefore($scope.redeemStartDate, 'day')
                        && moment($scope.redeemStartDate).isBefore($scope.redeemEndDate)
                        && moment($scope.displayStartDate).isBefore($scope.redeemEndDate))
                        && ($scope.displayStartDate != null && $scope.redeemStartDate != null
                            && $scope.redeemEndDate != null)
                        && ($scope.displayStartDate != $scope.couponDetail.couponDetailViewData.displayStartDate
                            || $scope.redeemStartDate != $scope.couponDetail.couponDetailViewData.redeemStartDate
                            || $scope.redeemEndDate != $scope.couponDetail.couponDetailViewData.redeemEndDate)
                        /*|| ($scope.couponDetail.couponDetailViewData.redeemStartDate == null
                            || $scope.couponDetail.couponDetailViewData.redeemEndDate == null
                            || $scope.couponDetail.couponDetailViewData.displayStartDate == null)*/) {
                        const confirm = $mdDialog.confirm()
                            .title('Aktiválás megerősítése')
                            .textContent('Aktiválás esetén a kupont élesítjük.')
                            .ariaLabel('Aktiválás megerősítése')
                            .ok('Aktiválás')
                            .cancel('Mégse');
                        $mdDialog.show(confirm).then(function () {
                            sendCoupon($scope.couponForm1, true).then(function () {
                                CouponService.activateOrDeactivateCoupon(couponId, 'activate', $scope.datesTimeZone).then(function (result) {
                                    if (result.errorCode == 0) {
                                        const alert = $mdDialog.alert()
                                            .textContent('A kupon aktivációja sikeres.')
                                            .ariaLabel('Sikeres aktiváció')
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $rootScope.isDataSheetModified = false;
                                            $state.reload();
                                        });
                                    } else if (result.errorCode == 6) {
                                        $mdDialog.show({
                                            template:
                                                '<md-dialog>' +
                                                '  <md-dialog-content class="md-dialog-content">' +
                                                'Kupon aktiválását egyelőre csak Yalty ügyintéző tudja végezni. ' +
                                                'Kérjük hívja Yalty partneri kapcsolattartóját vagy kérje kuponja aktiválását az ' +
                                                '<a href="mailto:ugyfelszolgalat@yalty.com?subject=Kupon%20aktiválása%20-%20{{compNamePromo}}%20|%20{{promotionName}}">ugyfelszolgalat@yalty.com</a> címünkön' +
                                                '  </md-dialog-content>' +
                                                '  <md-dialog-actions>' +
                                                '    <md-button ng-click="closeDialog()" class="md-primary">' +
                                                '      Rendben' +
                                                '    </md-button>' +
                                                '  </md-dialog-actions>' +
                                                '</md-dialog>',
                                            locals: {
                                                compNamePromo: $scope.compNamePromo,
                                                promotionName: $scope.promotionName
                                            },
                                            controller: DialogCtrl
                                        });
                                    } else if (result.errorCode == 7) {
                                        const alert = $mdDialog.alert()
                                            .title("FIGYELEM: A promóciót nem aktiváltuk!")
                                            .textContent("A promócióhoz nem tartozik olyan üzlet, amely aktív Yalty városhoz tartozik. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                                + "Állítsa be a promócióhoz tartozó üzlet adatlapján a Yalty város mezőt és ezután aktiválja újra a promóciót.\n\n"
                                                + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $scope.sendButtonDisabled = true;
                                            $rootScope.isDataSheetModified = false;
                                        });
                                    } else if (result.errorCode == 8) {
                                        const alert = $mdDialog.alert()
                                            .title("FIGYELEM: A promóciót aktiváltuk, de egy vagy több, a promócióhoz tartozó üzlet városához nem fog megjelenni!")
                                            .textContent("A promócióhoz tartozik egy vagy több olyan üzlet, amely nem köthető Yalty városhoz. Egy adott üzlet akkor tartozik Yalty városhoz, ha az üzlet oldalán az üzlet városa ténylegesen egy Yalty város vagy az oldalon a Yalty város mezőben ki van választva egy város vagy ha az üzlet címe alapján egy Yalty város adott sugarú környezetén belül van.\n\n"
                                                + "Állítsa be a Yalty város mezőt a promócióhoz tartozó ezen üzlet(ek) adatlapján.\n\n"
                                                + "Egyedi eset kezeléséhez kérjük hívja Yalty partneri kapcsolattartóját.")
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {
                                            $rootScope.isDataSheetModified = false;
                                            $scope.sendButtonDisabled = false;
                                            $state.reload();
                                        });
                                    } else {
                                        const alert = $mdDialog.alert()
                                            .textContent(result.msg)
                                            .ariaLabel(result.msg)
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () {

                                        });
                                    }
                                });
                            }, function () { })
                        }, function () {

                        });
                    } else {
                        const alert = $mdDialog.alert()
                            /*.title("Érvénytelen dátum mezők")
                            .textContent("A megadott dátumok nem felelnek meg.")
                            .ariaLabel('Activation failure.')*/
                            .htmlContent("A Promóció megjelenési és beváltási idejei helytelenek. Kérjük ellenőrizze az alábbiakat:<br>" +
                                "- A \"Kupon promóció beválthatóságának kezdő időpontja\" dátuma egyenlő vagy nagyobb kell legyen a \"Kupon promóció " +
                                "megjelenésének kezdő időpontja\" dátumával (tehát itt csak a dátum részekre kell ez teljesüljön, az időrészekre nem feltétlen).<br>" +
                                "- És a \"Kupon promóció beválthatóságának kezdő időpontja\" kisebb kell legyen a \"Kupon promóció beválthatóságának vég időpontjánál\".")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            if ($scope.redeemStartDate == null && $scope.redeemEndDate == null && ($scope.displayStartDate == null && $scope.isDisplayStartDateActualOnActivation == false)) {
                                $scope.invalidErrorInDisplayStart = true;
                                $scope.invalidErrorInDisplayStartDate = true;
                                $scope.invalidErrorInRedeemStartDate = true;
                                $scope.invalidErrorInRedeemStartDateInBeginTime = true;
                                $scope.invalidErrorInRedeemEndDate = true;
                                $scope.invalidErrorInRedeemEndDateInEndTime = true;
                                $('.main').animate({ scrollTop: $('md-datepicker#redeemStartDate').offset().top - 150 }, "slow");
                            } else if ($scope.redeemStartDate == null && $scope.redeemEndDate == null && $scope.isDisplayStartDateActualOnActivation == true) {
                                $scope.invalidErrorInRedeemStartDate = true;
                                $scope.invalidErrorInRedeemStartDateInBeginTime = true;
                                $scope.invalidErrorInRedeemEndDate = true;
                                $scope.invalidErrorInRedeemEndDateInEndTime = true;
                                $('.main').animate({ scrollTop: $('md-datepicker#redeemStartDate').offset().top - 150 }, "slow");
                            } else if (($scope.displayStartDate == null && $scope.isDisplayStartDateActualOnActivation == false)) {
                                $scope.invalidErrorInDisplayStart = true;
                                $scope.invalidErrorInDisplayStartDate = true;
                                $('.main').animate({ scrollTop: $('md-datepicker#redeemStartDate').offset().top - 150 }, "slow");
                            } else if (moment($scope.displayStartDate).isAfter($scope.redeemStartDate, 'day') || $scope.redeemStartDate == null) {
                                $scope.invalidErrorInRedeemStartDate = true;
                                $scope.invalidErrorInRedeemStartDateInBeginTime = true;
                                $('.main').animate({ scrollTop: $('md-datepicker#redeemStartDate').offset().top - 150 }, "slow");
                            } else if (moment($scope.redeemStartDate).isSameOrAfter($scope.redeemEndDate) || moment($scope.displayStartDate).isAfter($scope.redeemEndDate) || $scope.redeemEndDate == null) {
                                $scope.invalidErrorInRedeemEndDate = true;
                                $scope.invalidErrorInRedeemEndDateInEndTime = true;
                                $('.main').animate({ scrollTop: $('md-datepicker#redeemEndDate').offset().top - 150 }, "slow");
                            }
                        });
                    }
                } else {
                    $mdDialog.show({
                        template:
                            '<md-dialog>' +
                            '  <md-dialog-content class="md-dialog-content">' +
                            'Kupon aktiválását egyelőre csak Yalty ügyintéző tudja végezni. ' +
                            'Kérjük hívja Yalty partneri kapcsolattartóját vagy kérje kuponja aktiválását az ' +
                            '<a href="mailto:ugyfelszolgalat@yalty.com?subject=Kupon%20aktiválása%20-%20{{compNamePromo}}%20|%20{{promotionName}}">ugyfelszolgalat@yalty.com</a> címünkön' +
                            '  </md-dialog-content>' +
                            '  <md-dialog-actions>' +
                            '    <md-button ng-click="closeDialog()" class="md-primary">' +
                            '      Rendben' +
                            '    </md-button>' +
                            '  </md-dialog-actions>' +
                            '</md-dialog>',
                        locals: {
                            compNamePromo: $scope.compNamePromo,
                            promotionName: $scope.promotionName
                        },
                        controller: DialogCtrl
                    });
                }
            } else {
                const confirm = $mdDialog.confirm()
                    .title('Törlés megerősítése')
                    .htmlContent('A promóció törlése esetén a kupon megjelenését visszavonjuk.' + '<br>' + 'A felhasználói eszközökről következő frissítésük alkalmával a kupon törlésre kerül.')
                    .ariaLabel('Törlés megerősítése')
                    .ok('Törlés')
                    .cancel('Mégse');
                $mdDialog.show(confirm).then(function () {
                    CouponService.activateOrDeactivateCoupon(couponId, actionType, null).then(function (result) {
                        if (result.errorCode == 0) {
                            const alert = $mdDialog.alert()
                                .title('A promóciót sikeresen töröltük.')
                                .ariaLabel('Sikeres deaktiváció')
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $state.reload();
                            });
                        } else if (result.errorCode == 5) {
                            const alert = $mdDialog.alert()
                                .htmlContent("A Promóció megjelenési és beváltási idejei helytelenek. Kérjük ellenőrizze az alábbiakat:<br>" +
                                    "- A \"Kupon promóció beválthatóságának kezdő időpontja\" dátuma egyenlő vagy nagyobb kell legyen a \"Kupon promóció " +
                                    "megjelenésének kezdő időpontja\" dátumával (tehát itt csak a dátum részekre kell ez teljesüljön, az időrészekre nem feltétlen).<br>" +
                                    "- És a \"Kupon promóció beválthatóságának kezdő időpontja\" kisebb kell legyen a \"Kupon promóció beválthatóságának vég időpontjánál\".")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {

                            });
                        } else {
                            const alert = $mdDialog.alert()
                                .textContent(result.msg)
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {

                            });
                        }
                    });
                }, function () {

                });
            }
        }
        var modifyCoupon = function (couponId, isTemplate) {
            resetVariables();
            if (isTemplate == false) {
                $scope.couponId = couponId;
            }
            CouponService.modifyCoupon(couponId).then(function (result) {
                if (result.errorCode == 0) {
                    CouponService.getCouponCollections(result.couponDetailViewData.couponCollectionId,
                        result.couponDetailViewData.couponPromoStatusCode).then(function (collectionRes) {
                            const collections = collectionRes.couponCollections ? collectionRes.couponCollections.recordset : []
                            $scope.showSetCoupon = true;
                            $scope.buttonClass = "btn";
                            $scope.newButtonClass = "btn";
                            $scope.animateMobile = "yalty-animateTo-mobile";
                            $scope.showCoupons = false;
                            $scope.openVirtual = { "margin-top": "0px" };
                            $scope.couponDetail = result;
                            setCouponCollectionOptions(collections, collectionRes.errorCode)
                            $scope.couponCollection = result.couponDetailViewData.couponCollectionId || 0
                            $scope.appearsOutsideOfCollection = result.couponDetailViewData.appearsOutsideOfCollection || false
                            var year = $filter('date')(result.couponDetailViewData.lastUpdatedTimestamp, "yyyy");
                            var month = $filter('date')(result.couponDetailViewData.lastUpdatedTimestamp, "MM");
                            var day = $filter('date')(result.couponDetailViewData.lastUpdatedTimestamp, "dd");
                            var monthInHungarian = getMonth(month);
                            $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";
                            $scope.detailImage = result.couponDetailViewData.couponListViewImgURL;
                            $scope.compNamePromo = result.couponDetailViewData.compNamePromo;
                            $scope.isCompNamePromoSameAsCo = result.couponDetailViewData.isCompNamePromoSameAsCo;
                            $scope.promotionConditionsDescr = (result.couponDetailViewData.promotionConditionsDescr);
                            $scope.promotionDetailedDescr = (result.couponDetailViewData.promotionDetailedDescr);
                            $scope.listViewImgBlackOverlayOpacityValue = result.couponDetailViewData.listViewImgBlackOverlayOpacity
                            $scope.initOpacityValue()

                            $scope.isEnglishTranslationEnabled = $scope.isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt'
                            $scope.translationEnabled = $scope.isTranslation_EN_Enabled
                            $scope.languages = [
                                { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                                {
                                    id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                                    url: "../imgs/translation_flag_EN.png",
                                    windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                                }
                            ]
                            $scope.languageSelector = 1
                            $scope['EN'] = {}
                            $scope['EN'].compNamePromo = result.couponDetailViewData.compNamePromo_EN
                            $scope['EN'].promotionName = result.couponDetailViewData.promotionName_EN
                            $scope['EN'].discountText = result.couponDetailViewData.discountText_EN
                            $scope['EN'].discountText2 = result.couponDetailViewData.discountText2_EN
                            $scope['EN'].promotionDetailedDescr = result.couponDetailViewData.promotionDetailedDescr_EN
                            $scope['EN'].promotionConditionsDescr = result.couponDetailViewData.promotionConditionsDescr_EN
                            $scope.translatedCompNamePromo = result.couponDetailViewData.compNamePromo_EN
                            $scope.translatedPromotionName = result.couponDetailViewData.promotionName_EN
                            $scope.translatedDiscountText = result.couponDetailViewData.discountText_EN
                            $scope.translatedDiscountText2 = result.couponDetailViewData.discountText2_EN
                            $scope.translatedPromotionDetailedDescr = result.couponDetailViewData.promotionDetailedDescr_EN
                            $scope.translatedPromotionConditionsDescr = result.couponDetailViewData.promotionConditionsDescr_EN

                            if (isTemplate == true) {
                                var imageListForCanvas = new Image();
                                var canvas = document.getElementById("listImageCanvas");
                                var context = canvas.getContext("2d");
                                imageListForCanvas.src = result.couponDetailViewData.couponListViewImgURL;
                                imageListForCanvas.onload = function () {
                                    context.canvas.height = imageListForCanvas.height;
                                    context.canvas.width = imageListForCanvas.width;
                                    context.drawImage(imageListForCanvas, 0, 0);
                                    $rootScope.listCropperData.listCroppedImage = canvas.toDataURL("image/jpeg", 0.9).replace("image/jpeg", "image/jpg");
                                }

                                var imageDetailForCanvas = new Image();
                                imageDetailForCanvas.src = result.couponDetailViewData.couponDetailViewImgURL;
                                var canvasDetail = document.getElementById("detailImageCanvas");
                                var contextDetail = canvasDetail.getContext("2d");
                                imageDetailForCanvas.onload = function () {
                                    contextDetail.canvas.height = imageDetailForCanvas.height;
                                    contextDetail.canvas.width = imageDetailForCanvas.width;
                                    contextDetail.drawImage(imageDetailForCanvas, 0, 0);
                                    $rootScope.detailCropperData.detailCroppedImage = canvasDetail.toDataURL("image/jpeg", 0.9).replace("image/jpeg", "image/jpg");
                                }

                                var imageBarcodeForCanvas = new Image();
                                imageBarcodeForCanvas.src = result.couponDetailViewData.barcodeSourceImgURL;
                                var canvasBarcode = document.getElementById("barcodeImgCanvas");
                                var contextBarcode = canvasBarcode.getContext("2d");
                                imageBarcodeForCanvas.onload = function () {
                                    contextBarcode.canvas.height = imageBarcodeForCanvas.height;
                                    contextBarcode.canvas.width = imageBarcodeForCanvas.width;
                                    contextBarcode.drawImage(imageBarcodeForCanvas, 0, 0);
                                    $rootScope.barcodeCropperData.barcodeImg = canvasBarcode.toDataURL("image/png", 0.9);
                                }

                                /*if ((result.couponDetailViewData.promotionName + " (MÁSOLAT)").length <= 33) {*/
                                    $scope.promotionName = result.couponDetailViewData.promotionName/* + " (MÁSOLAT)";
                                } else {
                                    var slice;
                                    if (result.couponDetailViewData.promotionName.length > 20) {
                                        slice = result.couponDetailViewData.promotionName.slice(0, 20);
                                    }
                                    $scope.promotionName = slice + "... (MÁSOLAT)";
                                }*/
                                var now = new Date();
                                var date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes());
                                $scope.displayStartDate = date;
                                $scope.isDisplayStartDateActualOnActivation = true;
                                $scope.displayStartDateInBegin = false;
                                $scope.redeemStartDate = new Date();
                                $scope.redeemStartDate.setHours(0, 0);
                                $scope.redeemStartDate.setSeconds(0, 0);
                                $scope.redeemStartDateInBegin = true;
                                $scope.redeemEndDate = new Date();
                                $scope.redeemEndDate.setMonth($scope.redeemStartDate.getMonth() + 1);
                                $scope.redeemEndDate.setHours(23, 59);
                                $scope.redeemEndDate.setSeconds(0, 0);
                                $scope.redeemEndDateInEnd = true;
                                $scope.companyStores = [];
                                // if (result.couponDetailViewData.redeemPartnerResponseRequired == false && result.couponDetailViewData.redeemType == 0) {
                                //     $scope.couponRedeemTypes.splice(2, 0, {
                                //         id: 3, type: 0, listName: 'Kupon képernyőn felmutatása opcionális Yalty partneri visszaigazolással',
                                //         redeemPartnerResponseRequired: false
                                //     })
                                // }
                                if (result.couponDetailViewData.redeemType == 0) result.couponDetailViewData.redeemType = 100
                                const redeemTypeIndex = $scope.couponRedeemTypes.findIndex(x => x.type == result.couponDetailViewData.redeemType &&
                                    x.redeemPartnerResponseRequired == result.couponDetailViewData.redeemPartnerResponseRequired)
                                $scope.redeemType = $scope.couponRedeemTypes[redeemTypeIndex].id
                                $scope.initRedeemType = $scope.redeemType
                                $scope.barcodeType = result.couponDetailViewData.barcodeType
                                $scope.barcodeSourceType = result.couponDetailViewData.barcodeSourceType != null ?
                                    Number(result.couponDetailViewData.barcodeSourceType) : 1
                                $scope.barcodeSubType = result.couponDetailViewData.barcodeSubType
                                $scope.barcodeSourceCode = result.couponDetailViewData.barcodeSourceCode
                                $scope.codeValidation()
                                $rootScope.isDataSheetModified = true;
                            } else {
                                $rootScope.listCropperData.listCroppedImage = result.couponDetailViewData.couponListViewImgURL;
                                $rootScope.detailCropperData.detailCroppedImage = result.couponDetailViewData.couponDetailViewImgURL;
                                $rootScope.barcodeCropperData.barcodeImg = result.couponDetailViewData.barcodeSourceImgURL;
                                if (result.couponDetailViewData.redeemType == 0) result.couponDetailViewData.redeemType = 100
                                const redeemTypeIndex = $scope.couponRedeemTypes.findIndex(x => x.type == result.couponDetailViewData.redeemType &&
                                    x.redeemPartnerResponseRequired == result.couponDetailViewData.redeemPartnerResponseRequired)
                                if (redeemTypeIndex != -1) $scope.redeemType = $scope.couponRedeemTypes[redeemTypeIndex].id
                                $scope.initRedeemType = $scope.redeemType
                                $scope.barcodeType = result.couponDetailViewData.barcodeType
                                $scope.barcodeSourceType = result.couponDetailViewData.barcodeSourceType != null ?
                                    Number(result.couponDetailViewData.barcodeSourceType) : 1
                                $scope.barcodeSubType = result.couponDetailViewData.barcodeSubType
                                $scope.barcodeSourceCode = result.couponDetailViewData.barcodeSourceCode
                                $scope.codeValidation()
                                $scope.promotionName = result.couponDetailViewData.promotionName;
                                $scope.couponPromoStatusText = $scope.promoStatusText[result.couponDetailViewData.couponPromoStatusCode].text;
                                $scope.couponPromoStatusCode = $scope.promoStatusText[result.couponDetailViewData.couponPromoStatusCode].code;
                                if ($scope.couponPromoStatusCode == 1) {
                                    $scope.isDisplayStartDateActualOnActivation = result.couponDetailViewData.isDisplayStartDateActualOnActivation;
                                } else {
                                    $scope.isDisplayStartDateActualOnActivation = result.couponDetailViewData.isDisplayStartDateActualOnActivation;
                                }
                                if (result.couponDetailViewData.displayStartDate != null) {
                                    var displayStart = new Date(result.couponDetailViewData.displayStartDate);
                                    $scope.displayStartDate = new Date(displayStart.getFullYear(), displayStart.getMonth(), displayStart.getDate(), displayStart.getHours(), displayStart.getMinutes());
                                    $scope.displayStartDate.setUTCMinutes(displayStart.getMinutes() + displayStart.getTimezoneOffset());
                                    const timeZoneOffset = moment.tz.zone($scope.datesTimeZone).utcOffset(displayStart.getTime());
                                    $scope.displayStartDate.setUTCMinutes(displayStart.getMinutes() - timeZoneOffset);
                                    if ($scope.displayStartDate.getHours() == 0 && $scope.displayStartDate.getMinutes() == 0) {
                                        $scope.displayStartDateInBegin = true;
                                    } else {
                                        $scope.displayStartDateInBegin = false;
                                    }
                                } else {
                                    $scope.displayStartDate = null;
                                    $scope.displayStartDateInBegin = false;
                                }
                                if (result.couponDetailViewData.redeemStartDate != null) {
                                    var redeemStart = new Date(result.couponDetailViewData.redeemStartDate);
                                    $scope.redeemStartDate = new Date(redeemStart.getFullYear(), redeemStart.getMonth(), redeemStart.getDate(), redeemStart.getHours(), redeemStart.getMinutes());
                                    $scope.redeemStartDate.setUTCMinutes(redeemStart.getMinutes() + redeemStart.getTimezoneOffset());
                                    const timeZoneOffset = moment.tz.zone($scope.datesTimeZone).utcOffset(redeemStart.getTime());
                                    $scope.redeemStartDate.setUTCMinutes(redeemStart.getMinutes() - timeZoneOffset);
                                    if ($scope.redeemStartDate.getHours() == 0 && $scope.redeemStartDate.getMinutes() == 0) {
                                        $scope.redeemStartDateInBegin = true;
                                    } else {
                                        $scope.redeemStartDateInBegin = false;
                                    }
                                } else {
                                    $scope.redeemStartDate = null;
                                    $scope.redeemStartDateInBegin = false;
                                }
                                if (result.couponDetailViewData.redeemEndDate != null) {
                                    var redeemEnd = new Date(result.couponDetailViewData.redeemEndDate);
                                    $scope.redeemEndDate = new Date(redeemEnd.getFullYear(), redeemEnd.getMonth(), redeemEnd.getDate(), redeemEnd.getHours(), redeemEnd.getMinutes());
                                    $scope.redeemEndDate.setUTCMinutes(redeemEnd.getMinutes() + redeemEnd.getTimezoneOffset());
                                    const timeZoneOffset = moment.tz.zone($scope.datesTimeZone).utcOffset(redeemEnd.getTime());
                                    $scope.redeemEndDate.setUTCMinutes(redeemEnd.getMinutes() - timeZoneOffset);
                                    if ($scope.redeemEndDate.getHours() == 23 && $scope.redeemEndDate.getMinutes() == 59) {
                                        $scope.redeemEndDateInEnd = true;
                                    } else {
                                        $scope.redeemEndDateInEnd = false;
                                    }
                                } else {
                                    $scope.redeemEndDate = null;
                                    $scope.redeemEndDateInEnd = false;
                                }
                                if (result.couponDetailViewData.redeemLocationIndicator == "all") {
                                    $scope.allRedeemLocationIndicator = "all";
                                    $scope.isAllRedeemLocationIndicator = true;
                                    setAllCompStores();
                                } else {
                                    $scope.allRedeemLocationIndicator = "select";
                                    $scope.isAllRedeemLocationIndicator = false;
                                    for (var i = 0; i < result.couponRedeemLocations.length; i++) {
                                        company.push(result.couponRedeemLocations[i].companyStoreId);
                                    }
                                    $scope.companyStores = company;
                                }
                                $rootScope.isDataSheetModified = false;
                            }
                            $scope.promoCategory = result.couponDetailViewData.promoCategoryId;
                            if (result.couponDetailViewData.promotionType == 1) {
                                $scope.data.model = $scope.names[0];
                                $scope.discountAmountPercent = result.couponDetailViewData.discountAmount;
                                $scope.amount = $scope.discountAmountPercent;
                            } else if (result.couponDetailViewData.promotionType == 2) {
                                $scope.data.model = $scope.names[1];
                                $scope.discountAmount = result.couponDetailViewData.discountAmount;
                                $scope.amount = $scope.discountAmount;
                            } else if (result.couponDetailViewData.promotionType == 3) {
                                $scope.data.model = $scope.names[2];
                                $scope.discountOldPrice = result.couponDetailViewData.discountOldPrice;
                                $scope.discountAmount = result.couponDetailViewData.discountAmount;
                                $scope.amount = $scope.discountAmount;
                            } else if (result.couponDetailViewData.promotionType == 4) {
                                $scope.data.model = $scope.names[3];
                                $scope.discountAmount = result.couponDetailViewData.discountAmount;
                                $scope.amount = $scope.discountAmount;
                            } else if (result.couponDetailViewData.promotionType == 5) {
                                $scope.data.model = $scope.names[4];
                                $scope.discountText = result.couponDetailViewData.discountText;
                                $scope.amount = $scope.discountText;
                            } else if (result.couponDetailViewData.promotionType == 6) {
                                $scope.data.model = $scope.names[5];
                                $scope.discountText = result.couponDetailViewData.discountText;
                                $scope.discountText2 = result.couponDetailViewData.discountText2;
                                $scope.amount = $scope.discountText;
                            } else if (result.couponDetailViewData.promotionType == 0) {
                                $scope.data.model = $scope.names[6];
                                $scope.amount = "";
                            }
                            if (result.couponDetailViewData.redeemOveralLimitation == "limited") {
                                $scope.redeemOveralLimitation = true;
                                $scope.redeem.redeemOveralAmount = result.couponDetailViewData.redeemOveralAmount;
                            } else {
                                $scope.redeemOveralLimitation = false;
                                $scope.redeem.redeemOveralAmount = null;
                            }
                            if (result.couponDetailViewData.redeemPerUserLimitation == "limited") {
                                $scope.redeemPerUserLimitation = true;
                                $scope.redeem.redeemPerUserAmount = result.couponDetailViewData.redeemPerUserAmount;
                            } else {
                                $scope.redeemPerUserLimitation = false;
                                $scope.redeem.redeemPerUserAmount = null;
                            }
                            $scope.redeemPartnerResponseRequired = result.couponDetailViewData.redeemPartnerResponseRequired;

                        })
                } else {
                    const alert = $mdDialog.alert()
                        .title('Sikertelen azonosítás')
                        .textContent('Felhasználó azonosítása sikertelen, ezért ki kell léptetnünk az oldalról.')
                        .ariaLabel('Session expired.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $rootScope.isDataSheetModified = false;
                        $cookies.remove('firstName');
                        $cookies.remove('lastName');
                        $cookies.remove('nameTitle');
                        $cookies.remove('areRedeemPageFilterSettingsPredefined');
                        $cookies.remove('defaultRedeemPageShowFilter');
                        $cookies.remove('defaultRedeemPageCityFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('userEmail');
                        $cookies.remove('userCompany');
                        $cookies.remove('compLogo');
                        $cookies.remove('authorizationLevel');
                        $cookies.remove('currentUserType');
                        $cookies.remove('expdt');
                        $cookies.remove('alrtdt');
                        $cookies.remove('redeemPageShowFilter');
                        $cookies.remove('redeemPageCompanyStoreFilter');
                        $cookies.remove('redeemPageCityFilter');
                        $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
                        $cookies.remove('redeemPageCityFilterCheckAll');
                        $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
                        $cookies.remove('canSendIndividualPushMessages');
                        $cookies.remove('freePackageSubscriptionExpTimestamp');
                        $cookies.remove('isYaltyStartEnabled');
                        $window.localStorage.clear();
                        $rootScope.authorizationLevel = null;
                        $rootScope.$$listeners['timer-tick'] = [];
                        $rootScope.$$listeners['timer-stopped'] = [];
                        $rootScope.$broadcast('timer-stop');
                        $state.go('partner_HU.login');
                    });
                }
            }, function () {
                const alert = $mdDialog.alert()
                    .title('Sikertelen azonosítás')
                    .textContent('Felhasználó azonosítása sikertelen, ezért ki kell léptetnünk az oldalról.')
                    .ariaLabel('Session expired.')
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () {
                    $http.post(API_PREFIX.url + '/partner/logoutUser', '').then(function (result) {
                        $rootScope.isDataSheetModified = false;
                        $cookies.remove('firstName');
                        $cookies.remove('lastName');
                        $cookies.remove('nameTitle');
                        $cookies.remove('areRedeemPageFilterSettingsPredefined');
                        $cookies.remove('defaultRedeemPageShowFilter');
                        $cookies.remove('defaultRedeemPageCityFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('userEmail');
                        $cookies.remove('userCompany');
                        $cookies.remove('compLogo');
                        $cookies.remove('authorizationLevel');
                        $cookies.remove('currentUserType');
                        $cookies.remove('expdt');
                        $cookies.remove('alrtdt');
                        $cookies.remove('redeemPageShowFilter');
                        $cookies.remove('redeemPageCompanyStoreFilter');
                        $cookies.remove('redeemPageCityFilter');
                        $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
                        $cookies.remove('redeemPageCityFilterCheckAll');
                        $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
                        $cookies.remove('canSendIndividualPushMessages');
                        $cookies.remove('freePackageSubscriptionExpTimestamp');
                        $cookies.remove('isYaltyStartEnabled');
                        $window.localStorage.clear();
                        $rootScope.authorizationLevel = null;
                        $rootScope.$$listeners['timer-tick'] = [];
                        $rootScope.$$listeners['timer-stopped'] = [];
                        $rootScope.$broadcast('timer-stop');
                        $state.go('partner_HU.login');
                    }, function () {
                        $rootScope.isDataSheetModified = false;
                        $cookies.remove('firstName');
                        $cookies.remove('lastName');
                        $cookies.remove('nameTitle');
                        $cookies.remove('areRedeemPageFilterSettingsPredefined');
                        $cookies.remove('defaultRedeemPageShowFilter');
                        $cookies.remove('defaultRedeemPageCityFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreFilter');
                        $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('userEmail');
                        $cookies.remove('userCompany');
                        $cookies.remove('compLogo');
                        $cookies.remove('authorizationLevel');
                        $cookies.remove('currentUserType');
                        $cookies.remove('expdt');
                        $cookies.remove('alrtdt');
                        $cookies.remove('redeemPageShowFilter');
                        $cookies.remove('redeemPageCompanyStoreFilter');
                        $cookies.remove('redeemPageCityFilter');
                        $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
                        $cookies.remove('redeemPageCityFilterCheckAll');
                        $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
                        $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
                        $cookies.remove('canSendIndividualPushMessages');
                        $cookies.remove('freePackageSubscriptionExpTimestamp');
                        $cookies.remove('isYaltyStartEnabled');
                        $window.localStorage.clear();
                        $rootScope.authorizationLevel = null;
                        $rootScope.$$listeners['timer-tick'] = [];
                        $rootScope.$$listeners['timer-stopped'] = [];
                        $rootScope.$broadcast('timer-stop');
                        $state.go('partner_HU.login');
                    })
                });
            });
        }

        $scope.modifyCoupon = function (couponId, isTemplate) {
            if ($rootScope.isDataSheetModified == false) {
                modifyCoupon(couponId, isTemplate);
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    modifyCoupon(couponId, isTemplate);
                }, function () {
                });
            }
        }

        function setCouponCollectionOptions(collections, errorCode) {
            $scope.couponCollectionOptions = [{
                id: 0, display: 'Nem tartozik kuponkollekcióhoz'
            }]
            if (errorCode == 0) {
                $scope.failedToGetCouponCollections = false
                for (let i = 0; i < collections.length; i++) {
                    $scope.couponCollectionOptions.push({
                        id: collections[i].id,
                        display: `${collections[i].yaltyCity} - ${collections[i].collectionName} - ${$filter('date')(collections[i].redeemStartDate, 'yyyy. MMMM d.')} - ${$filter('date')(collections[i].redeemEndDate, 'yyyy. MMMM d.')}`
                    })
                }
            } else {
                $scope.failedToGetCouponCollections = true
            }
        }

        $scope.openTranslationWindow = (languageId, whereToOpen) => {
            if (languageId != 1) {
                const langIndex = $scope.languages.findIndex(item => item.id == languageId)
                $scope.windowTitleLang = $scope.languages[langIndex].windowTitleName
                $scope.langName = $scope.languages[langIndex].name
                $scope.lang = $scope.languages[langIndex].code
                $scope.translatedCompNamePromo = $scope[$scope.lang].compNamePromo
                $scope.translatedPromotionName = $scope[$scope.lang].promotionName
                $scope.translatedDiscountText = $scope[$scope.lang].discountText
                $scope.translatedDiscountText2 = $scope[$scope.lang].discountText2
                $scope.translatedPromotionDetailedDescr = $scope[$scope.lang].promotionDetailedDescr
                $scope.translatedPromotionConditionsDescr = $scope[$scope.lang].promotionConditionsDescr
                $scope.showTranslationWindow = true
                const langCode = $scope.languages[langIndex].code
                $scope.initValues = {}
                $scope.initValues[langCode] = {}
                $scope.initValues[langCode].enabled = $scope.translationEnabled
                if (!$scope.translationEnabled) {
                    $scope.translationEnabled = true
                    $scope.languageSelector = 2
                    $scope.modifyTranslationEnabled(true)
                }
                const divToScroll = whereToOpen ? whereToOpen : 'translationTable'
                $(`#${divToScroll}`).animate({ scrollTop: 0 }, "fast");
            }
        }
        $scope.modifyTranslationEnabled = (windowOpening) => {
            if ($scope.languageSelector == 2) {
                $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
                $scope.languages[1].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
            }
            const langName = $scope.langName.toLowerCase()
            if ($scope.translationEnabled) {
                let alert = $mdDialog.confirm()
                    .title(`A(z) ${langName} fordítás bekapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegeket, üzletek információit és a promóciók szövegeit is szükséges ${langName} nyelvre fordítani. A fordításban érintett oldalak:`)
                    .htmlContent(`
                    <ul class="md-title mdDialogList">
                        <li>Partneri adatok,</li>
                        <li>Üzletek (azon belül az egyes üzletek),</li>
                        <li>Hűségkártyáink (azon belül az egyes, még aktív hűségkártyák) és </li>
                        <li>Kuponjaink (azon belül az egyes, még aktív kuponok) oldalak.</li>
                    </ul>`)
                    .ok('Rendben')
                if (windowOpening) alert.cancel('Mégsem')
                $mdDialog.show(alert).catch(() => {
                    $scope.couponForm1.languageSelector.$valid = true
                    $scope.couponForm1.languageSelector.$invalid = false
                    $scope.couponForm1.languageSelector.$error = {}
                    $scope.translationEnabled = false
                    $scope.closeTranslationWindow()
                })
            } else {
                const alert = $mdDialog.alert()
                    .title(`A(z) ${langName} fordítás kikapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegek, üzletek információ és a promóciók szövege esetén kikapcsoljuk a(z) ${langName} nyelvű fordítást. Tehát minden, a fordításban érintett oldalon:`)
                    .htmlContent(`
                        <ul class="md-title mdDialogList">
                            <li>Partneri adatok,</li>
                            <li>Üzletek (azon belül az egyes üzletek),</li>
                            <li>Hűségkártyáink (azon belül az egyes hűségkártyák) és</li>
                            <li>Kuponjaink (azon belül az egyes kuponok) - ki lesz így kapcsolva.</li>
                        </ul>
                    `)
                    .ok('Rendben')
                $mdDialog.show(alert).then(() => {
                    $scope.couponForm1.languageSelector.$valid = true
                    $scope.couponForm1.languageSelector.$invalid = false
                    $scope.couponForm1.languageSelector.$error = {}
                })
            }

        }
        $scope.closeTranslationWindow = () => {
            $scope.languageSelector = 1
            $scope.showTranslationWindow = false
            $scope.translationEnabled = $scope.initValues['EN'].enabled
            $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
            $scope.languages[$scope.languageSelector].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
        }
        $scope.saveTranslation = () => {
            $scope[$scope.lang].compNamePromo = $scope.translatedCompNamePromo
            $scope[$scope.lang].promotionName = $scope.translatedPromotionName
            $scope[$scope.lang].discountText = $scope.translatedDiscountText
            $scope[$scope.lang].discountText2 = $scope.translatedDiscountText2
            $scope[$scope.lang].promotionDetailedDescr = $scope.translatedPromotionDetailedDescr
            $scope[$scope.lang].promotionConditionsDescr = $scope.translatedPromotionConditionsDescr
            $scope.languageSelector = 1
            $scope.sendButtonDisabled = false
            $rootScope.isDataSheetModified = true
            if ($scope.couponForm1.translatedCompNamePromo.$valid && $scope.couponForm1.translatedPromotionName.$valid &&
                ($scope.data.model.id != 5 || $scope.couponForm1.translatedDiscountText.$valid) &&
                ($scope.data.model.id != 6 || ($scope.couponForm1.translatedDiscountText.$valid && $scope.couponForm1.translatedDiscountText2.$valid)) &&
                $scope.couponForm1.translatedPromotionDetailedDescr.$valid && $scope.couponForm1.translatedPromotionConditionsDescr.$valid) {
                $scope.couponForm1.languageSelector.$valid = true
                $scope.couponForm1.languageSelector.$invalid = false
                $scope.couponForm1.languageSelector.$error = {}
            }
            $scope.showTranslationWindow = false

        }

        $scope.copyToTranslationField = (fieldName, copyFromPartnerData) => {
            const translationFieldName = 'translated' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
            if (copyFromPartnerData) {
                if ($scope.partnerData[translationFieldName]) {
                    $scope[translationFieldName] = $scope.partnerData[translationFieldName]
                }
            } else if ($scope[fieldName]) {
                $scope[translationFieldName] = $scope[fieldName]
            }
        }

        if ($stateParams.couponId != 0) {
            $scope.modifyCoupon($stateParams.couponId, false);
        } else {
            $scope.showCoupons = true;
            $scope.buttonClass = "activeBtn";
            $scope.newButtonClass = "btn";
            $scope.animateMobile = "";
        }
        $scope.removeCoupon = function (ev) {
            var confirm = $mdDialog.confirm()
                .title('Biztosan törölni szeretné ezt a kupont?')
                .textContent('Igen válasz esetén a kupon törlésre kerül.')
                .ariaLabel('Törlés megerősítése')
                .targetEvent(ev)
                .ok('Igen')
                .cancel('Mégsem');

            $mdDialog.show(confirm).then(function () {
                if ($scope.couponId) {
                    if ($rootScope.isDataSheetModified == true) {
                        $rootScope.isDataSheetModified = false;
                    }
                    var coupon = {};
                    coupon.couponId = $scope.couponId;
                    $http.post(API_PREFIX.url + '/coupon/removeCoupon_v2', coupon).then(function (result) {
                        if (result.data.errorCode == 0) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_törlés_sikeres",
                                "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon törlés sikeres")
                        } else {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_törlés_sikertelen",
                                "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon törlés sikertelen")
                        }
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(result.data.msg)
                                .ok('Rendben')
                                .targetEvent(ev)
                        ).then(function () {
                            $state.reload();
                        });
                    }, function () {
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_törlés_sikertelen",
                            "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon törlés sikertelen")
                    });
                }
            }, function () {
            });
        }

        $scope.updateCoupon = function (param) {
            if (param == 'name') {
                if ($scope.promotionName) {
                    $scope.promotionName = $scope.promotionName.trim();
                }
                if ($scope.promotionName != (null || "" || undefined)) {
                    $scope.newCoupon = null;
                } else if (($scope.promotionName == (null || undefined || "")) || angular.isUndefined($scope.promotionName)) {
                    $scope.newCoupon = "ÚJ KUPON";
                    //$scope.promotionName = null;
                }
            } else if (param == 'select') {
                $scope.discountOldPrice = undefined;
                $scope.discountText = undefined;
                $scope.discountText2 = undefined;
                $scope.discountAmount = undefined;
                $scope.discountAmountPercent = undefined;
                $scope.amount = undefined;

                $scope['EN'].discountText = undefined
                $scope['EN'].discountText2 = undefined

                $scope.translatedDiscountText = undefined
                $scope.translatedDiscountText2 = undefined
            } else if (param == 'model') {
                if ($scope.data.model.id == 1) {
                    $scope.amount = $scope.discountAmountPercent;
                    $scope.discountOldPrice = undefined;
                    $scope.discountText = undefined;
                    $scope.discountText2 = undefined;
                    $scope.discountAmount = undefined;
                } else if ($scope.data.model.id == 2) {
                    $scope.amount = $scope.discountAmount;
                    $scope.discountOldPrice = undefined;
                    $scope.discountAmountPercent = undefined;
                    $scope.discountText = undefined;
                    $scope.discountText2 = undefined;
                } else if ($scope.data.model.id == 3) {
                    $scope.amount = $scope.discountAmount;
                    $scope.discountText = undefined;
                    $scope.discountAmountPercent = undefined;
                    $scope.discountText2 = undefined;
                } else if ($scope.data.model.id == 4) {
                    $scope.amount = $scope.discountAmount;
                    $scope.discountOldPrice = undefined;
                    $scope.discountAmountPercent = undefined;
                    $scope.discountText = undefined;
                    $scope.discountText2 = undefined;
                } else if ($scope.data.model.id == 5) {
                    $scope.amount = $scope.discountText;
                    $scope.discountOldPrice = undefined;
                    $scope.discountAmount = undefined;
                    $scope.discountAmountPercent = undefined;
                    $scope.discountText2 = undefined;
                } else if ($scope.data.model.id == 6) {
                    $scope.amount = $scope.discountText;
                    $scope.discountOldPrice = undefined;
                    $scope.discountAmountPercent = undefined;
                    $scope.discountAmount = undefined;
                } else if ($scope.data.model.id == 0) {
                    $scope.discountOldPrice = undefined;
                    $scope.discountText = undefined;
                    $scope.discountText2 = undefined;
                    $scope.discountAmountPercent = undefined;
                }
            }
            $scope.discountTextChanged()
        }

        $scope.discountTextChanged = function () {
            if ($scope.data.model.id == 5 || $scope.data.model.id == 6) {
                setDiscountStyle('discountText', 'discountTextStyle', 148);
                setDiscountStyle('discountText2', 'discountText2Style', 148);
            }
            // const width = document.getElementById("discount-value-label-container").offsetWidth
            // document.getElementById('discount-value-label-container').setAttribute("style",`width:${width}px`);
            // console.log(width);
            // if (width > 160) {
            //     $scope.discountTextAndLabelStyle = {
            //         'flex-direction': 'column'
            //     }
            //     $scope.discountText
            // } else if () $scope.discountTextAndLabelStyle = undefined
        }

        function setDiscountStyle(discountField, style, widthToCheck) {
            const width = getTextWidth($scope[discountField], '18px Helvetica-bold-preview')
            if (width > widthToCheck) {
                const widthWithSmallerChars = getTextWidth($scope[discountField], '14px Helvetica-bold-preview')
                if (widthWithSmallerChars > widthToCheck) {
                    $scope[`${discountField}PreviewMaxWidthWarning`] = true
                    $scope[style] = {
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                        'overflow': 'hidden',
                        'max-width': `${widthToCheck}px`,
                        'font-size': '14px'
                    }
                } else {
                    $scope[style] = {
                        'font-size': '14px'
                    }
                    $scope[`${discountField}PreviewMaxWidthWarning`] = false
                }
            } else {
                $scope[style] = {}
                $scope[`${discountField}PreviewMaxWidthWarning`] = false
            }
        }

        function getTextWidth(text, font) {
            const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
            const context = canvas.getContext("2d");
            context.font = font;
            const metrics = context.measureText(text);
            return metrics.width
        }

        $scope.selectCompStore = function () {
            $scope.isCompStoresSame = true;
        }

        $scope.setAppearsOutsideOfCollection = function () {
            if ($scope.couponCollection == 0) $scope.appearsOutsideOfCollection = false
        }

        const sendCoupon = function (couponForm, isActivateCouponUpdate) {
            return $q(function (resolve, reject) {
                $scope.couponForm1.redeemType.$setValidity('partnerConfirmationOff', true);
                if (!couponForm.$valid) {

                    angular.forEach(couponForm.$error, function (field) {
                        angular.forEach(field, function (errorField) {
                            errorField.$setTouched();
                        });
                    });
                    let errorInEnglishTranslation = null
                    if (!couponForm.translatedCompNamePromo.$valid) {
                        errorInEnglishTranslation = 'translatedCompNamePromo'
                    } else if (!couponForm.translatedPromotionName.$valid) {
                        errorInEnglishTranslation = 'translatedPromotionName'
                    } else if (!couponForm.translatedDiscountText.$valid) {
                        errorInEnglishTranslation = 'translatedDiscountText'
                    } else if (!couponForm.translatedDiscountText1.$valid) {
                        errorInEnglishTranslation = 'translatedDiscountText1'
                    } else if (!couponForm.translatedDiscountText2.$valid) {
                        errorInEnglishTranslation = 'translatedDiscountText2'
                    } else if (!couponForm.translatedPromotionDetailedDescr.$valid) {
                        errorInEnglishTranslation = 'translatedPromotionDetailedDescr'
                    } else if (!couponForm.translatedPromotionConditionsDescr.$valid) {
                        errorInEnglishTranslation = 'translatedPromotionConditionsDescr'
                    }

                    if (errorInEnglishTranslation) {
                        $scope.langName = 'angol'
                        $scope.couponForm1.languageSelector.$touched = true
                        $scope.couponForm1.languageSelector.$valid = false
                        $scope.couponForm1.languageSelector.$invalid = true
                        $scope.couponForm1.languageSelector.$error = { required: true }
                        document.getElementById('languageSelector').scrollIntoView()
                    }

                    if ($rootScope.listCropperData.listCroppedImage == null && $rootScope.detailCropperData.detailCroppedImage == null) {
                        couponForm.listImg.$error.need = true;
                        couponForm.detailImg.$error.need = true;
                    } else if ($rootScope.listCropperData.listCroppedImage == null) {
                        couponForm.listImg.$error.need = true;
                    } else if ($rootScope.detailCropperData.detailCroppedImage == null) {
                        couponForm.detailImg.$error.need = true;
                    }
                    if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                        angular.element($document[0].querySelector('input.ng-invalid')).focus();
                    } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                        angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                    } else if (angular.element($document[0].querySelector('md-datepicker.ng-invalid')).length > 0) {
                        angular.element($document[0].querySelector('md-datepicker.ng-invalid')).focus();
                    } else {
                        angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                    }
                    reject(false);
                } else if ($rootScope.listCropperData.listCroppedImage == null && $rootScope.detailCropperData.detailCroppedImage == null) {
                    couponForm.listImg.$error.need = true;
                    couponForm.detailImg.$error.need = true;
                    $('.main').animate({ scrollTop: $('div#listImg').offset().top - 150 }, "slow");
                    reject(false);
                } else if ($rootScope.listCropperData.listCroppedImage == null) {
                    couponForm.listImg.$error.need = true;
                    $('.main').animate({ scrollTop: $('div#listImg').offset().top - 150 }, "slow");
                    reject(false);
                } else if ($rootScope.detailCropperData.detailCroppedImage == null) {
                    couponForm.detailImg.$error.need = true;
                    $('.main').animate({ scrollTop: $('div#detailImg').offset().top - 150 }, "slow");
                    reject(false);
                } else if ($rootScope.barcodeCropperData.barcodeImg == null && $scope.redeemType == 2 && $scope.barcodeSourceType == 0) {
                    couponForm.barcodeImg.$error.need = true;
                    var element = document.getElementById("barcodePreviewContainer");
                    element.scrollIntoView();
                    reject(false);
                } else {
                    $scope.sendButtonDisabled = true;
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "block";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                    }
                    if ($scope.couponPromoStatusCode == 1) {
                        if (moment($scope.displayStartDate).isSameOrBefore($scope.redeemStartDate, 'day')
                            && moment($scope.redeemStartDate).isBefore($scope.redeemEndDate)
                            && moment($scope.displayStartDate).isBefore($scope.redeemEndDate)) {
                            var confirm = $mdDialog.confirm()
                                .title('Módosítás megerősítése')
                                .htmlContent('A kuponhoz futó promóció tartozik. Biztosan módosítja a kupon adatait?')
                                .ariaLabel('Modify confirm.')
                                .ok('Módosítás')
                                .cancel('Mégse');
                            $mdDialog.show(confirm).then(function () {
                                setCouponsData()
                            }, function () {
                                if (document.getElementById('loadingOverlay')) {
                                    document.getElementById('loadingOverlay').style.display = "none";
                                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                                }
                                $scope.sendButtonDisabled = false;
                                reject(false);
                            })
                        } else {
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            if (moment($scope.displayStartDate).isAfter($scope.redeemStartDate, 'day') || $scope.redeemStartDate == null) {
                                $scope.invalidErrorInRedeemStartDate = true;
                                $scope.invalidErrorInRedeemStartDateInBeginTime = true;
                                $('.main').animate({ scrollTop: $('md-datepicker#redeemStartDate').offset().top - 150 }, "slow");
                            } else if (moment($scope.redeemStartDate).isSameOrAfter($scope.redeemEndDate) || moment($scope.displayStartDate).isAfter($scope.redeemEndDate) || $scope.redeemEndDate == null) {
                                $scope.invalidErrorInRedeemEndDate = true;
                                $scope.invalidErrorInRedeemEndDateInEndTime = true;
                                $('.main').animate({ scrollTop: $('md-datepicker#redeemEndDate').offset().top - 150 }, "slow");
                            }
                            var alertdialog = $mdDialog.alert()
                                /*.title("Érvénytelen dátum mezők")
                                .textContent("A megadott dátumok nem felelnek meg.")
                                .ariaLabel('Activation failure.')*/
                                .htmlContent("A Promóció megjelenési és beváltási idejei helytelenek. Kérjük ellenőrizze az alábbiakat:<br>" +
                                    "- A \"Kupon promóció beválthatóságának kezdő időpontja\" dátuma egyenlő vagy nagyobb kell legyen a \"Kupon promóció " +
                                    "megjelenésének kezdő időpontja\" dátumával (tehát itt csak a dátum részekre kell ez teljesüljön, az időrészekre nem feltétlen).<br>" +
                                    "- És a \"Kupon promóció beválthatóságának kezdő időpontja\" kisebb kell legyen a \"Kupon promóció beválthatóságának vég időpontjánál\".")
                                .ok('Rendben')
                            $mdDialog.show(alertdialog).then(function () {
                                $scope.sendButtonDisabled = false;
                                reject(false);
                            });
                        }
                    } else {
                        setCouponsData()
                    }
                    function setCouponsData() {

                        var couponData = {};
                        var discountAmount;
                        if ($scope.data.model.id == 1) {
                            $scope.discountOldPrice = 0;
                            $scope.discountText = null;
                            $scope.discountText2 = null;

                            emptyTranslatedDiscountFields()

                            discountAmount = $scope.discountAmountPercent;
                        } else if ($scope.data.model.id == 2) {
                            $scope.discountOldPrice = 0;
                            $scope.discountText = null;
                            $scope.discountText2 = null;

                            emptyTranslatedDiscountFields()

                            discountAmount = $scope.discountAmount;
                        } else if ($scope.data.model.id == 3) {
                            $scope.discountText = null;
                            $scope.discountText2 = null;

                            emptyTranslatedDiscountFields()

                            discountAmount = $scope.discountAmount;
                        } else if ($scope.data.model.id == 4) {
                            $scope.discountOldPrice = 0;
                            $scope.discountText = null;
                            $scope.discountText2 = null;

                            emptyTranslatedDiscountFields()

                            discountAmount = $scope.discountAmount;
                        } else if ($scope.data.model.id == 5) {
                            $scope.discountOldPrice = 0;
                            discountAmount = 0;
                            $scope.discountText2 = null;

                            emptyTranslatedDiscountFields()

                        } else if ($scope.data.model.id == 6) {
                            $scope.discountOldPrice = 0;
                            discountAmount = 0;
                        } else if ($scope.data.model.id == 7) {
                            $scope.discountOldPrice = 0;
                            $scope.discountText = null;
                            $scope.discountText2 = null;

                            emptyTranslatedDiscountFields()

                            discountAmount = 0;
                        }

                        function emptyTranslatedDiscountFields() {
                            if ($scope.data.model.id != 5) $scope['EN'].discountText = null
                            $scope.discountText2 = null;
                        }
                        var redeemPerUserLimitation, redeemOveralLimitation
                        if ($scope.redeemOveralLimitation == false) {
                            redeemOveralLimitation = "unlimited";
                            couponData.redeemOveralAmount = 0;
                        } else {
                            redeemOveralLimitation = "limited";
                            $scope.redeem.overallQuantity = $scope.redeem.redeemOveralAmount;
                            couponData.redeemOveralAmount = $scope.redeem.overallQuantity;
                        }
                        if ($scope.redeemPerUserLimitation == false) {
                            redeemPerUserLimitation = "unlimited";
                            couponData.redeemPerUserAmount = 0;
                        } else {
                            redeemPerUserLimitation = "limited";
                            $scope.redeem.perUserQuantity = $scope.redeem.redeemPerUserAmount;
                            couponData.redeemPerUserAmount = $scope.redeem.perUserQuantity;
                        }
                        var stores = [];
                        for (var i in $scope.companyStores) {
                            stores[i] = $scope.companyStores[i];
                        }
                        couponData.couponListViewImg = $rootScope.listCropperData.listCroppedImage;
                        couponData.couponDetailViewImg = $rootScope.detailCropperData.detailCroppedImage;
                        couponData.compNamePromo = $scope.compNamePromo;
                        couponData.isCompNamePromoSameAsCo = $scope.isCompNamePromoSameAsCo;
                        couponData.promotionName = $scope.promotionName;
                        couponData.promotionType = $scope.data.model.id;
                        couponData.discountAmount = discountAmount;
                        couponData.discountOldPrice = $scope.discountOldPrice;
                        couponData.discountText = $scope.discountText;
                        couponData.discountText2 = $scope.discountText2;
                        couponData.promotionDetailedDescr = $scope.promotionDetailedDescr;
                        if ($scope.promotionConditionsDescr != null) {
                            couponData.promotionConditionsDescr = $scope.promotionConditionsDescr;
                        } else {
                            couponData.promotionConditionsDescr = "";
                        }
                        couponData.redeemOveralLimitation = redeemOveralLimitation;
                        couponData.redeemPerUserLimitation = redeemPerUserLimitation;
                        couponData.redeemLocations = stores;
                        couponData.redeemLocationIndicator = $scope.allRedeemLocationIndicator;
                        couponData.promoCategoryId = $scope.promoCategory;
                        if ($scope.couponDetail) {
                            var array = [];
                            for (const elem of $scope.couponDetail.couponRedeemLocations) {
                                array.push(elem.companyStoreId);
                            }
                            if (arraysEqual(array, stores)) {
                                couponData.isRedeemLocationsModified = false;
                            } else {
                                couponData.isRedeemLocationsModified = true;
                            }
                        }
                        if ($scope.couponId) {
                            couponData.couponId = $scope.couponId;
                        }
                        couponData.isDisplayStartDateActualOnActivation = $scope.isDisplayStartDateActualOnActivation;
                        if ($scope.displayStartDate) {
                            var displayStart = new Date($scope.displayStartDate.getFullYear(), $scope.displayStartDate.getMonth(), $scope.displayStartDate.getDate(), $scope.displayStartDate.getHours(), $scope.displayStartDate.getMinutes());
                            displayStart.setUTCMinutes($scope.displayStartDate.getMinutes() - displayStart.getTimezoneOffset());
                            const timeZoneOffset = moment.tz.zone($scope.datesTimeZone).utcOffset(displayStart.getTime());
                            displayStart.setUTCMinutes($scope.displayStartDate.getMinutes() + timeZoneOffset);
                            couponData.displayStartDate = displayStart;
                        } else {
                            couponData.displayStartDate = null;
                        }
                        if ($scope.isDisplayStartDateActualOnActivation == true && isActivateCouponUpdate == false && $scope.couponPromoStatusCode == 0) {
                            couponData.displayStartDate = null;
                        }
                        if ($scope.isDisplayStartDateActualOnActivation == true && $scope.couponPromoStatusCode == 1 && $scope.couponDetail) {
                            couponData.displayStartDate = $scope.couponDetail.couponDetailViewData.displayStartDate;
                        }
                        if ($scope.redeemStartDate) {
                            var redeemStart = new Date($scope.redeemStartDate.getFullYear(), $scope.redeemStartDate.getMonth(), $scope.redeemStartDate.getDate(), $scope.redeemStartDate.getHours(), $scope.redeemStartDate.getMinutes());
                            redeemStart.setUTCMinutes($scope.redeemStartDate.getMinutes() - redeemStart.getTimezoneOffset());
                            const timeZoneOffset = moment.tz.zone($scope.datesTimeZone).utcOffset(redeemStart.getTime());
                            redeemStart.setUTCMinutes($scope.redeemStartDate.getMinutes() + timeZoneOffset);
                            couponData.redeemStartDate = redeemStart;
                        } else {
                            couponData.redeemStartDate = null;
                        }
                        if ($scope.redeemEndDate) {
                            var redeemEnd = new Date($scope.redeemEndDate.getFullYear(), $scope.redeemEndDate.getMonth(), $scope.redeemEndDate.getDate(), $scope.redeemEndDate.getHours(), $scope.redeemEndDate.getMinutes());
                            redeemEnd.setUTCMinutes($scope.redeemEndDate.getMinutes() - redeemEnd.getTimezoneOffset());
                            const timeZoneOffset = moment.tz.zone($scope.datesTimeZone).utcOffset(redeemEnd.getTime());
                            redeemEnd.setUTCMinutes($scope.redeemEndDate.getMinutes() + timeZoneOffset);
                            if ($scope.redeemEndDateInEnd == true) {
                                redeemEnd.setSeconds(59, 999);
                            }
                            couponData.redeemEndDate = redeemEnd;
                        } else {
                            couponData.redeemEndDate = null;
                        }

                        couponData.isTranslation_EN_Enabled = $scope.isEnglishTranslationEnabled == 'bekapcsolt'
                        couponData.compNamePromo_EN = $scope['EN'].compNamePromo
                        couponData.promotionName_EN = $scope['EN'].promotionName
                        couponData.discountText_EN = $scope['EN'].discountText
                        couponData.discountText2_EN = $scope['EN'].discountText2
                        couponData.promotionDetailedDescr_EN = $scope['EN'].promotionDetailedDescr
                        couponData.promotionConditionsDescr_EN = $scope['EN'].promotionConditionsDescr
                        const redeemTypeIndex = $scope.couponRedeemTypes.findIndex(x => x.id == $scope.redeemType)
                        couponData.redeemType = $scope.couponRedeemTypes[redeemTypeIndex].type
                        couponData.redeemPartnerResponseRequired = $scope.couponRedeemTypes[redeemTypeIndex].redeemPartnerResponseRequired;
                        couponData.barcodeType = couponData.redeemType == 10 ? $scope.barcodeType : null;
                        couponData.barcodeSubType = couponData.redeemType == 10 && couponData.barcodeType == 1 ? $scope.barcodeSubType : null
                        couponData.barcodeSourceType = couponData.redeemType == 10 ? $scope.barcodeSourceType : null
                        couponData.barcodeSourceCode = couponData.redeemType == 10 && couponData.barcodeSourceType == 1 ? $scope.barcodeSourceCode : null
                        couponData.barcodeSourceImg = couponData.redeemType == 10 && couponData.barcodeSourceType == 0 ? $rootScope.barcodeCropperData.barcodeImg : null
                        couponData.listViewImgBlackOverlayOpacity = $scope.listViewImgBlackOverlayOpacityValue
                        couponData.couponCollectionId = $scope.couponCollection == 0 ? null : $scope.couponCollection
                        couponData.appearsOutsideOfCollection = $scope.couponCollection == 0 ? null : $scope.appearsOutsideOfCollection
                        $http.post(API_PREFIX.url + '/coupon/addOrUpdateCoupon_v2', couponData).then(function (result) {
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            if (result.data.errorCode == 0) {
                                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_létrehozás_módosítás_sikeres",
                                    "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon létrehozás, módosítás sikeres")
                                resolve(true);
                            } else {
                                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_létrehozás_módosítás_sikertelen",
                                    "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon létrehozás, módosítás sikertelen")
                                $scope.sendButtonDisabled = false;
                                $rootScope.isDataSheetModified = true;
                                reject(false);
                            }
                        }, function () {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_létrehozás_módosítás_sikertelen",
                                "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon létrehozás, módosítás sikertelen")
                        });
                    }
                }
            });
        };
        $scope.sendCoupon = function (form) {
            sendCoupon(form, false).then(function (result) {
                if (result == true) {
                    var alert;
                    if ($scope.couponId) {
                        alert = $mdDialog.alert()
                            .title("Sikeres módosítás")
                            .ariaLabel("Sikeres módosítás")
                            .textContent("A kupon módosítása sikeres.")
                            .ok('Rendben')
                    } else {
                        alert = $mdDialog.alert()
                            .title("Sikeres létrehozás")
                            .ariaLabel("Sikeres létrehozás")
                            .textContent("A kupon létrehozása sikeres.")
                            .ok('Rendben')
                    }
                    $mdDialog.show(alert).then(function () {
                        $rootScope.isDataSheetModified = false;
                        $state.reload();
                    });
                }
            }, function () { })
        }
        function DialogCtrl($scope, $mdDialog, compNamePromo, promotionName) {
            $scope.compNamePromo = compNamePromo;
            $scope.promotionName = promotionName;
            $scope.closeDialog = function () {
                $mdDialog.hide();
            }
        }
    });
function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}
function getMonth(month) {
    if (month == "01") {
        return "január";
    } else if (month == "02") {
        return "február";
    } else if (month == "03") {
        return "március";
    } else if (month == "04") {
        return "április";
    } else if (month == "05") {
        return "május";
    } else if (month == "06") {
        return "június";
    } else if (month == "07") {
        return "július";
    } else if (month == "08") {
        return "augusztus";
    } else if (month == "09") {
        return "szeptember";
    } else if (month == "10") {
        return "október";
    } else if (month == "11") {
        return "november";
    } else if (month == "12") {
        return "december";
    }
}
function hasWhiteSpace(s) {
    return /\s/g.test(s);
}

/*.directive("fileread", [
    function () {
        return {
            scope: {
                fileread: "="
            },
            link: function (scope, element, attributes) {
                element.bind("change", function (changeEvent) {
                    var reader = new FileReader();
                    reader.onload = function (loadEvent) {
                        scope.$apply(function () {
                            scope.fileread = loadEvent.target.result;
                        });
                    }
                    reader.readAsDataURL(changeEvent.target.files[0]);
                });
            }
        }
    }
]);

function previewFile(image, browser) {
var preview = document.querySelector(image);
var file = document.querySelector(browser).files[0];
var reader = new FileReader();

reader.addEventListener("load", function () {
    preview.src = reader.result;
}, false);

if (file) {
    reader.readAsDataURL(file);
}
}*/